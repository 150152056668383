import dayjs from 'dayjs';

import { MESSAGES } from 'constants/data';

export const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const phoneRegExp = /^\d{2,3}\d{3,4}\d{4}$/;
export const pwRegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
export const isEmpty = (value) => {
    let result = false;
    if (
        value === '' ||
        value === null ||
        value === 'null' ||
        value === undefined ||
        value === 'undefined' ||
        value === 'INVALID' ||
        (value !== null && Array.isArray(value) && value.length === 0) ||
        (value !== null && typeof value === 'object' && Object.keys(value).length === 0)
    ) {
        result = true;
    }

    return result;
};

export const queryToString = (query) => {
    let result = '?';
    Object.keys(query)?.forEach((q) => {
        if (query[q]) {
            // searchkeyword가 있으면 주소창 searchCondition
            if (q === 'searchKeyword' && isEmpty(query.searchCondition)) {
                result += `searchCondition=${encodeURIComponent(query.searchCondition)}&`;
            }
            result += `${q}=${encodeURIComponent(query[q])}&`;
        }
    });

    return result.substring(0, result.length - 1);
};

export const queryToObject = (search) => {
    const params = {};
    const keywords = search?.split('?')?.[1]?.split('&');

    keywords?.forEach((keyword) => {
        const pair = keyword.split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
    });

    return params;
};

// 현재 메뉴에서 사용되는 검색 필터 리스트 구하기
// searchStates : 현재 searchSlice에 있는 모든 값
// searchParams : 현재 메뉴에서 사용할 검색 필터 이름들
export const getCurrentSearchParams = (searchStates, searchParams) => {
    const currentList = {};
    Object.values(searchParams).map((paramName) => {
        if (!isEmpty(searchStates[paramName])) {
            currentList[paramName] = searchStates[paramName];
        }
        return currentList;
    });

    if (currentList?.searchKeyword) {
        currentList.searchKeyword = '';
    }

    return currentList; // 현재 메뉴에서 사용할 검색 필터 object
};

// 날짜 형식 변환
export const formatDate = (type, date) => {
    let formattedDate = dayjs(date).format('YYYY-MM-DD');
    if (type === 'monthly') {
        formattedDate = dayjs(date).format('YYYY-MM');
    } else if (type === 'time') {
        formattedDate = dayjs(date).format('YYYY-MM-DD HH:mm:ss');
    }

    return formattedDate;
};

// 날짜 계산
export const calculateDate = (today, month) => {
    const calToday = new Date(today);
    calToday.setMonth(calToday.getMonth() - month);

    if (today.getDate() !== calToday.getDate()) {
        calToday.setDate(0);
    }

    return calToday;
};

// 기간 검색 버튼 클릭 (오늘, 1주일, 1개월, 3개월, 6개월, 기간 초기화)
export const handleDateClick = (name, months) => {
    const dateObj = { startDate: null, endDate: null };
    const curDate = new Date();
    const formattedCurDate = formatDate('daily', curDate);
    let prevDate = '';

    if (name === 'today') {
        dateObj.startDate = formattedCurDate;
        dateObj.endDate = formattedCurDate;
    } else if (name === 'week') {
        prevDate = new Date(curDate).setDate(curDate.getDate() - 7);
    } else if (name === 'month') {
        prevDate = calculateDate(curDate, months);
    } else if (name === 'reset') {
        dateObj.startDate = null;
        dateObj.endDate = null;
    }

    if (name === 'week' || name === 'month') {
        dateObj.startDate = formatDate('daily', prevDate);
        dateObj.endDate = formattedCurDate;
    }

    return dateObj;
};

export const getMessageText = (type) => MESSAGES[type] || '';
