import * as yup from 'yup';

import { emailRegExp, phoneRegExp, pwRegExp } from 'utils';

const SCHEMA = {
    Dashboard: yup.object().shape({
        category: yup.object().shape({
            value: yup.string().required()
        }),
        name: yup.string().required(),
        description: yup.string().required(),
        quantity: yup.number().min(0).positive().required(),
        useYn: yup.string().required()
    }),
    ChangeInfo: yup.object().shape({
        mn_nm: yup.string().required('이름을 입력해주세요'),
        mn_tel: yup.string().required('전화번호를 입력해주세요').matches(phoneRegExp, '올바른 전화번호를 입력해주세요'),
        mn_email: yup
            .string()
            .required('이메일을 입력해주세요')
            .matches(emailRegExp, '올바른 이메일형식을 입력해주세요')
    }),
    Notice: yup.object().shape({
        subject: yup.string().required('제목을 입력해주세요'),
        use_yn: yup
            .object()
            .shape({
                value: yup.string().required()
            })
            .required('사용여부를 선택해주세요'),
        contents: yup.string().required('내용을 입력해주세요')
    }),
    Inquiry: yup.object().shape({
        contents: yup.string().required('답변 내용을 입력해주세요')
    }),
    Policy: yup.object().shape({
        startDate: yup.string().required(),
        endDate: yup.string().required()
    }),
    User: yup.object().shape({
        mn_nm: yup.string().required('이름을 입력해주세요'),
        admin_id: yup.string().required('아이디를 입력해주세요'),
        admin_pw: yup
            .string()
            .required('비밀번호를 입력해주세요')
            .matches(pwRegExp, '총 8자리이상 영문자, 숫자, 특수문자가 포함되어야 합니다.'),
        mn_tel: yup.string().required('전화번호를 입력해주세요'),
        mn_email: yup.string().required('이메일을 입력해주세요'),

        email_domain: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required('이메일주소를 선택해주세요')
        })
    }),
    Brand: yup.object().shape({
        com_nm: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required('회사명을 입력해주세요')
        }),
        br_nm: yup.string().required('브랜드명을 입력해주세요'),

        br_img: yup
            .mixed()
            .required('이미지를 선택해주세요')
            .test('image', '이미지를 선택해주세요', (value) => {
                return value?.length > 0;
            }),
        br_cate: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required('종류를 선택해주세요')
        }),
        br_main: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required('메인 노출여부를 선택해주세요')
        }),
        use_yn: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required('사용여부를 선택해주세요')
        })
    }),

    BrandModify: yup.object().shape({
        com_nm: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required('회사명을 입력해주세요')
        }),
        br_nm: yup.string().required('브랜드명을 입력해주세요'),

        br_img: yup.mixed(),
        br_cate: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required('종류를 선택해주세요')
        }),
        br_main: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required('메인 노출여부를 선택해주세요')
        }),
        use_yn: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required('사용여부를 선택해주세요')
        })
    }),
    Product: yup.object().shape({
        com_nm: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required()
        }),
        br_nm: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required()
        }),
        pro_nm: yup.string().required(),
        pro_img: yup.mixed().required(), // file
        pro_dc: yup.string().required(),
        pro_url: yup.string().notRequired(),
        use_yn: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required()
        })
    }),
    Event: yup.object().shape({
        evt_nm: yup.string().required(),
        use_yn: yup.object().shape({
            value: yup.string().required()
        }),
        com_nm: yup.object().shape({
            value: yup.string().required()
        }),
        evt_s_dt: yup.date().required(),
        evt_e_dt: yup.date().required(),
        evt_m_img: yup.mixed().required(),
        evt_l_img: yup.mixed().required(),
        evt_type: yup.string().required(),
        evt_d_img: yup.mixed(),
        evt_url: yup.string()
    }),
    EventModify: yup.object().shape({
        evt_nm: yup.string().required(),
        use_yn: yup.object().shape({
            value: yup.string().required()
        }),
        com_nm: yup.object().shape({
            value: yup.string().required()
        }),
        evt_s_dt: yup.date().required(),
        evt_e_dt: yup.date().required(),
        evt_m_img: yup.mixed(),
        evt_l_img: yup.mixed(),
        evt_type: yup.string().required(),
        evt_d_img: yup.mixed(),
        evt_url: yup.string()
    }),
    ChangePwd: yup.object().shape({
        admin_pw: yup.string().required('현재 비밀번호를 입력해주세요'),
        new_pw: yup
            .string()
            .required('새 비밀번호를 입력해주세요')
            .matches(pwRegExp, '총 8자리이상 영문자, 숫자, 특수문자가 포함되어야 합니다.'),
        check_pw: yup
            .string()
            .required('비밀번호를 한번더 입력해주세요')
            .oneOf([yup.ref('new_pw'), null], '비밀번호가 일치하지 않습니다.')
    }),
    Retail: yup.object().shape({
        sel_nm: yup.string().required(),
        use_yn: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required()
        })
    })
};

const DEFAULT_VALUE = {
    Dashboard: {
        category: { value: '', label: '카테고리를 선택해주세요' },
        name: '',
        quantity: 0,
        ingredients_1: false,
        ingredients_2: false,
        ingredients_3: false,
        ingredients_4: false,
        viewYn: 'Y',
        useYn: 'N'
    }
};

export { SCHEMA, DEFAULT_VALUE };
