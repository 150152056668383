import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // 공통
    confirmType: '',
    isConfirmed: false,
    progressOpen: false,
    data: null,
    state: '',
    isExpiredToken: false, // 토큰 및 세션 만료 구분

    // 메시지 모달
    msgOpen: false,
    messageType: '',
    message: '',

    // 이미지 모달
    imgOpen: false,

    // 상세 모달
    detailOpen: false,

    // 이벤트 추가 모달
    eventOpen: false,
    eventData: null,
    eventType: null,

    // 삭제할 정책 idx
    deletingIdx: '',

    // 계정 추가 모달
    userOpen: false,
    userData: null,
    userType: null,

    // 문의하기 추가 모달
    inquiryOpen: false,
    inquiryData: null,
    inquiryIdx: '',

    // 공지시항 추가 모달
    noticeOpen: false,
    noticeData: null,
    noticeType: null,

    // 브랜드 추가 모달
    brandOpen: false,
    brandData: null,
    brandType: null,
    companyData: null,

    // 제품 추가 모달
    productOpen: false,
    productData: null,

    // 판매처 추가 모달
    retailOpen: false,
    retailData: null
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        // type: confirm -> ConfirmModal
        // 대신 confirmType을 함께 작성!!!
        setMessage: (state, { payload }) => {
            state.msgOpen = true;
            state.messageType = payload.type || 'message';
            state.message = payload.message;
            state.confirmType = payload.confirmType || '';
            state.deletingIdx = payload.deletingIdx || '';
            state.isExpiredToken = payload.isExpiredToken || false;
            state.data = payload.data || null;
            state.state = payload.state || null;
        },
        // 확인 모달에서 확인 버튼 클릭
        setConfirm: (state) => {
            state.msgOpen = false;
            state.messageType = '';
            state.message = '';
            state.isConfirmed = true;
        },
        // 처리중 모달 --> ConfirmModal -> 처리중
        setConfirmed: (state) => {
            state.isConfirmed = false;
            state.progressOpen = true; // progress 모달 띄우기
        },
        // 확인/처리중 모달 관련된 값들 초기화

        setConfirmReset: (state) => {
            state.confirmType = '';
            state.data = null;
            state.progressOpen = false;
            state.isConfirmed = false;
            state.deletingIdx = '';
        },
        // 이미지 모달 띄우기
        setImage: (state, { payload }) => {
            state.imgOpen = true;
            state.data = payload.data;
        },
        setDetail: (state) => {
            state.detailOpen = true;
            // state.data = payload.data || null;
        },
        setEvent: (state, { payload }) => {
            state.eventOpen = payload.open;
            state.eventData = payload.data;
            state.eventType = payload.type;
        },
        setEventType: (state, { payload }) => {
            state.eventType = payload;
        },
        setInquiry: (state, { payload }) => {
            state.inquiryOpen = payload.open;
            state.inquiryData = payload.data;
            state.inquiryIdx = payload.bdIdx;
        },
        setNotice: (state, { payload }) => {
            state.noticeOpen = payload.open;
            state.noticeData = payload.data;
            state.noticeType = payload.type;
        },
        setNoticeType: (state, { payload }) => {
            state.noticeType = payload;
        },
        setUser: (state, { payload }) => {
            state.userOpen = payload.open;
            state.userData = payload.data;
            state.userType = payload.type;
        },
        setUserType: (state, { payload }) => {
            state.userType = payload;
        },
        setBrand: (state, { payload }) => {
            state.brandOpen = payload.open;
            state.brandData = payload.data;
            state.brandType = payload.type;
            state.companyData = payload.company;
        },
        setBrandType: (state, { payload }) => {
            state.brandType = payload;
        },
        setProduct: (state, { payload }) => {
            state.productOpen = payload.open;
            state.productData = payload.data;
        },
        setRetail: (state, { payload }) => {
            state.retailOpen = payload.open;
            state.retailData = payload.data;
        },
        // 모달 닫기
        // 모든 값 초기화할때 도 사용
        setClose: (state) => ({ ...state, ...initialState })
    }
});

export const {
    setMessage,
    setConfirm,
    setDetail,
    setEvent,
    setEventType,
    setNotice,
    setUser,
    setUserType,
    setInquiry,
    setNoticeType,
    setBrand,
    setBrandType,
    setImage,
    setConfirmed,
    setConfirmReset,
    setProduct,
    setRetail,
    setClose
} = modalSlice.actions;

export const modalSelector = (state) => state.modal;

export default modalSlice.reducer;
