import { useEffect } from 'react';

import { isEmpty } from 'utils';

const UseFormError = (errors) => {
    useEffect(() => {
        const errorArray = Object.values(errors);
        if (!isEmpty(errors)) {
            if (errorArray.length !== 0) {
                if (Object.prototype.hasOwnProperty.call(errorArray[0], 'message')) {
                    alert(errorArray[0].message);
                } else {
                    alert(errorArray[0].value.message);
                }
            }
        }
    }, [errors]);
};

export default UseFormError;
