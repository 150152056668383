import axios from 'axios';

// import { setIsExit } from 'slices/menuSlice';
// import { setMessage } from 'slices/modalSlice';

// import { setIsExit } from 'slices/menuSlice';
// import { setMessage } from 'slices/modalSlice';

import { setLogOut } from 'slices/loginSlice';
import { setMessage } from 'slices/modalSlice';

import reduxStore from 'app/store';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 5000 // API 요청 응답 대기 시간...
});

instance.defaults.headers.common.Accept = 'application/json';
instance.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';
// instance.defaults.withCredentials = true; // 토큰방식이 아닌 세션을 사용할 경우 (cookie 사용할 때!!!!)+ package.json에 proxy 추가 그리고 .env -> REACT_APP_BASE_URL=/ (**개발 환경)

// axios 헤더 설정
instance.interceptors.request.use((config) => {
    if (config.fileFlag === true) {
        config.headers['Content-Type'] = 'multipart/form-data';
        delete config.fileFlag;
    }

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        config.headers.token = accessToken;
    }

    return config;
});

// 응답 처리
instance.interceptors.response.use(
    (res) => res.data,
    (err) => {
        const { dispatch } = reduxStore;
        const errorCode = err?.response?.status;
        const errorObject = {
            statusCode: errorCode,
            message: err.response?.data || '네트워크 에러'
        };

        if (errorCode === 401) {
            dispatch(setLogOut());
            window.location.reload();
        }

        // access_token 만료
        if (
            err.response?.status === 401 &&
            err.response?.data === '로그인 세션이 만료되었습니다. 다시 로그인해주세요.'
        ) {
            return dispatch(setMessage({ isExpiredToken: true, message: err.response?.data }));
        }

        return Promise.reject({ ...errorObject });
    }
);

export const getData = async (url, params = {}) => instance.get(`${url}`, { params });
export const postData = async (url, body = {}, fileFlag = false) => instance.post(`${url}`, body, { fileFlag });
export const patchData = async (url, body = {}, fileFlag = false) => instance.patch(`${url}`, body, { fileFlag });
export const putData = async (url, body = {}, fileFlag = false) => instance.put(`${url}`, body, { fileFlag });
export const deleteData = async (url, body = {}) => instance.delete(`${url}`, body);
