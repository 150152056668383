import { useRef } from 'react';

import _ from 'lodash';
import { useQuery } from 'react-query';

import useMessage from 'hooks/useMessage';
import useSearchParams from 'hooks/useSearchParams';

import { getData } from 'api';

import { SEARCH_PARAM } from 'configs/search';

const useGetList = ({ menu, url }) => {
    const prevParams = useRef(null); // Previous Search Params
    const params = useSearchParams(SEARCH_PARAM[menu]); // 검색 설정하기 (Search Params)

    const handleMessage = useMessage();
    // API 호출
    const { isError, error, ...rest } = useQuery(menu.toLowerCase(), () => getData(url, params), {
        enabled: !!(params && !_.isEqual(prevParams.current, params)),
        onError: (error) => handleMessage(error),
        onSettled: () => {
            prevParams.current = params;
        }
    });

    return { params, ...rest };
};

export default useGetList;
