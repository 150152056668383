import React from 'react';

import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import Layout from 'layouts/Layout';

function RouteWrapper({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
}

RouteWrapper.propTypes = {
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

export default RouteWrapper;
