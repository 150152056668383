import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import ReactSelect from 'react-select';

import { Paper, Box, Grid, Typography } from '@mui/material';

import useMenu from 'hooks/useMenu';
import useMessage from 'hooks/useMessage';

import { postData } from 'api';
import { DefaultButton, SubmitButton } from 'components/Buttons';
import DatePicker from 'components/FormInputs/DatePicker';
import ReactQuill from 'components/ReactQuill';

import { SCHEMA } from 'configs/form';

import { formatDate } from 'utils';

const formStyles = {
    container: (provided) => ({
        ...provided,
        width: '100%',
        maxWidth: 300,
        height: 36,
        display: 'inline-block',
        fontSize: 12,
        textAlign: 'center'
    })
};

export default function AddPolicy() {
    const handleMessage = useMessage();
    const history = useHistory();
    const menu = useMenu({ page: 'Policy', menu: 'policy', menuTitle: '약관 및 방침 관리', menuNum: 10 }); // 페이지/메뉴 설정하기

    const [contents, setContents] = useState('');

    const options = [
        { value: 'P', label: '개인정보 처리방침' },
        { value: 'S', label: '서비스 이용약관' }
    ];

    const [selectedOption, setSelectedOption] = useState(options[0]);

    const { control, watch } = useForm({
        resolver: yupResolver(SCHEMA[menu])
    });

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const { mutate: addPolicy } = useMutation('addPolicy', (body) => postData('/web/policy', body), {
        onSuccess: () => {
            history.goBack();
        },
        onError: (err) => {
            console.log('err:', err);
        }
    });

    const onClick = () => {
        if (!contents) {
            // 약관 내용을 입력하지 않은 경우 얼럿모달 띄우기
            handleMessage({ message: '내용을 입력해 주세요.' });
            return;
        }
        const today = formatDate('daily', new Date());

        const start = startDate || today;
        const end = endDate || today;

        if (start >= end) {
            // 종료일이 시작일보다 앞 날짜면 알림 모달 띄우기
            handleMessage({ message: '종료일은 시작일보다 같거나 빠를 수 없습니다.' });
            return;
        }
        addPolicy({
            policy_type: selectedOption.value,
            contents,
            start_dt: start,
            end_dt: end
        });
    };

    const handleChange = (selected) => {
        setSelectedOption(selected);
    };

    return (
        <>
            <ReactSelect
                onChange={handleChange}
                options={options}
                aria-label="정책선택"
                defaultValue={options[0]}
                styles={formStyles}
            />
            <Box mb={2.5} />
            <Paper sx={{ p: '20px', borderRadius: '10px' }} elevation={1}>
                <ReactQuill contents={contents} setContents={setContents} />

                <Grid container alignItems="center" pl="20px" pr="20px">
                    <Grid item container alignItems="center">
                        <Typography mr={1}>시작일</Typography>
                        <Grid item>
                            <DatePicker defaultValue={new Date()} control={control} name="startDate" />
                        </Grid>
                        <Typography ml={3} mr={1}>
                            종료일
                        </Typography>
                        <Grid item>
                            <DatePicker
                                defaultValue={new Date(new Date().setDate(new Date().getDate() + 1))}
                                minDate={new Date(new Date().setDate(new Date(startDate).getDate() + 1))}
                                control={control}
                                name="endDate"
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container justifyContent="center" columnGap={1} mt={1}>
                    <Grid item>
                        <DefaultButton text="목록" onClick={() => history.goBack()} />
                    </Grid>
                    <Grid item>
                        <SubmitButton text="등록" onClick={onClick} />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
