import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { SEARCH_PARAM as params } from 'configs/search';

import { getCurrentSearchParams, queryToString } from 'utils';

const useSearch = ({ menu }) => {
    const history = useHistory();
    const location = useLocation();

    const searchStates = useSelector((state) => state.search);

    // 검색하기 (location.search 값 변경)
    const handleSearch = (searchItems) => {
        const searchParams = getCurrentSearchParams(searchStates, params[menu]);
        history.push({
            pathname: location.pathname,
            search: queryToString({ ...searchParams, ...searchItems })
        });
    };

    return handleSearch;
};

export default useSearch;
