import React, { useState } from 'react';

import useGetList from 'hooks/useGetList';
import useMenu from 'hooks/useMenu';

import { Search } from 'components';

import NoticeTable from './components/NoticeTable';



export default function Notice() {
    const menu = useMenu({ page: 'Notice', menu: 'notice', menuTitle: '공지사항', menuNum: 8 }); // 페이지/메뉴 설정하기
    const { isLoading, data: dataList } = useGetList({ menu, url: `/web/notice/lists` }); // 리스트 데이터 가져오기
    const [selected, setSelected] = useState([]);
    return (
        <>
            <Search path="/notice" heading="" total={dataList?.totalCount} />
            <NoticeTable
                isLoading={isLoading}
                data={dataList?.noticeLists}
                total={dataList?.totalCount}
                selected={selected}
                setSelected={setSelected}
            />
        </>
    );
}
