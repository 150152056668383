import React from 'react';

import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { loginSelector } from 'slices/loginSlice';

import { ScrollToTop } from 'components';
import PublicRouter from 'routes/PublicRouter';
import PrivateRouter from 'routes/Router';

export default function App() {
    const { isLogin } = useSelector(loginSelector);

    return (
        <BrowserRouter>
            <ScrollToTop />
            {isLogin ? <PrivateRouter /> : <PublicRouter />}
        </BrowserRouter>
    );
}
