import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { setSearchFilters } from 'slices/searchSlice';

import useMessage from 'hooks/useMessage';
import useSearch from 'hooks/useSearch';

import { deleteData, putData } from 'api';
import { TableLoader, Pagination } from 'components';

import { HEAD_CELL } from 'configs/table';

import { StyledTablePaper } from 'styles/customize/table';

import NoticeData from './ListTable';

function NoticeTable({ isLoading, data, total, selected, setSelected }) {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { page, pageSize } = useSelector((state) => state.search);
    const { page: menu } = useSelector((state) => state.menu);

    const handleSearch = useSearch({ menu });
    const handleMessage = useMessage();
    const { mutate } = useMutation('deleteNotice', () => deleteData(`web/notice/${`${selected}`}`), {
        onSuccess: async () => {
            await queryClient.refetchQueries('notice');
            setSelected([]);
        }
    });

    // 삭제하기
    const onDelete = () => {
        if (selected.length === 0) {
            alert('삭제할 공지사항을 선택해주세요');
        }
        mutate();
    };

    // Pagination
    const handlePage = (paging) => {
        dispatch(setSearchFilters(paging));
        handleSearch(paging);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.bd_idx);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const {
        isLoading: noticeLoading,
        mutate: noticeMutation,
        reset: noticeReset
    } = useMutation(({ url, fileYn, data }) => putData(url, fileYn, data), {
        onError: (error) => handleMessage({ type: 'message', ...error })
    });

    // 사용여부/노출여부 등 select 데이터 수정하기
    const handleSelect = (name, value, rowIndex) => {
        noticeReset();
        noticeMutation({ url: `/web/notice/:${rowIndex}`, fileYn: false, data: { [name]: value } });
    };

    const numSelected = selected?.length || 0;
    const rowCount = data.length;
    const isSelected = (index) => (selected ? selected.indexOf(index) !== -1 : false);

    const onPageChange = (newPage) => handlePage({ page: newPage, pageSize }); // 페이지 이동하기

    const onRowsPerPageChange = (event) => handlePage({ page: 1, pageSize: parseInt(event.target.value, 10) }); // rows per page(페이지 당 행) 변경하기

    const handleClick = (event, index) => {
        const selectedIndex = selected.indexOf(index);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, index);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };
    // page 변경시 체크박스 초기화
    useEffect(() => {
        setSelected([]);
    }, [page, setSelected]);

    return (
        <StyledTablePaper elevation={1}>
            <TableContainer>
                <Table
                    aria-labelledby={`${menu.toLowerCase()}Table`}
                    size="medium"
                    aria-label={`${menu.toLowerCase()} table`}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                    checked={rowCount > 0 && numSelected === rowCount}
                                    onChange={handleSelectAllClick}
                                    inputProps={{ 'aria-label': 'select all data' }}
                                />
                            </TableCell>
                            {HEAD_CELL[menu]?.map(({ id, label }) => (
                                <TableCell key={id} align="center">
                                    {label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading || noticeLoading ? (
                            <TableLoader colSpan={20} />
                        ) : total === 0 ? (
                            <TableLoader type="empty" colSpan={20} />
                        ) : (
                            data.map((row, index) => {
                                const labelId = `${menu.toLowerCase()}-table-checkbox-${index}`;
                                const isItemSelected = isSelected(row.bd_idx);

                                return (
                                    <React.Fragment key={index}>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.bd_idx}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleClick(event, row.bd_idx)}
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <NoticeData
                                                menu={menu}
                                                row={row}
                                                index={index}
                                                handleSelect={handleSelect}
                                            />
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!isLoading && (
                <Pagination
                    count={total}
                    page={page > 0 ? page - 1 : 0}
                    rowsPerPage={Number(pageSize) || 10}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    onDelete={onDelete}
                />
            )}
        </StyledTablePaper>
    );
}

NoticeTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    selected: PropTypes.array,
    setSelected: PropTypes.func
};

NoticeTable.defaultProps = {
    data: [],
    total: 0,
    selected: false,
    setSelected: null
};

export default NoticeTable;
