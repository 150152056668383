import React from 'react';

import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

function ErrorMessage({ text }) {
    return (
        <Typography component="p" color="error" variant="errorMessage">
            {text}
        </Typography>
    );
}

ErrorMessage.propTypes = {
    text: PropTypes.string.isRequired
};

export default ErrorMessage;
