import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { modalSelector, setClose } from 'slices/modalSlice';

import UseFormError from 'hooks/useFormError';
import useMessage from 'hooks/useMessage';

import { postData, putData } from 'api';
import { DefaultButton, SubmitButton } from 'components/Buttons';
import { ErrorMessage, Input, Select } from 'components/FormInputs';

import { SCHEMA } from 'configs/form';

import { StyledBox, StyledInputContainer, StyledLabel } from 'styles/customize/form';
import theme from 'styles/theme/form';

import { isEmpty } from 'utils';

const useOptions = [
    { value: '0', label: '사용 여부를 선택해주세요.' },
    { value: 'Y', label: '사용' },
    { value: 'N', label: '미사용' }
];

const mainOptions = [
    { value: '0', label: '노출 여부를 선택해주세요.' },
    { value: 'Y', label: '노출' },
    { value: 'N', label: '미노출' }
];

const brandCategory = [
    { value: '0', label: '종류를 선택해주세요' },
    { value: '1', label: '뷰티' },
    { value: '2', label: '전자' },
    { value: '3', label: '식음료' },
    { value: '4', label: '패션' },
    { value: '5', label: '유아동' },
    { value: '6', label: '의약품' },
    { value: '7', label: '기타' }
];

function BrandModal() {
    const dispatch = useDispatch();
    const { brandOpen, brandData, brandType, companyData } = useSelector(modalSelector);

    const queryClient = useQueryClient();

    const handleMessage = useMessage();

    const isModify = brandType === 'modify';

    const defaultValues = {
        com_nm: { label: '회사를 선택해주세요.', value: '' },
        br_nm: '',
        br_cate: { label: '종류를 선택해주세요', value: '' },
        br_img: null,
        br_main: { label: '노출 여부를 선택해주세요.', value: '' },
        use_yn: { label: '사용 여부를 선택해주세요.', value: '' }
    };

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(isModify ? SCHEMA.BrandModify : SCHEMA.Brand)
    });

    UseFormError(errors);
    const { mutate: addBrand } = useMutation(({ url, data, fileYn }) => postData(url, data, fileYn), {
        onSuccess: () => {
            queryClient.refetchQueries('brand');
            reset(defaultValues);
            dispatch(setClose());
        },
        onError: (error) => handleMessage({ type: 'message', ...error })
    });

    const { mutate: editBrand } = useMutation('editBrand', ({ url, data, fileYn }) => putData(url, data, fileYn), {
        onSuccess: () => {
            queryClient.refetchQueries('brand');
            reset(defaultValues);
            dispatch(setClose());
        }
    });

    const onSubmit = (data) => {
        const formData = new FormData();

        // formData.append('br_img', data.br_img[0]);
        formData.append('com_nm', data.com_nm.label);
        formData.append('br_cate', data.br_cate.value);
        formData.append('br_main', data.br_main.value);
        formData.append('use_yn', data.use_yn.value);
        formData.append('br_nm', data.br_nm);

        if (data.br_img?.[0]) {
            formData.append('br_img', data.br_img[0]);
        } else {
            formData.append('br_img', brandData?.br_img);
        }

        if (isModify) {
            editBrand({
                url: `web/brand/${brandData.br_idx}`,
                data: formData,
                fileYn: true
            });
        } else {
            addBrand({
                url: 'web/brand',
                data: formData,
                fileYn: true
            });
        }
    };

    const onClose = () => {
        dispatch(setClose());
        reset(defaultValues);
    };

    useEffect(() => {
        if (brandOpen && !isEmpty(brandData) && isModify) {
            const cateIndex = brandCategory.findIndex((brand) => brand.value === brandData.br_cate);
            const mainIndex = mainOptions.findIndex((brand) => brand.value === brandData.br_main);
            const useIndex = useOptions.findIndex((brand) => brand.value === brandData.use_yn);
            const companyIndex = companyData?.findIndex((brand) => brand.label === brandData.com_nm);

            reset({
                com_nm: companyData[companyIndex],
                br_nm: brandData?.br_nm,
                br_cate: brandCategory[cateIndex],
                br_img: null,
                br_main: mainOptions[mainIndex],
                use_yn: useOptions[useIndex]
            });
        }
    }, [brandOpen, brandData, reset]);

    return (
        <>
            <Dialog
                open={brandOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose(event, reason);
                    }
                }}
                disableEscapeKeyDown
                sx={{
                    '& .MuiPaper-root': {
                        overflow: 'visible',
                        minWidth: 1145,
                        minHeight: 462,
                        maxWidth: '100%'
                    }
                }}
            >
                <ThemeProvider theme={theme}>
                    <DialogTitle>브랜드 {isModify ? '수정' : '등록'}</DialogTitle>
                    <DialogContent sx={{ height: '100%', paddingBottom: 0, overflow: 'inherit' }}>
                        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <StyledBox display="grid" gridTemplateColumns="200px 1fr">
                                <StyledLabel>
                                    <Typography variant="inputLabel">회사명</Typography>
                                </StyledLabel>
                                <StyledInputContainer sx={{ '& .MuiFormControl-root': { width: 320 } }}>
                                    <Select name="com_nm" control={control} options={companyData} />
                                    {errors.com_nm && <ErrorMessage text="회사명은 필수 입력입니다." />}
                                </StyledInputContainer>
                                <StyledLabel>
                                    <Typography variant="inputLabel">브랜드명</Typography>
                                </StyledLabel>
                                <StyledInputContainer sx={{ '& .MuiFormControl-root': { width: 500 } }}>
                                    <Input placeholder="브랜드명을 입력해 주세요." name="br_nm" control={control} />
                                    {errors.br_nm && <ErrorMessage text="브랜드명을 입력해주세요." />}
                                </StyledInputContainer>
                                <StyledLabel>
                                    <Typography variant="inputLabel">로고 이미지(128x128)</Typography>
                                </StyledLabel>
                                <StyledInputContainer>
                                    {isModify ? (
                                        <>
                                            <input type="file" {...register('br_img')} />
                                            <a href={brandData.br_img} download>
                                                {brandData.br_img.split('/br_img/')[1]}
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <input type="file" {...register('br_img')} />
                                            {errors.br_img && <ErrorMessage text="로고 이미지는 필수 선택입니다." />}
                                        </>
                                    )}
                                </StyledInputContainer>
                                <StyledLabel>
                                    <Typography variant="inputLabel">종류</Typography>
                                </StyledLabel>
                                <StyledInputContainer>
                                    <Select name="br_cate" control={control} options={brandCategory} />
                                    {errors.br_cate && <ErrorMessage text="카테고리를 선택해주세요." />}
                                </StyledInputContainer>

                                <StyledLabel>
                                    <Typography variant="inputLabel">메인 노출</Typography>
                                </StyledLabel>
                                <StyledInputContainer>
                                    <Select name="br_main" control={control} options={mainOptions} />
                                    {errors.br_main && <ErrorMessage text="노출여부를 선택해주세요." />}
                                </StyledInputContainer>
                                <StyledLabel>
                                    <Typography variant="inputLabel">사용 여부</Typography>
                                </StyledLabel>
                                <StyledInputContainer>
                                    <Select name="use_yn" control={control} options={useOptions} />
                                    {errors.use_yn && <ErrorMessage text="사용 여부를 선택해주세요." />}
                                </StyledInputContainer>
                            </StyledBox>
                            <Grid
                                sx={{ py: 2, mt: '40px', gap: '10px' }}
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Grid item xs="auto">
                                    <DefaultButton icon="cancel" onClick={onClose} text="취소" />
                                </Grid>
                                <Grid item xs="auto">
                                    <SubmitButton type="submit" text={isModify ? '수정' : '등록'} onClick={onSubmit} />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </ThemeProvider>
            </Dialog>
        </>
    );
}
export default BrandModal;
