/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import ImageIcon from '@mui/icons-material/Image';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { modalSelector, setClose, setEventType } from 'slices/modalSlice';

import useMessage from 'hooks/useMessage';

import { postData, putData } from 'api';
import { DefaultButton, DefaultButton as ModalButton, SubmitButton } from 'components/Buttons';
import { EventDatePicker, Input, RadioButton, Select, ErrorMessage } from 'components/FormInputs';

import { SCHEMA } from 'configs/form';

import { StyledBox, StyledInputContainer, StyledLabel } from 'styles/customize/form';
import theme from 'styles/theme/form';

import { formatDate, isEmpty } from 'utils';

const options = [
    { label: '사용 여부를 선택해주세요.', value: '' },
    { value: 'Y', label: '사용' },
    { value: 'N', label: '미사용' }
];

const companyOptions = [
    { label: '회사를 선택해주세요.', value: '' },
    { value: 'LG전자', label: 'LG전자' },
    { value: '블록오디세이', label: '블록오디세이' }
];

const defaultValues = {
    evt_nm: '',
    use_yn: { label: '사용 여부를 선택해주세요.', value: '' },
    com_nm: { label: '회사를 선택해주세요.', value: '' },
    evt_s_dt: '',
    evt_e_dt: '',
    evt_m_img: null,
    evt_l_img: null,
    evt_type: '',
    evt_d_img: null,
    evt_url: ''
};

function EventModal() {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const { eventOpen, eventData, eventType } = useSelector(modalSelector);

    const isAdd = eventType === 'add';
    const isDetail = eventType === 'detail';
    const isModify = eventType === 'modify';

    const getFilename = (fp) => `${fp.replace(/(.*\/)*([^.]+).*/gi, '$2')}.${fp.replace(/.+\./, '')}`;

    const {
        register,
        handleSubmit,
        control,
        reset,
        getValues,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(isModify ? SCHEMA.EventModify : SCHEMA.Event)
    });

    const watchRadio = useWatch({ name: ['evt_d_img', 'evt_url'], control });

    const typeHandler = () => {
        dispatch(setEventType('modify'));
        reset({
            evt_nm: eventData?.evt_nm,
            use_yn: eventData?.use_yn === 'Y' ? { value: 'Y', label: '사용' } : { value: 'N', label: '미사용' },
            com_nm:
                eventData?.com_nm === 'LG전자'
                    ? { value: 'LG전자', label: 'LG전자' }
                    : { value: '블록오디세이', label: '블록오디세이' },
            evt_s_dt: eventData?.evt_s_dt ? eventData?.evt_s_dt : null,
            evt_e_dt: eventData?.evt_e_dt ? eventData?.evt_e_dt : null,
            evt_m_img: null,
            evt_l_img: null,
            evt_type: eventData?.evt_type,
            evt_d_img: null,
            evt_url: !isEmpty(eventData?.evt_url) ? eventData?.evt_url : ''
        });
    };

    const { mutate: registEventMutation } = useMutation(
        'addEvent',
        ({ url, data, fileYn }) => postData(url, data, fileYn),
        {
            onSuccess: async () => {
                await queryClient.refetchQueries('event');
                reset(defaultValues);
                dispatch(setClose());
            }
        }
    );

    const { mutate: editEvent } = useMutation('editEvent', ({ url, data, fileYn }) => putData(url, data, fileYn), {
        onSuccess: async () => {
            await queryClient.refetchQueries('event');
            reset(defaultValues);
            dispatch(setClose());
        },
        onError: (error) => {
            console.log('error::::', error);
        }
    });

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('evt_nm', data.evt_nm);
        formData.append('use_yn', data.use_yn.value);
        formData.append('com_nm', data.com_nm.value);
        formData.append('evt_s_dt', formatDate('time', data.evt_s_dt));
        formData.append('evt_e_dt', formatDate('time', data.evt_e_dt));

        if (data.evt_m_img?.[0]) {
            formData.append('evt_m_img', data.evt_m_img[0]);
        } else {
            formData.append('evt_m_img', eventData?.evt_m_img);
        }

        if (data.evt_l_img?.[0]) {
            formData.append('evt_l_img', data.evt_l_img[0]);
        } else {
            formData.append('evt_l_img', eventData?.evt_l_img);
        }

        formData.append('evt_type', data.evt_type);

        if (isAdd) {
            if (data.evt_type === 'I') {
                formData.append('evt_d_img', data.evt_d_img?.[0]);
            } else {
                formData.append('evt_url', data.evt_url || '');
            }

            registEventMutation({
                url: 'web/event',
                data: formData,
                fileYn: true
            });
        } else {
            if (data.evt_type === 'I') {
                if (data.evt_d_img?.[0]) {
                    formData.append('evt_d_img', data.evt_d_img[0]);
                } else if (!isEmpty(eventData?.evt_d_img)) {
                    formData.append('evt_d_img', eventData?.evt_d_img);
                } else {
                    formData.append('evt_d_img', '');
                }
            } else if (data.evt_url) {
                formData.append('evt_url', data.evt_url);
            } else {
                formData.append('evt_url', '');
            }

            editEvent({
                url: `web/event/${eventData?.evt_idx}`,
                data: formData,
                fileYn: true
            });
        }
    };

    // 모달을 닫을때는 반드시 데이터 reset
    const onClose = () => {
        dispatch(setClose());
        reset(defaultValues);
    };

    useEffect(() => {
        if (watchRadio?.evt_d_img !== null && watchRadio?.[0]?.[0]?.name.length > 0) {
            setValue('evt_type', 'I');
        } else if (watchRadio?.[1].length > 0) {
            setValue('evt_type', 'U');
        }
    }, [watchRadio]);

    return (
        <>
            <Dialog
                open={eventOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose(event, reason);
                    }
                }}
                disableEscapeKeyDown
                sx={{
                    '& .MuiPaper-root': {
                        minWidth: 1145,
                        minHeight: 462,
                        maxWidth: '100%'
                    }
                }}
            >
                <ThemeProvider theme={theme}>
                    <DialogTitle>이벤트 {isModify ? '수정' : isAdd ? '등록' : ''}</DialogTitle>
                    <DialogContent sx={{ height: '100%', paddingBottom: 0 }}>
                        {/* <form /> */}
                        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <StyledBox display="grid" gridTemplateColumns="200px 1fr">
                                <StyledLabel>
                                    <Typography variant="inputLabel">이벤트명</Typography>
                                </StyledLabel>
                                <StyledInputContainer
                                    sx={{
                                        '& .MuiFormControl-root': {
                                            width: '100%'
                                        }
                                    }}
                                >
                                    {isDetail ? (
                                        <Typography>{eventData?.evt_nm}</Typography>
                                    ) : (
                                        <>
                                            <Input
                                                placeholder="이벤트명을 입력해 주세요."
                                                name="evt_nm"
                                                control={control}
                                            />
                                            {errors.evt_nm && <ErrorMessage text="이벤트명 입력은 필수입니다." />}
                                        </>
                                    )}
                                </StyledInputContainer>

                                <StyledLabel>
                                    <Typography variant="inputLabel">사용 여부</Typography>
                                </StyledLabel>
                                <StyledInputContainer>
                                    {isDetail ? (
                                        <Typography>{eventData?.use_yn}</Typography>
                                    ) : (
                                        <>
                                            <Select name="use_yn" control={control} options={options} />
                                            {errors.use_yn && <ErrorMessage text="사용 여부 선택은 필수입니다." />}
                                        </>
                                    )}
                                </StyledInputContainer>
                                <StyledLabel>
                                    <Typography variant="inputLabel">회사명</Typography>
                                </StyledLabel>
                                <StyledInputContainer>
                                    {isDetail ? (
                                        <Typography variant="inputLabel">{eventData?.com_nm}</Typography>
                                    ) : (
                                        <>
                                            <Select name="com_nm" control={control} options={companyOptions} />
                                            {errors.com_nm && <ErrorMessage text="회사명 선택은 필수입니다." />}
                                        </>
                                    )}
                                </StyledInputContainer>
                                <StyledLabel>
                                    <Typography variant="inputLabel">노출일자</Typography>
                                </StyledLabel>
                                <StyledInputContainer>
                                    {isDetail ? (
                                        <>
                                            <Typography variant="inputLabel">
                                                {formatDate('time', eventData?.evt_s_dt)}
                                            </Typography>
                                            ~
                                            <Typography variant="inputLabel">
                                                {formatDate('time', eventData?.evt_e_dt)}
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <EventDatePicker
                                                minDate={new Date()}
                                                name="evt_s_dt"
                                                term="daily"
                                                control={control}
                                            />
                                            ~
                                            <EventDatePicker
                                                minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                                                name="evt_e_dt"
                                                term="daily"
                                                control={control}
                                            />
                                            {errors.evt_s_dt || errors.evt_e_dt ? (
                                                <ErrorMessage text="노출일자를 선택해주세요." />
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    )}
                                </StyledInputContainer>
                                {isDetail ? (
                                    <>
                                        <StyledLabel sx={{ height: '100%' }}>
                                            <Typography variant="inputLabel">메인 이벤트 배너</Typography>
                                        </StyledLabel>
                                        <StyledInputContainer sx={{ height: '100%' }}>
                                            <a href={eventData?.evt_m_img} download>
                                                <img
                                                    style={{ objectFit: 'contain' }}
                                                    width={300}
                                                    height={188}
                                                    src={eventData?.evt_m_img}
                                                    alt="mainImage"
                                                />
                                            </a>
                                        </StyledInputContainer>
                                        <StyledLabel sx={{ height: '100%' }}>
                                            <Typography variant="inputLabel">이벤트 리스트 이미지</Typography>
                                        </StyledLabel>
                                        <StyledInputContainer sx={{ height: '100%' }}>
                                            <a href={eventData?.evt_l_img} download>
                                                <img
                                                    style={{ objectFit: 'contain' }}
                                                    width={300}
                                                    height={188}
                                                    src={eventData?.evt_l_img}
                                                    alt="listImage"
                                                />
                                            </a>
                                        </StyledInputContainer>
                                        <StyledLabel sx={{ height: '100%' }}>
                                            <Typography variant="inputLabel">이벤트 상세 이미지</Typography>
                                        </StyledLabel>
                                        <StyledInputContainer sx={{ height: '100%' }}>
                                            {eventData?.evt_type === 'I' ? (
                                                <a href={eventData?.evt_d_img} download>
                                                    <img
                                                        style={{ objectFit: 'contain' }}
                                                        width={300}
                                                        height={188}
                                                        src={eventData?.evt_d_img}
                                                        alt="detailImage"
                                                    />
                                                </a>
                                            ) : (
                                                ''
                                            )}
                                        </StyledInputContainer>
                                    </>
                                ) : (
                                    <>
                                        <StyledLabel>
                                            <Typography variant="inputLabel">메인 이미지 (750 X 750)</Typography>
                                        </StyledLabel>
                                        <StyledInputContainer>
                                            {isModify ? (
                                                <>
                                                    <input type="file" {...register('evt_m_img')} />
                                                    <a href={eventData?.evt_m_img} download>
                                                        <u style={{ display: 'flex' }}>
                                                            <ImageIcon />
                                                            {eventData.evt_m_img
                                                                ? getFilename(eventData.evt_m_img)
                                                                : ''}
                                                        </u>
                                                    </a>
                                                </>
                                            ) : (
                                                <>
                                                    <input type="file" {...register('evt_m_img')} />
                                                    {errors.evt_m_img && (
                                                        <ErrorMessage text="메인 이미지는 필수 선택입니다." />
                                                    )}
                                                </>
                                            )}
                                        </StyledInputContainer>
                                        <StyledLabel>
                                            <Typography variant="inputLabel">리스트 이미지 (670 X 376)</Typography>
                                        </StyledLabel>
                                        <StyledInputContainer>
                                            {isModify ? (
                                                <>
                                                    <input type="file" {...register('evt_l_img')} />
                                                    <a href={eventData?.evt_l_img} download>
                                                        <u style={{ display: 'flex' }}>
                                                            <ImageIcon />
                                                            {eventData.evt_l_img
                                                                ? getFilename(eventData.evt_l_img)
                                                                : ''}
                                                        </u>
                                                    </a>
                                                </>
                                            ) : (
                                                <>
                                                    <input type="file" {...register('evt_l_img')} />
                                                    {errors.evt_l_img && (
                                                        <ErrorMessage text="리스트 이미지는 필수 선택입니다." />
                                                    )}
                                                </>
                                            )}
                                        </StyledInputContainer>
                                        <StyledLabel sx={{ height: '100%' }}>
                                            <Typography variant="inputLabel">상세 이미지 (750 X ∞)</Typography>
                                        </StyledLabel>
                                        <StyledInputContainer sx={{ height: '100%' }}>
                                            <RadioButton
                                                name="evt_type"
                                                row={false}
                                                control={control}
                                                options={[
                                                    { value: 'I', label: '' },
                                                    { value: 'U', label: '' }
                                                ]}
                                            />
                                            <div
                                                style={{
                                                    height: '88px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                {isModify ? (
                                                    <span style={{ display: 'flex' }}>
                                                        <input type="file" {...register('evt_d_img')} />
                                                        <a href={eventData?.evt_d_img} download>
                                                            <u style={{ display: 'flex' }}>
                                                                {eventData?.evt_type === 'U' ? (
                                                                    ''
                                                                ) : (
                                                                    <>
                                                                        <ImageIcon />
                                                                        {eventData.evt_d_img
                                                                            ? getFilename(eventData.evt_d_img)
                                                                            : ''}
                                                                    </>
                                                                )}
                                                            </u>
                                                        </a>
                                                    </span>
                                                ) : (
                                                    <>
                                                        <input type="file" {...register('evt_d_img')} />
                                                    </>
                                                )}

                                                {isModify ? (
                                                    <Input
                                                        value={eventData?.evt_url}
                                                        name="evt_url"
                                                        control={control}
                                                    />
                                                ) : (
                                                    <Input
                                                        placeholder="URL을 입력해 주세요."
                                                        name="evt_url"
                                                        control={control}
                                                    />
                                                )}
                                            </div>
                                            {/* {errors.evt_type && (
                                                <ErrorMessage text="이미지나 URL 중 하나는 필수 선택입니다." />
                                            )} */}
                                        </StyledInputContainer>
                                    </>
                                )}
                            </StyledBox>
                            <Grid
                                sx={{ py: 2, mt: '40px' }}
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                                gap="10px"
                            >
                                <Grid item xs="auto">
                                    <ModalButton icon="cancel" onClick={onClose} text="취소" />
                                </Grid>
                                <Grid item xs="auto">
                                    {isDetail ? (
                                        <DefaultButton onClick={typeHandler} text="수정" />
                                    ) : isAdd ? (
                                        <SubmitButton type="submit" text="등록" />
                                    ) : (
                                        <SubmitButton type="submit" text="확인" />
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </ThemeProvider>
            </Dialog>
        </>
    );
}
export default EventModal;
