import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { DatePicker as Picker } from '@mui/x-date-pickers/DatePicker';

import { DATE_FORMAT } from 'configs/search';

import { StyledBox } from 'styles/customize/select';

import { formatDate } from 'utils';

function DatePicker({ defaultValue, term, minDate, maxDate, name, control }) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <Picker
                    mask={term === 'daily' ? '____-__-__' : '____-__'}
                    inputFormat={
                        term === 'daily'
                            ? DATE_FORMAT.daily.replace(/\//g, '-')
                            : DATE_FORMAT.monthly.replace(/\//g, '-')
                    }
                    views={term === 'daily' ? ['day'] : ['month']}
                    value={value || defaultValue}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(e) => onChange(formatDate(term, e))}
                    inputProps={{ 'aria-label': name, placeholder: '' }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <StyledBox
                            sx={{
                                width: '100%',
                                maxWidth: 200,
                                my: '5px',
                                padding: '8.5px 14px 8.5px 14px',
                                '& input': { width: '100%', maxWidth: 200 }
                            }}
                        >
                            <input ref={inputRef} {...inputProps} readOnly />
                            {InputProps?.endAdornment}
                        </StyledBox>
                    )}
                />
            )}
        />
    );
}

DatePicker.propTypes = {
    defaultValue: PropTypes.string,
    term: PropTypes.string,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired
};

DatePicker.defaultProps = {
    defaultValue: formatDate('daily', new Date()),
    term: 'daily',
    minDate: null,
    maxDate: null
};

export default DatePicker;
