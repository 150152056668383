import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import ReactSelect from 'react-select';

import { Paper, Box, Grid, Typography } from '@mui/material';

import useMenu from 'hooks/useMenu';
import useMessage from 'hooks/useMessage';

import { putData, getData } from 'api';
import { DefaultButton, SubmitButton } from 'components/Buttons';
import DatePicker from 'components/FormInputs/DatePicker';
import ReactQuill from 'components/ReactQuill';

import { SCHEMA } from 'configs/form';

import { formatDate } from 'utils';

const formStyles = {
    container: (provided) => ({
        ...provided,
        width: '100%',
        maxWidth: 300,
        height: 36,
        display: 'inline-block',
        fontSize: 12,
        textAlign: 'center'
    })
};

export default function ModifyPolicy() {
    const handleMessage = useMessage();
    const { idx } = useParams();

    const history = useHistory();
    const menu = useMenu({ page: 'Policy', menu: 'policy', menuTitle: '약관 및 방침 관리', menuNum: 10 }); // 페이지/메뉴 설정하기

    const { control, watch } = useForm({
        resolver: yupResolver(SCHEMA[menu])
    });

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const { data: policyData, refetch } = useQuery(['getPolicy', idx], () => getData(`/web/policy/${idx}`, { idx }), {
        enabled: !!idx,
        onError: (error) => {
            console.log('error:', error);
        }
    });

    const [contents, setContents] = useState(policyData?.contents || '');

    const { mutate: modifyPolicy } = useMutation(['modifyPolicy', idx], (body) => putData(`/web/policy/${idx}`, body), {
        onSuccess: () => {
            // 수정 성공 시 목록 화면으로 이동
            refetch();
            history.goBack();
        },
        onError: (error) => {
            console.log('error:', error);
        }
    });

    const onClick = () => {
        // 수정 화면에서는 약관의 내용을 모두 지워도 경고를 띄우지 않음
        const today = formatDate('daily', new Date());
        const start = startDate || formatDate('daily', policyData?.start_dt) || today;
        const end = endDate || formatDate('daily', policyData?.end_dt) || today;
        if (start >= end) {
            // 종료일이 시작일보다 앞 날짜면 알림 모달 띄우기
            handleMessage({ message: '종료일은 시작일보다 같거나 빠를 수 없습니다.' });
            return;
        }
        modifyPolicy({ contents, start_dt: start, end_dt: end });
    };

    const getPolicyType = () => {
        if (policyData?.policy_type === 'S') {
            return { label: '서비스 이용약관' };
        }
        return { label: '개인정보 처리방침' };
    };

    return (
        <>
            <ReactSelect aria-label="정책선택" defaultValue={getPolicyType()} styles={formStyles} isDisabled />
            <Box mb={2.5} />
            <Paper sx={{ p: '20px', borderRadius: '10px' }} elevation={1}>
                <ReactQuill contents={contents || policyData?.contents || ''} setContents={setContents} />

                <Grid container alignItems="center" pl="20px" pr="20px">
                    <Grid item container alignItems="center">
                        <Typography mr={1}>시작일</Typography>
                        <Grid item>
                            <DatePicker defaultValue={policyData?.start_dt} control={control} name="startDate" />
                        </Grid>
                        <Typography ml={3} mr={1}>
                            종료일
                        </Typography>
                        <Grid item>
                            <DatePicker defaultValue={policyData?.end_dt} control={control} name="endDate" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container justifyContent="center" columnGap={1} mt={1}>
                    <Grid item>
                        <DefaultButton text="목록" onClick={() => history.goBack()} />
                    </Grid>
                    <Grid item>
                        <SubmitButton text="수정" onClick={onClick} />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
