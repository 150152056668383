import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress, Typography, Dialog, DialogContent } from '@mui/material';

import { setClose } from 'slices/modalSlice';

export default function ProgressModal() {
    const dispatch = useDispatch();
    const { progressOpen } = useSelector((state) => state.modal);

    const onClose = () => dispatch(setClose());

    return (
        progressOpen && (
            <Dialog
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose(event, reason);
                    }
                }}
                open={progressOpen}
            >
                <DialogContent
                    sx={{
                        height: 320,
                        width: 320,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: '50px'
                    }}
                >
                    <CircularProgress size={55} />
                    <Typography component="p" variant="h3">
                        처리 중입니다. 잠시만 기다려주세요.
                    </Typography>
                </DialogContent>
            </Dialog>
        )
    );
}
