import React, { useState } from 'react';

import { useCookies } from 'react-cookie';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { AccountCircle as AccountCircleIcon, Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Button, IconButton, MenuItem, MenuList, Popover, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { setLogOut } from 'slices/loginSlice';
import { setShowMenu } from 'slices/menuSlice';

import { postData } from 'api';

const StyledAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme: { palette, breakpoints }, open }) => ({
    width: '100%',
    height: 80,
    left: 0,
    display: 'block',
    zIndex: 10,
    color: palette.text.primary,
    backgroundColor: palette.neutral.white,
    [breakpoints.up('lg')]: {
        width: 'calc(100% - 200px)',
        left: 200,
        flexDirection: 'row'
    },
    ...(open && {
        [breakpoints.up('xs')]: {
            width: 'calc(100% - 200px)',
            left: 200,
            flexDirection: 'row'
        }
    })
}));

const StyledToolbar = styled(Toolbar, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme: { breakpoints }, open }) => ({
    '&.MuiToolbar-dense': {
        minHeight: 80
    },
    [breakpoints.up('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
        justifyContent: 'space-between'
    },
    [breakpoints.up('lg')]: {
        justifyContent: 'flex-start'
    },
    ...(open && {
        [breakpoints.up('xs')]: {
            paddingLeft: 0,
            paddingRight: 0,
            justifyContent: 'flex-start'
        }
    })
}));

const StyledUserButton = styled(Button)(({ theme: { palette } }) => ({
    minWidth: 140,
    padding: '10px 20px 10px 10px',
    textAlign: 'left',
    border: 'none',
    boxShadow: 'unset',
    color: 'inherit',
    backgroundColor: palette.neutral.white,
    '& p': {
        fontWeight: 500
    },
    '&:hover': {
        border: 'none',
        boxShadow: 'unset',
        backgroundColor: palette.neutral.white
    },
    '& svg': {
        width: 30,
        height: 30,
        color: palette.primary.main
    }
}));

const StyledMenuItem = styled(MenuItem)(({ theme: { palette } }) => ({
    width: 140,
    height: 32,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 20,
    paddingleft: 0,
    textAlign: 'right',
    borderBottom: `1px solid ${palette.border.opacity01}`,
    '&:last-child': {
        borderBottom: 'none'
    }
}));

export default function Header() {
    const dispatch = useDispatch();
    const { menuTitle, showSidebar } = useSelector((state) => state.menu);
    const [cookies] = useCookies(['cookieID']);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'admin-menu-popover' : undefined;
    const { mutate } = useMutation('logout', () => postData('/web/admin/signout'), {
        onSuccess: () => {
            dispatch(setLogOut());
        }
    });
    const handleLogOut = async () => {
        setAnchorEl(null);
        mutate();
    };

    const onClose = () => setAnchorEl(null);

    const onClick = (e) => setAnchorEl(e.currentTarget);

    return (
        <StyledAppBar position="fixed" elevation={0} open={showSidebar}>
            <StyledToolbar variant="dense" open={showSidebar}>
                <Typography flex={showSidebar ? { xs: 1 } : { lg: 1 }} paddingLeft="20px" component="h2" variant="h2">
                    <IconButton
                        sx={{ pr: 0, mr: '20px', display: { lg: 'none' } }}
                        onClick={() => dispatch(setShowMenu())}
                        aria-label="open drawer"
                        edge="start"
                    >
                        <MenuIcon />
                    </IconButton>
                    {menuTitle}
                </Typography>
                <StyledUserButton
                    aria-describedby={id}
                    disableRipple
                    startIcon={<AccountCircleIcon />}
                    variant="contained"
                    onClick={onClick}
                >
                    <Typography component="p" variant="body2">
                        {cookies.cookieID} 님
                    </Typography>
                </StyledUserButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={onClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    sx={{ '& .MuiPaper-root': { marginTop: '10px' } }}
                >
                    <MenuList component="div" id="admin-menu" aria-labelledby="admin-menu-button">
                        <StyledMenuItem component={Link} to="/admin/info" onClick={onClose}>
                            <Typography width="100%" variant="body2">
                                정보 수정
                            </Typography>
                        </StyledMenuItem>
                        <StyledMenuItem component={Link} to="/admin/password" onClick={onClose}>
                            <Typography width="100%" variant="body2">
                                비밀번호 변경
                            </Typography>
                        </StyledMenuItem>
                        <StyledMenuItem component="button" onClick={handleLogOut}>
                            <Typography width="100%" variant="body2">
                                로그아웃
                            </Typography>
                        </StyledMenuItem>
                    </MenuList>
                </Popover>
            </StyledToolbar>
        </StyledAppBar>
    );
}
