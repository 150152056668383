import React from 'react';

import { useQuery } from 'react-query';
// import ReactQuill from 'react-quill';

import { Paper } from '@mui/material';

import { getData } from 'api';

export default function ViewServicePolicy() {
    const { data: policyData } = useQuery(['getServicePolicy'], () => getData(`/app/terms`, { type: 'S' }), {
        select: ({ data }) => data.contents,
        onError: (error) => {
            console.log('error:', error);
        }
    });
    return (
        <Paper sx={{ p: '20px', borderRadius: '10px' }} elevation={1}>
            <div dangerouslySetInnerHTML={{ __html: policyData || '내용이 없습니다.' }} />
            {/* <ReactQuill value={policyData || '내용없음'} readOnly theme="bubble" /> */}
        </Paper>
    );
}
