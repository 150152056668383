import React from 'react';

import PropTypes from 'prop-types';

import { Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import Logo2 from 'assets/img/logo2.svg';

const StyledTextField = styled(TextField)(({ theme: { palette }, ...props }) => ({
    width: 300,
    '& .MuiInput-root:before': {
        borderBottom: `2px solid ${palette.border.opacity02}`
    },
    '& input': {
        height: 56,
        fontWeight: 500,
        fontSize: 13,
        lineHeight: '56px',
        '&::placeholder': {
            color: props.error ? palette.error.main : 'inherit'
        }
    }
}));

const Label = styled(FormControlLabel)(({ theme: { palette } }) => ({
    marginTop: 20,
    fontSize: 13,
    lineHeight: '20px',
    color: palette.text.label,
    letterSpacing: '-0.48px'
}));

const CheckBox = styled(Checkbox)(({ theme: { palette } }) => ({
    '&.Mui-checked': {
        color: palette.neutral.black
    }
}));

const LoginButton = styled(Button)(({ theme: { palette } }) => ({
    width: '100%',
    height: 48,
    marginTop: 42,
    backgroundColor: palette.primary.main,
    cursor: 'pointer',
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '-0.7px',
    fontWeight: 500,
    color: palette.neutral.white,
    border: 'none',
    outline: 'none',
    transition: 'none',
    '&:hover': {
        backgroundColor: palette.primary.main
    },
    '&:focus': {
        color: palette.neutral.white
    },
    '&:active': {
        color: palette.neutral.white,
        transform: 'scale(0.98)'
    },
    '&:disabled': {
        color: palette.neutral.white
    }
}));

function LoginForm({ idRef, pwRef, status, data, setFocused, onEnter, onChange, handleSubmit }) {
    const { isLoading, isError, isFocused } = status;
    const { id, password, checked } = data;

    return (
        <Box width={305} component="form" noValidate autoComplete="off">
            <Box sx={{ mb: '20px', fontSize: 28, fontWeight: 700, textTransform: 'uppercase', lineHeight: 1.5 }}>
                <img src={Logo2} alt="logo_img" />
                <Box sx={{ fontSize: 20, fontWeight: 300 }}>
                    <span style={{ display: 'block' }}>생활에 꼭 필요한 서비스</span>
                    <span>정품 정보를 더 쉽고 빠르게!</span>
                </Box>
            </Box>
            <StyledTextField
                inputRef={idRef}
                focused={isFocused.id}
                error={isError.id}
                InputLabelProps={{ shrink: false }}
                inputProps={{ 'aria-label': 'id' }}
                label=""
                variant="standard"
                fullWidth
                placeholder={isError.id ? '아이디를 입력해주세요.' : '아이디 입력'}
                name="id"
                value={id}
                onKeyDown={onEnter}
                onChange={onChange}
                onFocus={() => setFocused((prev) => ({ ...prev, id: true }))}
                onBlur={() => setFocused((prev) => ({ ...prev, id: false }))}
                onError={() => setFocused((prev) => ({ ...prev, id: false }))}
            />
            <StyledTextField
                inputRef={pwRef}
                focused={isFocused.password}
                error={isError.password}
                InputLabelProps={{ shrink: false }}
                inputProps={{ 'aria-label': 'password' }}
                label=""
                variant="standard"
                type="password"
                fullWidth
                placeholder={isError.password ? '비밀번호를 입력해주세요.' : '비밀번호 입력'}
                name="password"
                value={password}
                onKeyDown={onEnter}
                onChange={onChange}
                onFocus={() => setFocused((prev) => ({ ...prev, password: true }))}
                onBlur={() => setFocused((prev) => ({ ...prev, password: false }))}
                onError={() => setFocused((prev) => ({ ...prev, password: false }))}
            />
            <Label control={<CheckBox checked={checked} name="checked" onChange={onChange} />} label="아이디 저장" />
            <LoginButton disableRipple disabled={isLoading} onClick={handleSubmit}>
                로그인
            </LoginButton>
        </Box>
    );
}

LoginForm.propTypes = {
    idRef: PropTypes.object.isRequired,
    pwRef: PropTypes.object.isRequired,
    status: PropTypes.shape({
        isLoading: PropTypes.bool.isRequired,
        isError: PropTypes.shape({
            id: PropTypes.bool.isRequired,
            password: PropTypes.bool.isRequired
        }).isRequired,
        isFocused: PropTypes.shape({
            id: PropTypes.bool.isRequired,
            password: PropTypes.bool.isRequired
        }).isRequired
    }).isRequired,
    data: PropTypes.shape({
        id: PropTypes.string,
        password: PropTypes.string,
        checked: PropTypes.bool
    }).isRequired,
    setFocused: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default LoginForm;
