import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Login from 'pages/Login';
import ViewPrivatePolicy from 'pages/Policy/publicPolicy/ViewPrivatePolicy';
import ViewServicePolicy from 'pages/Policy/publicPolicy/ViewServicePolicy';

export default function PublicRouter() {
    return (
        <Switch>
            <Route exact path="/login">
                <Login />
            </Route>
            <Route exact path="/register">
                <Login />
            </Route>
            <Route exact path="/viewServicePolicy" component={ViewServicePolicy} />
            <Route exact path="/viewPrivatePolicy" component={ViewPrivatePolicy} />
            <Redirect path="*" to="/login" />
        </Switch>
    );
}
