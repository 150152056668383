import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';

import { setSearchFilters } from 'slices/searchSlice';

import useSearch from 'hooks/useSearch';

import { DateTermButton, SearchField, SearchRadio, SearchSelect } from 'components';

import {
    SEARCH_COMPONENT as component,
    SEARCH_CAPTION as caption,
    SEARCH_RADIO_ROW,
    SEARCH_SELECT
} from 'configs/search';
// import { BUTTONS } from 'configs/table';

import { StyledInputContainer, StyledLabel } from 'styles/customize/form';
import theme from 'styles/theme/search';

import { handleDateClick } from 'utils';

const StyledBox = styled(Box)(({ theme: { palette, shadows } }) => ({
    width: '100%',
    marginBottom: 20,
    boxShadow: shadows[1],
    borderRadius: 10,
    backgroundColor: palette.neutral.white,
    '& > div:nth-of-type(2) > div > *': {
        height: 60
    }
}));

function Search({ dataList }) {
    const { page: menu } = useSelector((state) => state.menu);
    const { selects, radio, searchKeyword } = component[menu] || {};
    const dispatch = useDispatch();
    const searchState = useSelector((state) => state.search);

    const handleSearch = useSearch({ menu }); // 검색하기

    const [searchCondition, setsearchCondition] = useState('');
    const [inquiryDateType, setInquiryDateType] = useState('reg_dt'); // 문의하기 기간 검색 select
    const [eventDateType, setEventType] = useState('reg_dt'); // 이벤트 기간 검색 select
    const [keyword, setKeyword] = useState('');
    const [dates, setDates] = useState({ startDate: null, endDate: null }); // startDate: 시작일, endDate: 종료일

    useEffect(() => {
        setInquiryDateType(searchState.inquiryDateType || 'reg_dt');
        setEventType(searchState.eventDateType || 'reg_dt');
        setKeyword(searchState.searchKeyword || ''); // 검색어 초기화
        setsearchCondition(searchState.searchCondition || ''); // 검색 조건 초기화
        setDates((prev) => ({
            ...prev,
            start_time: searchState.startDate || null,
            end_time: searchState.endDate || null
        })); // 시작일, 종료일 초기화
    }, [searchState]);

    // 검색하기
    const handleSearchFilter = (obj) => {
        // 새로 검색할 경우 페이지 번호 초기화하기
        if (parseInt(searchState.page, 10) > 1) {
            Object.assign(obj, { page: 1 });
        }

        dispatch(setSearchFilters(obj));
        handleSearch(obj);
    };

    // 기간 검색 버튼
    const onDateBtnClick = (name, months) => {
        const calculatedDates = handleDateClick(name, months); // dates: {startDate, endDate}
        handleSearchFilter(calculatedDates);
    };

    // 검색 조건 (select) 변경
    const onSelect = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'searchCondition ':
                setsearchCondition(value);
                break;
            case 'inquiryDateType':
                setInquiryDateType(value);
                break;
            case 'eventDateType':
                setEventType(value);
                break;
            default:
                handleSearchFilter({ [name]: value });
        }
    };

    // 검색 조건 (radio) 변경
    const onRadioChange = (e) => handleSearchFilter({ [e.target.name]: e.target.value });

    // 검색 기간 (기간 검색 부분의 Date Picker) 변경
    const onDateChange = (name, value) => setDates((prev) => ({ ...prev, [name]: value }));

    // 조회 버튼 클릭
    const onSubmit = (e) => {
        e.preventDefault();
        handleSearchFilter({ ...dates, searchCondition, inquiryDateType, eventDateType, searchKeyword: keyword });
    };

    // 검색 키워드 변경
    const onKeywordChange = (e) => {
        if (e.key === 'Enter') {
            onSubmit(e);
        } else {
            setKeyword(e.target.value);
        }
    };

    // 테이블 데이터 정렬하기
    // const onSort = (e) => handleSearchFilter({ [e.target.name]: e.target.value });

    // 기간검색 select 타입변경
    const handleType = (menu) => {
        if (menu === 'Inquiry') {
            return inquiryDateType;
        }
        return eventDateType;
    };
    const handleSelectName = () => {
        if (menu === 'Inquiry') {
            return 'inquiryDateType';
        }
        return 'eventDateType';
    };
    return (
        <ThemeProvider theme={theme}>
            <StyledBox onSubmit={onSubmit} component="form" autoComplete="off" noValidate>
                {/* <Grid
                    sx={{ height: 70, py: 2, px: '20px', borderBottom: 1, borderColor: 'border.opacity01' }}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h3" component="h3">
                        {heading}
                    </Typography>
                    {addTopButton[menu] && <LinkButton text="디저트 등록" path={path} />}
                </Grid> */}
                <Stack width="100%">
                    {/* <Box sx={{ marginRight: '20px' }}>
                        <span>조회조건</span>
                    </Box> */}
                    <Box display="grid" gridTemplateColumns="160px 1fr 160px 1fr">
                        {menu !== 'Brand' && menu !== 'Product' && menu !== 'Retail' && (
                            <>
                                <StyledLabel sx={{ borderRight: 0 }}>
                                    <Typography color="text.primary" variant="inputLabel">
                                        기간검색
                                    </Typography>
                                </StyledLabel>
                                {/* {menu === 'Inquiry' || menu === 'Event' ? ( */}
                                <StyledInputContainer gridColumn="span 3" columnGap="10px">
                                    <DateTermButton
                                        name={handleSelectName(menu)}
                                        dateType={handleType(menu)}
                                        onSelect={onSelect}
                                        dates={dates}
                                        onChange={onDateChange}
                                        onClick={onDateBtnClick}
                                        eventDateType={eventDateType}
                                    />
                                </StyledInputContainer>
                            </>
                        )}
                        {selects &&
                            SEARCH_SELECT[menu].map((row, index) => (
                                <React.Fragment key={`select-${index}`}>
                                    {row.length > 1 ? (
                                        row.map((type, index) => (
                                            <React.Fragment key={`search-select-${index}`}>
                                                <StyledLabel sx={{ borderRight: 0 }}>
                                                    <Typography color="text.primary" variant="inputLabel">
                                                        {caption[type]}
                                                    </Typography>
                                                </StyledLabel>
                                                <StyledInputContainer>
                                                    <SearchSelect
                                                        name={type}
                                                        dataList={dataList[type]}
                                                        onChange={onSelect}
                                                    />
                                                </StyledInputContainer>
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <>
                                            <StyledLabel sx={{ borderRight: 0 }}>
                                                <Typography color="text.primary" variant="inputLabel">
                                                    {caption[row[0]]}
                                                </Typography>
                                            </StyledLabel>
                                            <StyledInputContainer gridColumn="span 3">
                                                <SearchSelect
                                                    name={row[0]}
                                                    dataList={dataList[row[0]]}
                                                    onChange={onSelect}
                                                />
                                            </StyledInputContainer>
                                        </>
                                    )}
                                </React.Fragment>
                            ))}
                        {radio &&
                            SEARCH_RADIO_ROW[menu].map((row, index) => (
                                <React.Fragment key={`radio-${index}`}>
                                    {row.length > 1 ? (
                                        row.map((type, index) => (
                                            <React.Fragment key={`search-radio-${index}`}>
                                                <StyledLabel sx={{ borderRight: 0 }}>
                                                    <Typography color="text.primary" variant="inputLabel">
                                                        {caption[type]}
                                                    </Typography>
                                                </StyledLabel>
                                                <StyledInputContainer>
                                                    <SearchRadio
                                                        name={type}
                                                        dataList={dataList[type]}
                                                        onChange={onRadioChange}
                                                    />
                                                </StyledInputContainer>
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <>
                                            <StyledLabel sx={{ borderRight: 0 }}>
                                                <Typography color="text.primary" variant="inputLabel">
                                                    {caption[row[0]]}
                                                </Typography>
                                            </StyledLabel>
                                            <StyledInputContainer gridColumn="span 3">
                                                <SearchRadio
                                                    name={row[0]}
                                                    dataList={dataList[row[0]]}
                                                    onChange={onRadioChange}
                                                />
                                            </StyledInputContainer>
                                        </>
                                    )}
                                </React.Fragment>
                            ))}
                        {searchKeyword && menu && (
                            <>
                                <StyledLabel sx={{ borderRight: 0 }}>
                                    <Typography color="text.primary" variant="inputLabel">
                                        {menu === 'Brand' || menu === 'Product' || menu === 'Retail'
                                            ? '조회조건'
                                            : '검색'}
                                    </Typography>
                                </StyledLabel>

                                <StyledInputContainer direction="row" gridColumn="span 3" columnGap="10px">
                                    <SearchField
                                        searchCondition={searchCondition}
                                        searchKeyword={keyword}
                                        dataList={dataList.searchCondition}
                                        onSelect={onSelect}
                                        onChange={onKeywordChange}
                                    />
                                    <Grid item xs="auto">
                                        <Button variant="contained" onClick={onSubmit}>
                                            조회
                                        </Button>
                                    </Grid>
                                </StyledInputContainer>
                            </>
                        )}
                    </Box>
                </Stack>
            </StyledBox>
            {/* <Grid sx={{ pb: '20px' }} container justifyContent="space-between" alignItems="center">
                <Typography fontWeight={500} variant="h4" component="h4">
                    검색된 데이터 : <Box component="strong">{total}</Box>건
                </Typography>
                {menu && <SearchSelect name="sort" onChange={onSort} />}
            </Grid> */}
        </ThemeProvider>
    );
}

Search.propTypes = {
    // path: PropTypes.string,
    // total: PropTypes.number,
    dataList: PropTypes.object
    // heading: PropTypes.string.isRequired
};

Search.defaultProps = {
    // path: '/',
    // total: 0,
    dataList: {}
};

export default Search;
