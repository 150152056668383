import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { TableCell, Typography } from '@mui/material';

const inquiryType = (inquiry) => {
    let result = '';
    switch (inquiry) {
        case '1':
            result = '제품 관련 문의';
            break;
        case '2':
            result = '앱 서비스 관련 문의';
            break;
        case '3':
            result = '기타';
            break;
        default:
            break;
    }
    return result;
};
function ListTable({ row, onEdit }) {
    const { menu } = useSelector((state) => state.menu);

    return (
        <>
            {menu === 'inquiry' && (
                <>
                    <TableCell
                        align="center"
                        sx={{
                            ' & : hover': {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => onEdit(row)}
                    >
                        <Typography>{row.bd_num}</Typography>
                    </TableCell>
                    <TableCell align="center" onClick={() => onEdit(row)}>
                        {inquiryType(row.inqry)}
                    </TableCell>
                    <TableCell
                        align="center"
                        sx={{
                            ' & : hover': {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => onEdit(row)}
                    >
                        {row.com_nm || '-'}
                    </TableCell>
                    <TableCell
                        align="center"
                        sx={{
                            ' & : hover': {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => onEdit(row)}
                    >
                        {row.br_nm || '-'}
                    </TableCell>
                    <TableCell
                        align="center"
                        sx={{
                            ' & : hover': {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => onEdit(row)}
                    >
                        {row.subject || '-'}
                    </TableCell>
                    <TableCell
                        align="center"
                        sx={{
                            ' & : hover': {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => onEdit(row)}
                    >
                        {row.user_id || '-'}
                    </TableCell>
                    <TableCell
                        align="center"
                        sx={{
                            ' & : hover': {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => onEdit(row)}
                    >
                        {row.reg_dt || '-'}
                    </TableCell>
                    <TableCell
                        align="center"
                        sx={{
                            ' & : hover': {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => onEdit(row)}
                    >
                        {row.mod_dt || '-'}
                    </TableCell>
                    <TableCell
                        align="center"
                        sx={{
                            ' & : hover': {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => onEdit(row)}
                    >
                        {row.mn_nm || '-'}
                    </TableCell>
                    <TableCell
                        align="center"
                        sx={{
                            ' & : hover': {
                                cursor: 'pointer'
                            },
                            color: row.answer_yn !== 'Y' && 'red'
                        }}
                        onClick={() => onEdit(row)}
                    >
                        {row.answer_yn === 'Y' ? '답변완료' : '답변대기' || '0'}
                    </TableCell>
                </>
            )}
        </>
    );
}

ListTable.propTypes = {
    row: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired
};

export default ListTable;
