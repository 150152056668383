import { useState, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setSearchFilters } from 'slices/searchSlice';

import { isEmpty, queryToObject } from 'utils';

const useSearchParams = (searchParams, chartSearchParams) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchStates = useSelector((state) => state.search);

    const [newParams, setNewParams] = useState(null);

    const handleSearchParams = useCallback(() => {
        try {
            const params = {}; // API에 보낼 params
            const newObject = {}; // redux store에 보낼 값들
            const search = queryToObject(location.search);

            // 페이지 새로고침 / 검색 조회 등을 할 경우 location.search와 redux store에 있는 값들이 다름
            // 1. location.search에 있는 params와 redux store에 있는 search state를 비교
            // 2. 만약 redux store에 있는 search state와 location.search가 다르다면
            // 3. dispatch를 해서 상태가 동일하도록
            Object.keys(searchParams).forEach((key) => {
                const reduxStateName = searchParams[key]; // searchParams[key] : searchSlice에 있는 search state의 key
                const currentReduxState = searchStates[reduxStateName]; // searchStates[searchParams[key]] : redux store에 있는 search state의 value
                const currentSearchParam = search[reduxStateName] || ''; // search[key] || search[chartSearchParams[reduxStateName]] : location.search 값

                // location.search에 값이 있다면
                if (!isEmpty(currentSearchParam)) {
                    // int값으로 들어가야 에러가 발생안함
                    if (key === 'page' || key === 'pageSize') {
                        params[key] = parseInt(currentSearchParam, 10);

                        if (parseInt(currentReduxState, 10) !== parseInt(currentSearchParam, 10)) {
                            newObject[reduxStateName] = parseInt(currentSearchParam, 10);
                        }
                    } else if (currentReduxState !== currentSearchParam) {
                        // string 값들
                        params[key] = currentSearchParam;
                        newObject[reduxStateName] = currentSearchParam;
                    } else {
                        // location.search와 redux state가 동일한 값들
                        params[key] = currentReduxState || null;
                    }
                } else {
                    // location.search에 없는 값들
                    params[key] = currentReduxState || null;
                }
            });

            // API로 보낼때 page 번호가 0부터 시작하도록
            // location.search에는 page 번호가 1부터 시작
            if (params?.current_page) {
                Object.assign(params, { current_page: params.current_page > 1 ? params.current_page - 1 : 0 });
            }

            // searchKeyword가 있고 searchCondition이 없다면
            if (params?.searchKeyword && isEmpty(params?.searchCondition)) {
                Object.assign(params, { searchCondition: '' });
            }

            // redux store로 값을 dispatch해야할 때 실행되도록
            if (Object.keys(newObject).length > 0) {
                dispatch(setSearchFilters(newObject));
            }

            setNewParams(params);
        } catch (error) {
            console.log(error);
            // page, pageSizea만 default로 넘겨주기 (page는 0부터 시작)
            setNewParams({ current_page: 0 });
        }
    }, [dispatch, searchParams, chartSearchParams, searchStates, location.search]);

    useEffect(() => {
        handleSearchParams();
    }, [handleSearchParams]);

    return newParams;
};

export default useSearchParams;
