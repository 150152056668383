import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Grid, Box } from '@mui/material';

import { SearchDatePicker, SearchSelect } from 'components';

import { DATE_FORMAT, SEARCH_COMPONENT, SEARCH_OPTION as option } from 'configs/search';

function DateTermButton({ name, dateType, onSelect, caption, dates, onChange, eventDateType }) {
    const { page: menu } = useSelector((state) => state.menu);
    const { dateSelect } = SEARCH_COMPONENT[menu] || {};

    return (
        <>
            {dateSelect && (
                <SearchSelect
                    name={name}
                    value={dateType}
                    options={option.userDateType || option.inquiryDateType || option.eventDateType}
                    onChange={onSelect}
                />
            )}
            <SearchDatePicker
                caption={caption}
                dateFormat={DATE_FORMAT.daily}
                term="daily"
                dates={dates}
                onChange={onChange}
                eventDateType={eventDateType}
            />
            <Grid item xs>
                <Box
                    sx={{
                        '& button': { marginRight: '5px' },
                        '& button:last-of-type': { width: 'auto', marginRight: 0 }
                    }}
                />
            </Grid>
        </>
    );
}

DateTermButton.propTypes = {
    name: PropTypes.string,
    dateType: PropTypes.string,
    caption: PropTypes.bool,
    dates: PropTypes.object,
    onSelect: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    eventDateType: PropTypes.string
};

DateTermButton.defaultProps = {
    name: '',
    dateType: '',
    caption: false,
    dates: { startDate: null, endDate: null },
    onSelect: () => {},
    eventDateType: ''
};

export default DateTermButton;
