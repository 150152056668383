/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { ThemeProvider, styled } from '@mui/material/styles';

import { modalSelector, setClose } from 'slices/modalSlice';

import { postData } from 'api';
import { DefaultButton as ModalButton, SubmitButton } from 'components/Buttons';
import { Input, Select } from 'components/FormInputs';

import { SCHEMA } from 'configs/form';

import { StyledBox, StyledInputContainer, StyledLabel } from 'styles/customize/form';
import theme from 'styles/theme/form';

const options = [
    { label: '사용 여부를 선택해주세요.', value: '' },
    { value: 'Y', label: '사용' },
    { value: 'N', label: '미사용' }
];

function RetailModal() {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { retailOpen, retailData } = useSelector(modalSelector);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = useForm({
        // 초기값 설정! --> 안하면 warning이 나타남
        defaultValues: {
            sel_nm: '',
            use_yn: { label: '사용 여부를 선택해주세요.', value: '' }
        },
        resolver: yupResolver(SCHEMA.Retail)
    });

    const { mutate: registRetailerMutation } = useMutation(({ url, data, fileYn }) => postData(url, data, fileYn), {
        onSuccess: async () => {
            await queryClient.refetchQueries('retail');
            reset();
            dispatch(setClose());
        },
        onError: (error) => {
            console.log('브랜드 등록 실패::', error);
        }
    });

    // button + form 연결이 되어있다면
    const onSubmit = (data) => {
        registRetailerMutation({
            url: '/web/seller',
            data: {
                sel_nm: data?.sel_nm,
                use_yn: data?.use_yn.value
            },
            fileYn: false
        });
    };

    // 모달을 닫을때는 반드시 데이터 reset
    const onClose = () => {
        dispatch(setClose());
        // default values
        reset({
            sel_nm: '',
            use_yn: { label: '사용 여부를 선택해주세요.', value: '' }
        });
    };

    return (
        <>
            <Dialog
                open={retailOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose(event, reason);
                    }
                }}
                disableEscapeKeyDown
                sx={{
                    '& .MuiPaper-root': {
                        minWidth: 1145,
                        minHeight: 262,
                        maxWidth: '100%'
                    }
                }}
            >
                <ThemeProvider theme={theme}>
                    <DialogTitle>판매처 {retailData?.modalStatus === 'modify' ? '수정' : '등록'}</DialogTitle>
                    <DialogContent sx={{ height: '100%', paddingBottom: 0 }}>
                        {/* <form /> */}
                        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <StyledBox display="grid" gridTemplateColumns="200px 1fr">
                                <StyledLabel>
                                    <Typography variant="inputLabel">판매처</Typography>
                                </StyledLabel>
                                <StyledInputContainer sx={{ '& .MuiFormControl-root': { width: 320 } }}>
                                    <Input placeholder="판매처를 입력해 주세요." name="sel_nm" control={control} />
                                </StyledInputContainer>
                                <StyledLabel>
                                    <Typography variant="inputLabel">사용 여부</Typography>
                                </StyledLabel>
                                <StyledInputContainer>
                                    <Select name="use_yn" control={control} options={options} />
                                </StyledInputContainer>
                            </StyledBox>
                            <Grid
                                sx={{ py: 2, mt: '40px' }}
                                columnGap={1}
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Grid item xs="auto">
                                    <ModalButton icon="cancel" onClick={onClose} text="취소" />
                                </Grid>
                                <Grid item xs="auto">
                                    <SubmitButton type="submit" text="등록" />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </ThemeProvider>
            </Dialog>
        </>
    );
}
export default RetailModal;
