import React from 'react';

import CS from 'assets/img/ic_menu_cs.svg';
import Member from 'assets/img/ic_menu_member.svg';
import Product from 'assets/img/ic_menu_product.svg';
import Scan from 'assets/img/ic_menu_scan.svg';
import Total from 'assets/img/ic_menu_total.svg';
import Policy from 'assets/img/ic_policy.svg';

const MenuSet = {
    M: [
        {
            setCaption: true,
            caption: '종합현황',
            menu: ['summary', 'signup'],
            icon: <img src={Total} alt="totalImage" />,
            siblings: [
                {
                    num: 0,
                    menu: 'summary',
                    menuTitle: '요약보기',
                    path: '/'
                },
                {
                    num: 1,
                    menu: 'signup',
                    menuTitle: '회원가입',
                    path: '/sign-up'
                }
            ]
        },
        {
            setCaption: true,
            caption: '스캔/구매현황',
            menu: ['scan', 'regist', 'report'],
            icon: <img src={Scan} alt="scanImage" />,
            siblings: [
                {
                    num: 2,
                    menu: 'scan',
                    menuTitle: '스캔하기',
                    path: '/scan'
                },
                {
                    num: 3,
                    menu: 'regist',
                    menuTitle: '구매등록',
                    path: '/regist'
                },
                {
                    num: 4,
                    menu: 'report',
                    menuTitle: '신고목록',
                    path: '/report-list'
                }
            ]
        },
        {
            setCaption: true,
            caption: '계정관리',
            menu: ['user', 'brand'],
            icon: <img src={Member} alt="brandImage" />,
            siblings: [
                {
                    num: 5,
                    menu: 'user',
                    menuTitle: '계정관리',
                    path: '/user-list'
                },
                {
                    num: 6,
                    menu: 'brand',
                    menuTitle: '브랜드관리',
                    path: '/brand-list'
                }
            ]
        },
        {
            setCaption: true,
            caption: '제품/판매처 관리',
            menu: ['product', 'retail'],
            icon: <img src={Product} alt="productImage" />,
            siblings: [
                {
                    num: 11,
                    menu: 'product',
                    menuTitle: '제품관리',
                    path: '/product'
                },
                {
                    num: 12,
                    menu: 'retail',
                    menuTitle: '판매처관리',
                    path: '/retail'
                }
            ]
        },
        {
            setCaption: true,
            caption: '고객센터',
            menu: ['inquiry', 'notice', 'event'],
            icon: <img src={CS} alt="csImage" />,
            siblings: [
                {
                    num: 7,
                    menu: 'inquiry',
                    menuTitle: '1:1문의하기',
                    path: '/inquiry'
                },
                {
                    num: 8,
                    menu: 'notice',
                    menuTitle: '공지사항',
                    path: '/notice'
                },
                {
                    num: 9,
                    menu: 'event',
                    menuTitle: '이벤트',
                    path: '/event'
                }
            ]
        },
        {
            setCaption: true,
            caption: '약관 및 정책',
            menu: ['policy'],
            icon: <img src={Policy} alt="policyImage" />,
            siblings: [
                {
                    num: 10,
                    menu: 'policy',
                    menuTitle: '약관 및 방침 관리',
                    path: '/policy'
                }
            ]
        }
    ],
    A: [
        {
            setCaption: true,
            caption: '종합현황',
            menu: ['summary', 'signup'],
            icon: <img src={Total} alt="totalImage" />,
            siblings: [
                {
                    num: 0,
                    menu: 'summary',
                    menuTitle: '요약보기',
                    path: '/'
                }
            ]
        },
        {
            setCaption: true,
            caption: '스캔/구매현황',
            menu: ['scan', 'regist', 'report'],
            icon: <img src={Scan} alt="scanImage" />,
            siblings: [
                {
                    num: 2,
                    menu: 'scan',
                    menuTitle: '스캔하기',
                    path: '/scan'
                },
                {
                    num: 3,
                    menu: 'regist',
                    menuTitle: '구매등록',
                    path: '/regist'
                },
                {
                    num: 4,
                    menu: 'report',
                    menuTitle: '신고목록',
                    path: '/report-list'
                }
            ]
        },
        {
            setCaption: true,
            caption: '계정관리',
            menu: ['user', 'brand'],
            icon: <img src={Member} alt="brandImage" />,
            siblings: [
                {
                    num: 6,
                    menu: 'brand',
                    menuTitle: '브랜드관리',
                    path: '/brand-list'
                }
            ]
        },
        {
            setCaption: true,
            caption: '제품/판매처 관리',
            menu: ['product', 'retail'],
            icon: <img src={Product} alt="productImage" />,
            siblings: [
                {
                    num: 11,
                    menu: 'product',
                    menuTitle: '제품관리',
                    path: '/product'
                },
                {
                    num: 12,
                    menu: 'retail',
                    menuTitle: '판매처관리',
                    path: '/retail'
                }
            ]
        },
        {
            setCaption: true,
            caption: '고객센터',
            menu: ['inquiry', 'notice', 'event'],
            icon: <img src={CS} alt="csImage" />,
            siblings: [
                {
                    num: 7,
                    menu: 'inquiry',
                    menuTitle: '1:1문의하기',
                    path: '/inquiry'
                },
                {
                    num: 8,
                    menu: 'notice',
                    menuTitle: '공지사항',
                    path: '/notice'
                },
                {
                    num: 9,
                    menu: 'event',
                    menuTitle: '이벤트',
                    path: '/event'
                }
            ]
        },
        {
            setCaption: true,
            caption: '약관 및 정책',
            menu: ['policy'],
            icon: <img src={Policy} alt="policyImage" />,
            siblings: [
                {
                    num: 10,
                    menu: 'policy',
                    menuTitle: '약관 및 방침 관리',
                    path: '/policy'
                }
            ]
        }
    ]
};

export default MenuSet;
