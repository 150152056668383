import React from 'react';

import PropTypes from 'prop-types';

import { Collapse, List, Typography } from '@mui/material';

import { MenuItem } from 'layouts/SideBar/components';

const listStyle = {
    list: {
        '& .MuiListItemButton-root.Mui-selected': {
            backgroundColor: 'background.main',
            '& p': {
                color: 'text.primary'
            },
            '& svg': {
                color: 'text.primary'
            }
        }
    },
    collapsedList: {
        '&& > .MuiListItemButton-root.Mui-selected': {
            backgroundColor: 'background.lightOpacity07',
            '& p': {
                color: 'text.primary'
            },
            '& svg': {
                color: 'text.primary'
            },
            opacity: 0.7
        },
        '& .MuiListItemButton-root.Mui-selected': {
            backgroundColor: 'background.main',
            '& p': {
                color: 'text.primary'
            },
            '& svg': {
                color: 'text.primary'
            }
        }
    }
};

function MenuList({ menuNum, group, open, handler }) {
    const { handleToggleMenu, handlePageChange } = handler;
    return (
        <List
            component="div"
            sx={group.menu.some((menuName) => open[menuName]) ? listStyle.collapsedList : listStyle.list}
            subheader={
                group.setCaption ? (
                    <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="sidebarCaption" display="block">
                        {group.icon}&nbsp;&nbsp;{group.caption}
                    </Typography>
                ) : (
                    <></>
                )
            }
        >
            {group?.siblings?.map((menu, index) => (
                <React.Fragment key={`collapsed-menu-${menu.menu}-${index}`}>
                    {menu?.subMenus ? (
                        <>
                            <MenuItem
                                selected={menu.subMenus.some((subMenu) => subMenu.num === Number(menuNum))}
                                icon={menu.icon}
                                menuTitle={menu.menuTitle}
                                open={open[menu.menu]}
                                collapsed
                                onClick={() => handleToggleMenu(menu.menu)}
                            />
                            <Collapse
                                sx={{
                                    '& .MuiListItemButton-root': {
                                        borderRadius: '4px',
                                        paddingLeft: '46px'
                                    }
                                }}
                                in={open[menu.menu]}
                                unmountOnExit
                            >
                                <List component="div">
                                    {menu.subMenus.map((subMenu) => (
                                        <MenuItem
                                            key={`sub-menu-${subMenu.num}`}
                                            selected={menuNum === subMenu.num}
                                            icon={subMenu.icon}
                                            menuTitle={subMenu.menuTitle}
                                            path={subMenu.path}
                                            onClick={() => handlePageChange(subMenu.path)}
                                        />
                                    ))}
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        <MenuItem
                            selected={menuNum === menu.num}
                            icon={menu.icon}
                            menuTitle={menu.menuTitle}
                            path={menu.path}
                            onClick={() => handlePageChange(menu.path)}
                        />
                    )}
                </React.Fragment>
            ))}
        </List>
    );
}

MenuList.propTypes = {
    menuNum: PropTypes.number,
    open: PropTypes.object.isRequired,
    group: PropTypes.shape({
        setCaption: PropTypes.bool.isRequired,
        caption: PropTypes.string.isRequired,
        menu: PropTypes.arrayOf(PropTypes.string).isRequired,
        icon: PropTypes.object.isRequired,
        siblings: PropTypes.arrayOf(
            PropTypes.shape({
                num: PropTypes.number,
                menu: PropTypes.string.isRequired,
                menuTitle: PropTypes.string.isRequired,
                path: PropTypes.string,
                subMenus: PropTypes.arrayOf(
                    PropTypes.shape({
                        num: PropTypes.number.isRequired,
                        menuTitle: PropTypes.string.isRequired,
                        icon: PropTypes.object.isRequired,
                        path: PropTypes.string.isRequired
                    })
                )
            })
        )
    }).isRequired,
    handler: PropTypes.shape({
        handlePageChange: PropTypes.func.isRequired,
        handleToggleMenu: PropTypes.func.isRequired
    }).isRequired
};

MenuList.defaultProps = {
    menuNum: null
};

export default MenuList;
