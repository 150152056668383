import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

function LinkButton({ size, color, variant, disabled, text, path }) {
    return (
        <Button component={Link} to={path} size={size} color={color} variant={variant} disabled={disabled}>
            {text}
        </Button>
    );
}

LinkButton.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
};

LinkButton.defaultProps = {
    size: 'large',
    color: 'primary',
    variant: 'outlined',
    disabled: false
};

export default LinkButton;
