/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { ThemeProvider, styled } from '@mui/material/styles';

import { modalSelector, setClose, setMessage } from 'slices/modalSlice';

import { postData, getData, putData } from 'api';
import { DefaultButton as ModalButton, SubmitButton } from 'components/Buttons';
import { Input, Select, ErrorMessage } from 'components/FormInputs';

import { SCHEMA } from 'configs/form';

import {
    StyledBox,
    StyledInputContainer,
    StyledLabel,
    StyledLabelForMultiline,
    StyledInputContainerForMultiline,
    StyledInputContainerForRetailers,
    StyledLabelForRetailers
} from 'styles/customize/form';
import theme from 'styles/theme/form';

const options = [
    { label: '사용 여부를 선택해주세요.', value: '' },
    { value: 'Y', label: '사용' },
    { value: 'N', label: '미사용' }
];

const Label = styled(FormControlLabel)(({ theme: { palette } }) => ({
    fontSize: 13,
    lineHeight: '20px',
    color: palette.text.label,
    letterSpacing: '-0.48px'
}));

const CheckBox = styled(Checkbox)(({ theme: { palette } }) => ({
    '&.Mui-checked': {
        color: palette.neutral.black
    }
}));

function ProductModal() {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { productOpen, productData } = useSelector(modalSelector);

    const [retailerList, setRetailerList] = useState(null);
    const [notSelectedSeller, setNotSelectedSeller] = useState(false); // 판매처 선택 안했으면 true, 했으면 false

    const defaultValues = {
        com_nm: { label: '회사명을 선택해주세요', value: '' },
        br_nm: { label: '브랜드를 선택해주세요', value: '' },
        pro_nm: '',
        pro_img: null,
        pro_dc: '',
        pro_url: '',
        use_yn: { label: '사용 여부를 선택해주세요.', value: '' }
    };

    const {
        register,
        handleSubmit,
        control,
        getValues,
        watch,
        reset,
        formState: { errors }
    } = useForm({
        // 초기값 설정! --> 안하면 warning이 나타남
        defaultValues,
        resolver: yupResolver(SCHEMA.Product)
    });

    const companyName = watch('com_nm');

    const { data: comList } = useQuery('getComList', () => getData('/web/common/companyList'), {
        onError: (err) => {
            console.log('err', err);
        }
    });

    const { data: brandList } = useQuery(
        'getBrandList',
        () => getData('/web/common/brandList', { com_idx: companyName?.value }),
        {
            enabled: !!companyName?.value,
            onError: (err) => {
                console.log('err', err);
            }
        }
    );

    const { data: brandListForModify } = useQuery('getBrandListForModify', () => getData('/web/brand/lists'), {
        enabled: productData?.modalStatus === 'modify',
        select: (data) => {
            const result = data.brandLists.map((brand) => {
                return { value: brand.br_idx, label: brand.br_nm };
            });
            return result;
        },
        onError: (err) => {
            console.log('err', err);
        }
    });

    const { data: productDetail } = useQuery(
        ['getProductDetail', productData?.index],
        () => getData(`/web/product/${productData.index}`),
        {
            enabled: productData?.modalStatus === 'modify',
            onError: (err) => {
                console.log('err:', err);
            }
        }
    );

    const { data: sellersList, isLoading } = useQuery(
        'getRetailList',
        () => getData('/web/seller/lists', { limit: 100 }),
        {
            select: (data) => {
                // 판매처 객체에 check 여부에 대한 boolean 데이터를 삽입
                const sellerList = data?.sellerList;
                const resultList = sellerList?.map((sellerObj) => {
                    return { ...sellerObj, checked: false };
                });

                if (productData?.modalStatus === 'modify') {
                    const appliedRetailerList = resultList?.map((retailer) => {
                        if (productDetail?.sel_idx.includes(retailer.sel_idx)) {
                            retailer.checked = true;
                        }
                        return retailer;
                    });
                    return appliedRetailerList;
                }
                return resultList;
            },
            onError: (err) => {
                console.log('err:', err);
            }
        }
    );

    const onChange = (e) => {
        const { checked, tabIndex } = e.target;
        // 해당 타겟(판매처)의 이름과 체크 상태를 파악하여 현재 체크 상태의 반대로 변경

        const changedArr = [
            ...retailerList.slice(0, tabIndex),
            { ...retailerList[tabIndex], checked },
            ...retailerList.slice(tabIndex + 1)
        ];
        setRetailerList(changedArr);
    };

    const { mutate: registProductMutation } = useMutation(({ url, data, fileYn }) => postData(url, data, fileYn), {
        onSuccess: async () => {
            // 제품 등록 성공 시 refetch 신호 redux로 주기
            await queryClient.refetchQueries('product');
            reset(defaultValues);
            setNotSelectedSeller(false);
            dispatch(setClose());
        },
        onError: (error) => {
            console.log('브랜드 등록 실패::', error);
        }
    });

    const { mutate: editProductMutation } = useMutation(({ url, data, fileYn }) => putData(url, data, fileYn), {
        onSuccess: async () => {
            // 제품 등록 성공 시 refetch 신호 redux로 주기
            await queryClient.refetchQueries('product');
            reset(defaultValues);
            setNotSelectedSeller(false);
            dispatch(setClose());
        },
        onError: (error) => {
            console.log('브랜드 수정 실패::', error);
        }
    });

    // button + form 연결이 되어있다면
    const onSubmit = (data) => {
        // 판매처 체크리스트는 hook-form 사용하지 않음. 추후 고민해서 포함시킬 예정
        const filteredList = retailerList.filter((retailer) => retailer.checked);
        const selIndexList = filteredList.map((seller) => seller.sel_idx);
        if (selIndexList.length === 0) {
            // 선택한 판매처가 없는 경우
            return setNotSelectedSeller(true);
        }
        if (data?.pro_img.length === 0) {
            // 이미지 첨부 없을 시 실행되지 않게 조치
            return 0;
        }

        const formData = new FormData();
        formData.append('br_idx', data.br_nm.value);
        formData.append('com_idx', 1);
        formData.append('pro_nm', data.pro_nm);
        formData.append('sel_idx', selIndexList.toString());
        formData.append('pro_dc', data.pro_dc);
        formData.append('pro_url', data.pro_url);
        formData.append('use_yn', data.use_yn.value);
        formData.append('pro_img', data.pro_img[0]);

        if (productData?.modalStatus === 'modify') {
            return editProductMutation({
                url: `/web/product/${productData?.index}`,
                data: formData,
                fileYn: true
            });
        }

        return registProductMutation({
            url: '/web/product',
            data: formData,
            fileYn: true
        });
    };

    // 모달을 닫을때는 반드시 데이터 reset
    const onClose = () => {
        reset(defaultValues);

        setRetailerList(sellersList);
        setNotSelectedSeller(false);
        dispatch(setClose());
    };

    useEffect(() => {
        setRetailerList(sellersList);
    }, [sellersList]);

    useEffect(() => {
        // 제품 수정 화면인 경우 초기 데이터를 넣어줌
        if (productData?.modalStatus === 'modify') {
            const comIdx = comList?.findIndex((company) => company.value === productDetail?.com_idx);
            const brIdx = brandListForModify?.findIndex((brand) => brand.value === productDetail?.br_idx);
            const useIndex = options?.findIndex((option) => option.value === productDetail?.use_yn);

            if (brandListForModify && productDetail) {
                reset({
                    com_nm: comList?.[comIdx],
                    br_nm: brandListForModify?.[brIdx],
                    pro_nm: productDetail?.pro_nm,
                    pro_img: productDetail?.pro_img,
                    pro_dc: productDetail?.pro_dc,
                    pro_url: productDetail?.pro_url,
                    use_yn: options[useIndex]
                });
            }
        }
    }, [productData, reset, brandList, productDetail, options, comList, brandListForModify]);

    return (
        <>
            <Dialog
                open={productOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose(event, reason);
                    }
                }}
                disableEscapeKeyDown
                sx={{
                    '& .MuiPaper-root': {
                        minWidth: 1145,
                        minHeight: 462,
                        maxWidth: '100%'
                    }
                }}
            >
                <ThemeProvider theme={theme}>
                    <DialogTitle>제품 {productData?.modalStatus === 'modify' ? '수정' : '등록'}</DialogTitle>
                    <DialogContent sx={{ height: '100%', paddingBottom: 0 }}>
                        {/* <form /> */}
                        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <StyledBox display="grid" gridTemplateColumns="200px 1fr">
                                <StyledLabel>
                                    <Typography variant="inputLabel">회사명</Typography>
                                </StyledLabel>
                                <StyledInputContainer>
                                    <Select
                                        name="com_nm"
                                        control={control}
                                        options={comList || [{ value: '', label: '회사를 선택해 주세요.' }]}
                                    />
                                    {errors.com_nm && <ErrorMessage text="회사명 선택은 필수입니다." />}
                                </StyledInputContainer>
                                {(companyName.value || productData?.modalStatus === 'modify') && (
                                    <>
                                        <StyledLabel>
                                            <Typography variant="inputLabel">브랜드</Typography>
                                        </StyledLabel>
                                        <StyledInputContainer>
                                            <Select
                                                name="br_nm"
                                                control={control}
                                                options={brandList || [{ value: '', label: '브랜드 선택' }]}
                                            />
                                            {errors.br_nm && <ErrorMessage text="브랜드 선택은 필수입니다." />}
                                        </StyledInputContainer>
                                    </>
                                )}

                                <StyledLabel>
                                    <Typography variant="inputLabel">제품명</Typography>
                                </StyledLabel>
                                <StyledInputContainer sx={{ '& .MuiFormControl-root': { width: 320 } }}>
                                    <Input placeholder="제품명을 입력해 주세요." name="pro_nm" control={control} />
                                    {errors.pro_nm && <ErrorMessage text="제품명 입력은 필수입니다." />}
                                </StyledInputContainer>
                                <StyledLabelForRetailers>
                                    <Typography variant="inputLabel">판매처</Typography>
                                </StyledLabelForRetailers>
                                <StyledInputContainerForRetailers
                                    sx={{ '& .MuiFormControl-root': { width: 320 }, flexWrap: 'wrap' }}
                                >
                                    {isLoading
                                        ? '로딩중'
                                        : retailerList?.map((retailer, idx) => {
                                              return (
                                                  <Label
                                                      key={retailer.sel_idx}
                                                      control={
                                                          <CheckBox
                                                              tabIndex={idx}
                                                              name={retailer.sel_nm}
                                                              checked={retailer.checked}
                                                              onChange={onChange}
                                                          />
                                                      }
                                                      label={retailer.sel_nm}
                                                  />
                                              );
                                          })}
                                    {notSelectedSeller && <ErrorMessage text="판매처 선택은 필수입니다." />}
                                </StyledInputContainerForRetailers>
                                <StyledLabel>
                                    <Typography variant="inputLabel">제품 이미지(750x750)</Typography>
                                </StyledLabel>
                                <StyledInputContainer>
                                    {productData?.modalStatus === 'modify' ? (
                                        <>
                                            <input type="file" {...register('pro_img')} />
                                            <img
                                                src={productDetail?.pro_img}
                                                alt="pro_img"
                                                style={{ width: 36, height: 36 }}
                                            />
                                        </>
                                    ) : (
                                        <input type="file" {...register('pro_img')} />
                                    )}

                                    {(errors.pro_img || getValues('pro_img')?.length === 0) && (
                                        <ErrorMessage text="제품 이미지 첨부는 필수입니다." />
                                    )}
                                </StyledInputContainer>
                                <StyledLabelForMultiline>
                                    <Typography variant="inputLabel">내용</Typography>
                                </StyledLabelForMultiline>
                                <StyledInputContainerForMultiline>
                                    <Input
                                        fullWidth
                                        placeholder="제품 관련 내용을 입력해 주세요."
                                        name="pro_dc"
                                        multiline
                                        rows={6}
                                        control={control}
                                    />
                                    {errors.pro_dc && <ErrorMessage text="내용 입력은 필수입니다." />}
                                </StyledInputContainerForMultiline>
                                <StyledLabel>
                                    <Typography variant="inputLabel">상세 URL</Typography>
                                </StyledLabel>
                                <StyledInputContainer>
                                    <Input
                                        placeholder="제품 관련 URL이 있다면 입력해 주세요."
                                        name="pro_url"
                                        control={control}
                                    />
                                </StyledInputContainer>
                                <StyledLabel>
                                    <Typography variant="inputLabel">사용 여부</Typography>
                                </StyledLabel>
                                <StyledInputContainer>
                                    <Select name="use_yn" control={control} options={options} />
                                    {errors.use_yn && <ErrorMessage text="사용 여부 선택은 필수입니다." />}
                                </StyledInputContainer>
                            </StyledBox>
                            <Grid
                                sx={{ py: 2, mt: '40px' }}
                                columnGap={1}
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Grid item xs="auto">
                                    <ModalButton icon="cancel" onClick={onClose} text="취소" />
                                </Grid>
                                <Grid item xs="auto">
                                    <SubmitButton
                                        type="submit"
                                        text={productData?.modalStatus === 'modify' ? '수정' : '등록'}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </ThemeProvider>
            </Dialog>
        </>
    );
}
export default ProductModal;
