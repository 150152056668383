import React, { useState } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@mui/material';

import { setClose } from 'slices/modalSlice';

import { DefaultButton as CloseButton } from 'components/Buttons';

import palette from 'styles/theme/palette';

const ChangeList = [
    { id: 1, title: '• 개인정보 수집항목' },
    { id: 2, title: '• 개인정보 이용 및 보유기간' },
    { id: 3, title: '• 개인정보 열람청구' },
    { id: 4, title: '관련 법률 내용 추가' }
];
function DetailModal() {
    const dispatch = useDispatch();
    const { detailOpen } = useSelector((state) => state.modal);
    const [neverSee, setNeverSee] = useState(false);
    const [, setCookie] = useCookies(['neverSee']);

    const onClose = () => {
        if (neverSee) setCookie('neverSee', true, { maxAge: 60 * 60 * 24 * 14 });
        dispatch(setClose());
    };
    return (
        detailOpen && (
            <Dialog
                open={detailOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose(event, reason);
                    }
                }}
            >
                <DialogTitle sx={{ fontSize: 22 }}>개인정보 처리방침 개정 고지</DialogTitle>
                <DialogContent sx={{ minWidth: 320 }}>
                    <Typography component="p" variant="p" sx={{ marginBottom: '40px', fontSize: 14, fontWeight: 500 }}>
                        SCANUS의 개인정보 처리 방침이 개정되어 <br />
                        2022.08.03(수) 부터 적용됨을 알려드립니다.
                    </Typography>
                    <Typography component="p" sx={{ fontWeight: 700, fontSize: 14, marginBottom: '10px' }}>
                        주요 변경사항
                    </Typography>
                    <Box sx={{ padding: '20px', backgroundColor: palette.background.main, paddingLeft: '30px' }}>
                        {ChangeList.map(({ id, title }) => (
                            <Typography
                                key={id}
                                omponent="p"
                                sx={{ fontWeight: 700, fontSize: 13, lineHeight: '20px' }}
                            >
                                {title}
                            </Typography>
                        ))}
                    </Box>
                    <Typography component="p" sx={{ fontWeight: 500, fontSize: 14, margin: '10px 0 20px 0' }}>
                        SCANUS 개인정보 처리방침 :&nbsp;
                        <a
                            style={{ textDecoration: 'underLine', color: 'skyblue' }}
                            href="https://blockodyssey.io"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.blockodyssey.io
                        </a>
                    </Typography>
                    <Typography component="p" sx={{ fontWeight: 500, fontSize: 14, margin: '10px 0 20px 0' }}>
                        변경된 개인정보 처리방침 전문은 위 링크를 통해 확인하실 수 있으며
                        <br />
                        <strong>전체메뉴 약관 및 정책</strong>&nbsp;에서 상시 확인하실 수 있습니다.
                    </Typography>
                    <Typography component="p" sx={{ fontWeight: 500, fontSize: 14, marginTop: '40px' }}>
                        본 개인정보 처리방침 개정 관련 공지는
                        <br />
                        2022년 07월 04일부터 <strong>30일</strong>간 게시됩니다.
                        <br />
                        <br />
                        감사합니다.
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ p: '20px', justifyContent: 'space-between' }}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={neverSee} onChange={() => setNeverSee(!neverSee)} />}
                            label="다시 보지 않기"
                            sx={{ marginTop: '-10px', '& span': { fontWeight: 700, fontSize: 12 } }}
                        />
                    </FormGroup>
                    <CloseButton size="small" variant="contained" text="닫기" onClick={onClose} />
                </DialogActions>
            </Dialog>
        )
    );
}

export default DetailModal;
