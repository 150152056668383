import React from 'react';

import useGetList from 'hooks/useGetList';
import useMenu from 'hooks/useMenu';

import { Search } from 'components';

import ReportTable from './components/ReportTable';

export default function ReportList() {
    const menu = useMenu({ page: 'Report', menu: 'report', menuTitle: '신고목록', menuNum: 4 }); // 페이지/메뉴 설정하기
    const { isLoading, data: dataList } = useGetList({ menu, url: '/web/report/lists' }); // 리스트 데이터 가져오기

    return (
        <>
            <Search path="/report-list" total={dataList?.totalCount} />
            <ReportTable isLoading={isLoading} data={dataList?.reportLists} total={dataList?.totalCount} />
        </>
    );
}
