import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
    Image as ImageIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import { Grid, Box } from '@mui/material';

import {
    StyledDefaultImage,
    StyledImageButton,
    StyledImageContainer,
    StyledNoImage,
    StyledSmallerImage
} from 'styles/customize/page';

function Image({ images, alt }) {
    const [imgError, setImgError] = useState(false);
    const [smallImgError, setSmallImgError] = useState({});
    const [imageIndex, setImageIndex] = useState(0);

    // 작은 이미지 클릭
    const onImageClick = (index) => setImageIndex(index);

    // 이미지 이전 버튼
    const onPreviousImage = () => {
        if (imageIndex <= 0) {
            setImageIndex(images.length - 1);
        } else {
            setImageIndex((prevState) => prevState - 1);
        }
    };

    // 이미지 다음 버튼
    const onNextImage = () => {
        if (imageIndex >= images.length - 1) {
            setImageIndex(0);
        } else {
            setImageIndex((prevState) => prevState + 1);
        }
    };

    return (
        <StyledImageContainer>
            {images?.length > 0 ? (
                <>
                    <Grid
                        sx={{
                            position: 'relative',
                            width: 500,
                            height: 500,
                            overflow: 'hidden',
                            '& img': { borderRadius: '4px' }
                        }}
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        {imgError ? (
                            <StyledDefaultImage>
                                <ImageIcon />
                            </StyledDefaultImage>
                        ) : (
                            <img
                                height="100%"
                                onError={() => setImgError(true)}
                                src={images[imageIndex].img_detail}
                                alt={alt}
                            />
                        )}
                        {images.length > 1 && (
                            <Grid
                                sx={{ position: 'absolute', width: 500, height: 500 }}
                                item
                                xs
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <StyledImageButton
                                    startIcon={<ChevronLeftIcon />}
                                    variant="contained"
                                    onClick={onPreviousImage}
                                />
                                <StyledImageButton
                                    endIcon={<ChevronRightIcon />}
                                    variant="contained"
                                    onClick={onNextImage}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        sx={{ width: 500, margin: '20px auto', '& img:last-of-type': { margin: 0 } }}
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        {images.map((img, index) => (
                            <Box
                                width={70}
                                height={70}
                                key={index}
                                role="button"
                                tabIndex={0}
                                onClick={() => onImageClick(index)}
                                onKeyDown={() => onImageClick(index)}
                            >
                                {smallImgError[index] ? (
                                    <StyledDefaultImage sx={{ width: 70 }}>
                                        <ImageIcon />
                                    </StyledDefaultImage>
                                ) : (
                                    <StyledSmallerImage
                                        width="100%"
                                        height="100%"
                                        onError={() => setSmallImgError((prev) => ({ ...prev, [index]: true }))}
                                        src={img.img_detail}
                                        alt={alt}
                                    />
                                )}
                            </Box>
                        ))}
                    </Grid>
                </>
            ) : (
                <Grid container justifyContent="flex-start" alignItems="center">
                    <StyledNoImage>image not found</StyledNoImage>
                </Grid>
            )}
        </StyledImageContainer>
    );
}

Image.propTypes = {
    images: PropTypes.array.isRequired,
    alt: PropTypes.string.isRequired
};

export default Image;
