/* eslint-disable camelcase */

import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Box, Grid, Paper, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import useFormError from 'hooks/useFormError';
import useMenu from 'hooks/useMenu';
import useMessage from 'hooks/useMessage';

import { getData, putData } from 'api';
import { SubmitButton, Input } from 'components';

import { SCHEMA } from 'configs/form';

import { StyledBox, StyledInputContainer, StyledLabel } from 'styles/customize/form';
import theme from 'styles/theme/form';

export default function ChangeInfo() {
    const menu = useMenu({ page: 'ChangeInfo', menu: null, menuTitle: '정보 수정', menuNum: null });
    const queryClient = useQueryClient();
    const defaultValues = {
        admin_id: '',
        admin_idx: null,
        admin_type: '',
        com_idx: null,
        con_state: '',
        con_text: null,
        mn_email: '',
        mn_nm: '',
        mn_tel: '',
        mod_dt: '',
        reg_dt: ''
    };

    const {
        formState: { errors },
        clearErrors,
        reset,
        control,
        handleSubmit
    } = useForm({
        defaultValues,
        resolver: yupResolver(SCHEMA[menu])
    });
    const handleMessage = useMessage();

    const { data: myInfo } = useQuery('getMyInfo', () => getData('web/admin/myInfo'), {
        onSuccess: (data) => reset(data)
    });
    const { mutate } = useMutation('editMyInfo', (body) => putData('web/admin/myInfo', body), {
        onSuccess: async () => {
            handleMessage({ message: '수정되었습니다.' });
            await queryClient.refetchQueries('getMyInfo');
        }
    });

    useEffect(() => {
        clearErrors();
    }, [clearErrors, reset]);

    // 데이터 수정하기
    const onSubmit = (data) => {
        const { mn_nm, mn_tel, mn_email } = data;
        mutate({ mn_nm, mn_tel, mn_email });
    };
    useFormError(errors);

    return (
        <Paper sx={{ width: '100%', height: '100%', pb: '20px', bgcolor: 'neutral.white' }} elevation={1}>
            <ThemeProvider theme={theme}>
                <Box p="20px 20px 0" component="form" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <StyledBox mb="20px" display="grid" gridTemplateColumns="200px 1fr">
                        <StyledLabel>
                            <Typography variant="inputLabel">아이디</Typography>
                        </StyledLabel>
                        <StyledInputContainer>
                            <span>{myInfo?.admin_id}</span>
                        </StyledInputContainer>

                        <StyledLabel>
                            <Typography variant="inputLabel">이름</Typography>
                        </StyledLabel>
                        <StyledInputContainer>
                            <Input
                                inputProps={{ maxLength: 20 }}
                                name="mn_nm"
                                control={control}
                                placeholder="이름을 입력해주세요"
                            />
                        </StyledInputContainer>
                        <StyledLabel>
                            <Typography variant="inputLabel">전화번호</Typography>
                        </StyledLabel>
                        <StyledInputContainer>
                            <Input
                                inputProps={{ maxLength: 11 }}
                                name="mn_tel"
                                control={control}
                                placeholder="전화번호를 입력해주세요"
                            />
                        </StyledInputContainer>
                        <StyledLabel>
                            <Typography variant="inputLabel">이메일</Typography>
                        </StyledLabel>
                        <StyledInputContainer>
                            <Input
                                inputProps={{ maxLength: 50 }}
                                name="mn_email"
                                control={control}
                                placeholder="이메일을 입력해주세요"
                            />
                        </StyledInputContainer>
                    </StyledBox>
                    <Grid container justifyContent="flex-end" alignItems="center">
                        <SubmitButton type="submit" text="정보 수정하기" />
                    </Grid>
                </Box>
            </ThemeProvider>
        </Paper>
    );
}
