import React from 'react';

import { useHistory } from 'react-router-dom';

import { Box, Button, Grid, Typography } from '@mui/material';

export default function Page404() {
    const history = useHistory();

    return (
        <Grid height="100%" container justifyContent="center" alignItems="center">
            <Box
                sx={{
                    width: 700,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            >
                <Box
                    sx={{
                        width: 592,
                        height: 378,
                        fontSize: 170,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    404
                </Box>
                <Typography mb="20px" component="h3" variant="404">
                    페이지를 찾을 수 없습니다.
                </Typography>
                <Button
                    sx={{
                        width: 196,
                        height: 40,
                        marginBottom: '16px',
                        borderRadius: '4px',
                        fontSize: 13,
                        fontWeight: 700,
                        lineHeight: '17px',
                        letterSpacing: '-0.52px'
                    }}
                    color="secondary"
                    variant="contained"
                    disableRipple
                    onClick={() => history.replace('/')}
                >
                    메인 페이지로 돌아가기
                </Button>
            </Box>
        </Grid>
    );
}
