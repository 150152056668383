import React from 'react';

import PropTypes from 'prop-types';

import { Button, CircularProgress } from '@mui/material';

function SubmitButton({ type, color, loading, disabled, text, onClick }) {
    return (
        <Button
            sx={{
                '&.Mui-disabled': {
                    border: 1,
                    borderColor: 'disabled.primary',
                    backgroundColor: 'disabled.primary'
                }
            }}
            type={type}
            size="large"
            color={color}
            variant="outlined"
            startIcon={loading ? <CircularProgress color="primary" aria-label="loading submit" size={12} /> : null}
            disabled={!!(loading || disabled)}
            onClick={onClick}
        >
            {text}
        </Button>
    );
}

SubmitButton.propTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired
};

SubmitButton.defaultProps = {
    type: 'button',
    color: 'primary',
    loading: false,
    disabled: false,
    onClick: () => {}
};
export default SubmitButton;
