import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { setLogOut } from 'slices/loginSlice';
import { setClose } from 'slices/modalSlice';

import { DefaultButton as ModalButton } from 'components/Buttons';

function MessageModal() {
    const dispatch = useDispatch();

    const { msgOpen, messageType, message, isExpiredToken } = useSelector((state) => state.modal);
    const onClose = () => {
        // 메시지 모달에서 확인 클릭 시 로그아웃 처리 및 로그인 페이지 이동
        if (isExpiredToken) {
            dispatch(setClose());
            dispatch(setLogOut());
        } else {
            dispatch(setClose());
        }
    };

    return (
        msgOpen &&
        messageType === 'message' && (
            <Dialog
                open={msgOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose(event, reason);
                    }
                }}
            >
                <DialogTitle>알림</DialogTitle>
                <DialogContent>
                    <Typography minWidth={320} variant="body2">
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ p: '20px' }}>
                    <ModalButton size="small" variant="contained" text="확인" onClick={onClose} />
                </DialogActions>
            </Dialog>
        )
    );
}

export default MessageModal;
