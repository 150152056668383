import { Grid, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSearchContainer = styled(Grid)(({ theme: { palette, shadows } }) => ({
    width: '100%',
    margin: '0 auto 20px',
    paddingBottom: 20,
    borderRadius: 10,
    backgroundColor: palette.neutral.white,
    boxShadow: shadows[1]
}));

export const StyledSelect = styled(Select)(({ theme: { palette } }) => ({
    minWidth: 120,
    maxWidth: 150,
    backgroundColor: palette.neutral.white
}));
