import React from 'react';

import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { Paper } from '@mui/material';

import useMenu from 'hooks/useMenu';

import { getData } from 'api';

export default function ViewPolicy() {
    const { idx } = useParams();
    const menu = useMenu({ page: 'Policy', menu: 'policy', menuTitle: '약관 및 방침 관리', menuNum: 10 }); // 페이지/메뉴 설정하기

    const { data: policyData } = useQuery(['getPolicy', menu, idx], () => getData(`/web/policy/${idx}`), {
        onError: (error) => {
            console.log('error:', error);
        }
    });
    return (
        <Paper sx={{ p: '20px', borderRadius: '10px' }} elevation={1}>
            <div dangerouslySetInnerHTML={{ __html: policyData?.contents || '내용이 없습니다.' }} />

            {/* <ReactQuill value={policyData?.contents || '기본텍스트'} readOnly theme="bubble" /> */}
        </Paper>
    );
}
