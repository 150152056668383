import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { FormControlLabel, RadioGroup, Radio } from '@mui/material';

function RadioButton({ row, name, options, control }) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <RadioGroup aria-label="radio" row={row} value={value} onChange={(e) => onChange(e.target.value)}>
                    {options.map((radio, index) => (
                        <FormControlLabel
                            key={`radio-${index}`}
                            label={radio.label}
                            value={radio.value}
                            control={<Radio color="primary" />}
                        />
                    ))}
                </RadioGroup>
            )}
        />
    );
}

RadioButton.propTypes = {
    row: PropTypes.bool,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    control: PropTypes.object.isRequired
};

RadioButton.defaultProps = {
    row: true
};

export default RadioButton;
