import React, { useState, useEffect, useCallback } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Box, ButtonBase, Drawer, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { menuSelector } from 'slices/menuSlice';
import { reset } from 'slices/searchSlice';

import { Copyright, MenuList } from 'layouts/SideBar/components';

import 'react-perfect-scrollbar/dist/css/styles.css';
import { ReactComponent as ScanusLogo } from 'assets/img/logo.svg';

import MenuSet from 'constants/menu';

import { isEmpty } from 'utils';

const drawerWidth = 200;
const StyledNav = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme: { palette, transitions, breakpoints }, open }) => ({
    display: 'flex',
    [breakpoints.up('xs')]: {
        width: 0,
        transition: transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.leavingScreen
        }),
        ...(open && {
            width: drawerWidth,
            transition: transitions.create('width', {
                easing: transitions.easing.easeOut,
                duration: transitions.duration.enteringScreen
            })
        }),
        '& .MuiPaper-root': {
            display: 'none',
            transition: transitions.create('display', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.leavingScreen
            }),
            ...(open && {
                display: 'flex',
                transition: transitions.create('display', {
                    easing: transitions.easing.easeOut,
                    duration: transitions.duration.enteringScreen
                })
            })
        }
    },
    [breakpoints.up('lg')]: {
        width: drawerWidth,
        '& .MuiPaper-root': {
            display: 'flex'
        }
    },
    '& .MuiDrawer-root': {
        width: 'inherit',
        height: '100%',
        boxSizing: 'border-box',
        '& .MuiDrawer-paper': {
            width: 'inherit',
            top: 0,
            bottom: 0,
            paddingTop: 0,
            color: palette.neutral.white,
            backgroundColor: palette.primary.main,
            borderRight: 'none',
            '& .scrollbar-container': {
                height: 'calc(100% - 200px)'
            }
        }
    }
}));
const StyledBox = styled(Box)(({ theme: { palette } }) => ({
    width: drawerWidth,
    height: 80,
    backgroundColor: palette.primary.main,
    marginBottom: 20
}));

const StyledLogoButton = styled(ButtonBase)(() => ({
    width: drawerWidth,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
}));

function SideBar() {
    const dispatch = useDispatch();
    const { menuNum, showSidebar } = useSelector(menuSelector);
    const [open, setOpen] = useState({});
    const admin = localStorage.getItem('adminType');
    const menuType = (() => {
        switch (admin) {
            case 'M':
                return 'M';
            case 'A':
                return 'A';
            default:
                return 'A';
        }
    })();

    const handler = {
        handleToggleMenu: (menu) => setOpen((prev) => ({ ...prev, [menu]: !prev[menu] })),
        handlePageChange: () => dispatch(reset())
    };

    const handleMenu = useCallback(() => {
        if (!isEmpty(menuNum)) {
            const initMenu = {};
            MenuSet[menuType]?.map((group) => {
                group?.siblings?.map((menus) => {
                    if (menus.subMenus) {
                        initMenu[menus.menu] = menus.subMenus.some((menu) => menu.num === Number(menuNum));
                    } else {
                        initMenu[menus.menu] = false;
                    }
                    return initMenu;
                });
                return initMenu;
            });
            setOpen(initMenu);
        }
    }, [menuNum, menuType, admin]);

    useEffect(() => {
        handleMenu();
    }, [handleMenu, admin]);

    return (
        <StyledNav component="nav" aria-label="mailbox folders" open={showSidebar}>
            <Drawer variant="permanent" anchor="left">
                <StyledBox>
                    <StyledLogoButton disableRipple component={Link} to="/">
                        <Typography sx={{ width: '100%', marginLeft: '12px' }} variant="h1">
                            <ScanusLogo />
                        </Typography>
                    </StyledLogoButton>
                </StyledBox>
                <PerfectScrollbar component="div">
                    {MenuSet[menuType]?.map((group) => (
                        <React.Fragment key={`navigation-${group.caption}`}>
                            <MenuList group={group} menuNum={menuNum} open={open} handler={handler} />
                            <br />
                        </React.Fragment>
                    ))}
                </PerfectScrollbar>
                <Copyright />
            </Drawer>
        </StyledNav>
    );
}

export default SideBar;
