import React, { useState } from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import {
    Skeleton,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid
} from '@mui/material';

import ChartTitle from './ChartTitle';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
        datalabels: {
            formatter: () => {
                return null;
            }
        }
    },
    scales: {
        x: {
            stacked: true,
            ticks: {
                min: 0,
                max: 100,
                stepSize: 20
            }
        },
        y: {
            stacked: true,
            afterTickToLabelConversion: (chart) => {
                chart.ticks = [];
                chart.ticks.push({ value: 0, label: '0%' });
                chart.ticks.push({ value: 20, label: '20%' });
                chart.ticks.push({ value: 40, label: '40%' });
                chart.ticks.push({ value: 60, label: '60%' });
                chart.ticks.push({ value: 80, label: '80%' });
                chart.ticks.push({ value: 100, label: '100%' });
            }
        }
    }
};

const labels = ['면세점', '백화점', '직영점', '온라인', '올리브영', '기타'];

const data = {
    labels,
    datasets: [
        {
            label: '정상',
            data: labels.map(() => 30),
            backgroundColor: '#3F72E6',
            barThickness: 10
        },
        {
            label: '비유통',
            data: labels.map(() => 70),
            backgroundColor: '#F5D445',
            barThickness: 10
        }
    ]
};

const rows = [
    {
        channelName: '면세점',
        channelValue: 200,
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4,
        value5: 5,
        value6: 6
    },
    {
        channelName: '백화점',
        channelValue: 200,
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4,
        value5: 5,
        value6: 6
    },
    {
        channelName: '직영점',
        channelValue: 200,
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4,
        value5: 5,
        value6: 6
    },
    {
        channelName: '온라인',
        channelValue: 200,
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4,
        value5: 5,
        value6: 6
    },
    {
        channelName: '올리브영',
        channelValue: 200,
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4,
        value5: 5,
        value6: 6
    },
    {
        channelName: '기타',
        channelValue: 200,
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4,
        value5: 5,
        value6: 6
    },
    {
        channelName: '합계',
        channelValue: 200,
        value1: 10,
        value2: 22,
        value3: 33,
        value4: 44,
        value5: 55,
        value6: 66
    }
];

const getRatio = (target, base) => {
    return `(${Math.round((target / base) * 100)}%)`;
};

function BarChart({ barLoading, barChartData }) {
    const [chartData] = useState(rows);

    return barLoading ? (
        <Skeleton variant="rectangular" width="100%" height={500} />
    ) : (
        <>
            <ChartTitle title="유통채널별 유입현황" />
            <Box sx={{ position: 'relative', width: '100%', height: 200, marginTop: '20px' }}>
                <Bar options={options} data={barChartData.length > 0 || data} />
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" sx={{ '&&& *': { height: '15px' } }}>
                    <TableHead sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                        <TableRow sx={{ borderBottom: '2px solid black' }}>
                            <TableCell sx={{ width: '22%', textAlign: 'center' }}>유통채널</TableCell>
                            <TableCell sx={{ width: '13%' }} align="right">
                                면세점
                            </TableCell>
                            <TableCell sx={{ width: '13%' }} align="right">
                                백화점
                            </TableCell>
                            <TableCell sx={{ width: '13%' }} align="right">
                                직영점
                            </TableCell>
                            <TableCell sx={{ width: '13%' }} align="right">
                                온라인
                            </TableCell>
                            <TableCell sx={{ width: '13%' }} align="right">
                                올리브영
                            </TableCell>
                            <TableCell sx={{ width: '13%' }} align="right">
                                기타
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {chartData.map((row, idx) => {
                            if (idx === 6) {
                                return (
                                    <TableRow
                                        key={row.channelName}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            backgroundColor: 'rgba(0, 0, 0, 0.05)'
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Grid container sx={{ width: '100%' }} justifyContent="space-between">
                                                <Grid item xs={6}>
                                                    {row.channelName}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {row.channelValue}
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box>{row.value1}</Box>
                                            <Box>{getRatio(row.value1, row.channelValue)}</Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box>{row.value2}</Box>
                                            <Box>{getRatio(row.value2, row.channelValue)}</Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box>{row.value3}</Box>
                                            <Box>{getRatio(row.value3, row.channelValue)}</Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box>{row.value4}</Box>
                                            <Box>{getRatio(row.value4, row.channelValue)}</Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box>{row.value5}</Box>
                                            <Box>{getRatio(row.value5, row.channelValue)}</Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box>{row.value6}</Box>
                                            <Box>{getRatio(row.value6, row.channelValue)}</Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                            return (
                                <TableRow
                                    key={row.channelName}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Grid container sx={{ width: '100%' }} justifyContent="space-between">
                                            <Grid item xs={6}>
                                                {row.channelName}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {row.channelValue}
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box>{row.value1}</Box>
                                        <Box sx={{ color: 'red' }}>{getRatio(row.value1, chartData[6].value1)}</Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box>{row.value2}</Box>
                                        <Box sx={{ color: 'red' }}>{getRatio(row.value2, chartData[6].value2)}</Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box>{row.value3}</Box>
                                        <Box sx={{ color: 'red' }}>{getRatio(row.value3, chartData[6].value3)}</Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box>{row.value4}</Box>
                                        <Box sx={{ color: 'red' }}>{getRatio(row.value4, chartData[6].value4)}</Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box>{row.value5}</Box>
                                        <Box sx={{ color: 'red' }}>{getRatio(row.value5, chartData[6].value5)}</Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box>{row.value6}</Box>
                                        <Box sx={{ color: 'red' }}>{getRatio(row.value6, chartData[6].value6)}</Box>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

BarChart.propTypes = {
    barLoading: PropTypes.bool,
    barChartData: PropTypes.arrayOf(PropTypes.object)
};

BarChart.defaultProps = {
    barLoading: false,
    barChartData: []
};

export default BarChart;
