import React from 'react';

import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { TableCell, Typography } from '@mui/material';

import { setNotice } from 'slices/modalSlice';

import { patchData } from 'api';
import TableSelect from 'components/Table/Select';

const tableSelectOptions = {
    viewYn: [
        { label: '노출', value: 'Y' },
        { label: '미노출', value: 'N' }
    ],
    useYn: [
        { label: '사용', value: 'Y' },
        { label: '미사용', value: 'N' }
    ]
};

function ListTable({ row }) {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { menu } = useSelector((state) => state.menu);

    // 사용 유무 api
    const { mutate } = useMutation(
        'patchUseNotice',
        // eslint-disable-next-line camelcase
        (use_yn) => patchData(`web/notice/use/${row.bd_idx}`, { use_yn }),
        {
            onSuccess: async () => {
                await queryClient.refetchQueries('notice');
            }
        }
    );
    const handleSelect = (name, value) => {
        mutate(value);
    };

    const onDetailHandler = () => {
        dispatch(setNotice({ open: true, data: row, type: 'detail' }));
    };

    return (
        <>
            {menu === 'notice' && (
                <>
                    <TableCell
                        align="center"
                        sx={{
                            ' & : hover': {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={onDetailHandler}
                    >
                        <Typography>{row.subject || '-'}</Typography>
                    </TableCell>
                    <TableCell align="center">{row?.mn_nm || '-'}</TableCell>
                    <TableCell align="center">{row?.reg_dt || '-'}</TableCell>
                    <TableCell align="center" sx={{ width: '150px' }}>
                        <TableSelect
                            name="useYn"
                            value={row.use_yn}
                            label={row.use_yn === 'Y' ? '사용' : '미사용'}
                            handleSelect={handleSelect}
                            options={tableSelectOptions.useYn}
                            rowIndex={row.bd_idx}
                        />
                    </TableCell>
                </>
            )}
        </>
    );
}

ListTable.propTypes = {
    row: PropTypes.object.isRequired
};

export default ListTable;
