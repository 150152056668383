import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { TextField } from '@mui/material';

function Input({ placeholder, fullWidth, multiline, rows, inputType, name, control, inputProps }) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <TextField
                    id={`outlined-${name}`}
                    label=""
                    variant="outlined"
                    multiline={multiline}
                    rows={rows}
                    placeholder={placeholder}
                    fullWidth={fullWidth}
                    InputLabelProps={{ shrink: false }}
                    inputProps={{ 'aria-label': name, ...inputProps }}
                    type={inputType}
                    name={name}
                    value={value}
                    onChange={onChange}
                />
            )}
        />
    );
}

Input.propTypes = {
    fullWidth: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    inputType: PropTypes.string,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    inputProps: PropTypes.object
};

Input.defaultProps = {
    fullWidth: true,
    multiline: false,
    rows: 0,
    inputType: 'text',
    placeholder: '',
    inputProps: {}
};

export default Input;
