import React from 'react';

import { Grid, Paper } from '@mui/material';

import { BarChart, BubbleChart, LineChart } from 'components';

export default function Charts(props) {
    return (
        <Grid container justifyContent="flex-start" alignItems="center" direction="row" spacing="20px">
            <Grid item xs={12}>
                <Paper sx={{ padding: '20px', borderRadius: '10px' }}>
                    <LineChart xs={6} {...props} />
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper
                    sx={{
                        padding: '20px',
                        borderRadius: '10px'
                    }}
                    elevation={1}
                >
                    <BarChart {...props} />
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper sx={{ padding: '20px', borderRadius: '10px' }} elevation={1}>
                    <BubbleChart {...props} />
                </Paper>
            </Grid>
            {/* <Grid item xs={6}>
                <Paper sx={{ padding: '20px', borderRadius: '10px' }} elevation={1}>
                    <MapChart {...props} />
                </Paper>
            </Grid> */}
        </Grid>
    );
}
