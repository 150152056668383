import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { Grid, MenuItem } from '@mui/material';

import { SEARCH_OPTION as option } from 'configs/search';

import { StyledSelect } from 'styles/customize/search';

function SearchSelect({ name, value, dataList, onChange }) {
    const { page: menu } = useSelector((state) => state.menu);
    const searchState = useSelector((state) => state.search);

    const options = name === 'searchCondition' || name === 'sort' ? option[name][menu] : option[name];

    const selectOptions = dataList?.length > 0 ? [...options, ...dataList] : options;

    return (
        <Grid item xs="auto">
            <StyledSelect
                IconComponent={KeyboardArrowDownIcon}
                displayEmpty
                size="small"
                name={name}
                value={
                    name === 'searchCondition' ||
                    name === 'userDateType' ||
                    name === 'inquiryDateType' ||
                    name === 'eventDateType'
                        ? value
                        : searchState[name]
                }
                onChange={onChange}
            >
                {selectOptions?.map(({ label, value }) => (
                    <MenuItem key={`menu-item-${label}`} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </StyledSelect>
        </Grid>
    );
}

SearchSelect.propTypes = {
    dataList: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    ),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

SearchSelect.defaultProps = {
    dataList: [],
    value: ''
};

export default SearchSelect;
