import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)(({ theme: { palette } }) => ({
    borderRadius: 4,
    border: `1px solid ${palette.border.opacity01}`
}));

export const StyledLabel = styled(Box)(({ theme: { palette } }) => ({
    width: '100%',
    height: 48,
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRight: `1px solid ${palette.border.opacity01}`,
    borderBottom: `1px solid ${palette.border.opacity01}`,
    '&:nth-last-of-type(2)': {
        height: '100%',
        borderBottom: 0
    }
}));

export const StyledLabelForMultiline = styled(Box)(({ theme: { palette } }) => ({
    width: '100%',
    height: 144,
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRight: `1px solid ${palette.border.opacity01}`,
    borderBottom: `1px solid ${palette.border.opacity01}`,
    '&:nth-last-of-type(2)': {
        height: '100%',
        borderBottom: 0
    }
}));

export const StyledLabelForRetailers = styled(Box)(({ theme: { palette } }) => ({
    width: '100%',
    height: 98,
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRight: `1px solid ${palette.border.opacity01}`,
    borderBottom: `1px solid ${palette.border.opacity01}`,
    '&:nth-last-of-type(2)': {
        height: '100%',
        borderBottom: 0
    }
}));

export const StyledInputContainer = styled(Box)(({ theme: { palette } }) => ({
    width: '100%',
    height: 48,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 20,
    padding: '0 20px',
    borderLeft: 0,
    borderBottom: `1px solid ${palette.border.opacity01}`,
    '&:last-of-type': {
        borderBottom: 0
    }
}));

export const StyledInputContainerForMultiline = styled(Box)(({ theme: { palette } }) => ({
    width: '100%',
    height: 144,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 20,
    padding: '0 20px',
    borderLeft: 0,
    borderBottom: `1px solid ${palette.border.opacity01}`,
    '&:last-of-type': {
        borderBottom: 0
    }
}));

export const StyledInputContainerForRetailers = styled(Box)(({ theme: { palette } }) => ({
    width: '100%',
    height: 98,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 20,
    padding: '0 20px',
    borderLeft: 0,
    borderBottom: `1px solid ${palette.border.opacity01}`,
    '&:last-of-type': {
        borderBottom: 0
    }
}));

export const StyledImageContainer = styled(Box)(({ theme: { palette } }) => ({
    width: '100%',
    height: 196,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 20,
    padding: '0 20px',
    borderLeft: 0,
    borderBottom: `1px solid ${palette.border.opacity01}`,
    '&:last-of-type': {
        borderBottom: 0
    }
}));
