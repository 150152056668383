import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { modalSelector, setClose } from 'slices/modalSlice';

import UseFormError from 'hooks/useFormError';

import { getData, postData } from 'api';
import { DefaultButton, SubmitButton } from 'components/Buttons';
import { Input } from 'components/FormInputs';

import { SCHEMA } from 'configs/form';

import { StyledBox, StyledInputContainer, StyledLabel } from 'styles/customize/form';
import theme from 'styles/theme/form';

// 문의하기 답변 모달
function InquiryModal() {
    const { inquiryOpen, inquiryData, inquiryIdx } = useSelector(modalSelector);

    const queryClient = useQueryClient();

    const defaultValues = {
        contents: ''
    };

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(SCHEMA.Inquiry)
    });

    const dispatch = useDispatch();

    const { mutate: editInquiry } = useMutation('editNotice', ({ url, data }) => postData(url, data), {
        onSuccess: async () => {
            await queryClient.refetchQueries('inquiry');
            reset(defaultValues);
            dispatch(setClose());
        }
    });

    const { data: inquiryDetailData } = useQuery('getInquiryDetailData', () => getData(`/web/qna/${inquiryIdx}`), {
        enabled: !!inquiryIdx,

        onError: (error) => {
            console.log('error:', error);
        }
    });

    const onClose = () => {
        reset(defaultValues);
        dispatch(setClose());
    };

    UseFormError(errors);
    const onSubmit = (data) => {
        const index = inquiryData.bd_idx;
        if (data?.contents) {
            editInquiry({
                url: `/web/qna/answer/${index}`,
                data: {
                    answer: data?.contents
                }
            });
        }
    };

    return (
        <Dialog
            open={inquiryOpen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose(event, reason);
                }
            }}
            sx={{
                '& .MuiPaper-root': {
                    minWidth: 1145,
                    minHeight: 462,
                    maxWidth: '100%'
                }
            }}
        >
            <ThemeProvider theme={theme}>
                <DialogTitle>문의하기 답변등록</DialogTitle>
                <DialogContent sx={{ height: '100%', paddingBottom: 0 }}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <StyledBox display="grid" gridTemplateColumns="200px 1fr">
                            <StyledLabel>
                                <Typography variant="inputLabel">문의 제목</Typography>
                            </StyledLabel>
                            <StyledInputContainer>{inquiryData?.subject}</StyledInputContainer>
                            <StyledLabel>
                                <Typography variant="inputLabel">회원 아이디</Typography>
                            </StyledLabel>
                            <StyledInputContainer>{inquiryData?.user_id || '-'}</StyledInputContainer>

                            <StyledLabel sx={{ height: '100%', minHeight: '200px' }}>
                                <Typography variant="inputLabel">문의 내용</Typography>
                            </StyledLabel>
                            <StyledInputContainer sx={{ height: '100%', display: 'block' }}>
                                <StyledInputContainer sx={{ paddingLeft: 0 }}>
                                    {inquiryDetailData?.contents}
                                </StyledInputContainer>
                            </StyledInputContainer>

                            <StyledLabel sx={{ height: '100%', minHeight: '200px' }}>
                                <Typography variant="inputLabel">답변 내용</Typography>
                            </StyledLabel>
                            <StyledInputContainer sx={{ height: '100%', display: 'block' }}>
                                {inquiryData?.answer_yn === 'N' ? (
                                    <Input
                                        placeholder="내용을 입력해주세요"
                                        name="contents"
                                        multiline
                                        rows={10}
                                        control={control}
                                    />
                                ) : (
                                    <StyledInputContainer sx={{ paddingLeft: 0 }}>
                                        {inquiryData?.answer}
                                    </StyledInputContainer>
                                )}
                            </StyledInputContainer>
                        </StyledBox>
                        <Grid sx={{ py: 2 }} container justifyContent="flex-end" alignItems="center" gap="10px">
                            <Grid item xs="auto">
                                <DefaultButton icon="cancel" onClick={onClose} text="취소" />
                            </Grid>
                            <Grid item xs="auto">
                                <SubmitButton type="submit" text="등록" onClick={onSubmit} />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </ThemeProvider>
        </Dialog>
    );
}
export default InquiryModal;
