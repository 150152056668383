import React from 'react';

import useGetList from 'hooks/useGetList';
import useMenu from 'hooks/useMenu';

import { Search } from 'components';

import InquiryTable from './components/InquiryTable';

export default function Inquiry() {
    const menu = useMenu({ page: 'Inquiry', menu: 'inquiry', menuTitle: '1:1 문의하기', menuNum: 7 }); // 페이지/메뉴 설정하기
    const { isLoading, data: dataList } = useGetList({ menu, url: '/web/qna/lists' }); // 리스트 데이터 가져오기

    return (
        <>
            <Search path="/inquiry" heading="" total={dataList?.totalCount} />
            <InquiryTable isLoading={isLoading} data={dataList?.QuestionLists} total={dataList?.totalCount} />
        </>
    );
}
