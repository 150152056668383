import React from 'react';

import useGetList from 'hooks/useGetList';
import useMenu from 'hooks/useMenu';

import { Search } from 'components';

import RegistTable from './components/RegistTable';

export default function Regist() {
    const menu = useMenu({ page: 'Regist', menu: 'regist', menuTitle: '구매등록', menuNum: 3 }); // 페이지/메뉴 설정하기
    const { isLoading, data: dataList } = useGetList({ menu, url: '/web/buy/lists' }); // 리스트 데이터 가져오기

    return (
        <>
            <Search path="/regist" heading="" total={dataList?.totalCount} />
            <RegistTable isLoading={isLoading} data={dataList?.buyLists} total={dataList?.totalCount} />
        </>
    );
}
