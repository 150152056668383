import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';

import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';
import { Container, Fab, Grid, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { loginSelector } from 'slices/loginSlice';

import {
    ImageModal,
    ProgressModal,
    ConfirmModal,
    DetailModal,
    NoticeModal,
    MessageModal,
    BrandModal,
    ProductModal,
    EventModal,
    UserModal,
    InquiryModal

    // RetailModal
} from 'components/Modals';
import RetailModal from 'components/Modals/RetailModal';
import Header from 'layouts/Header';
import ScrollTop from 'layouts/ScrollTop';
import SideBar from 'layouts/SideBar';

const StyledContainer = styled(Container, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme: { breakpoints }, open }) => ({
    flex: 1,
    [breakpoints.up('lg')]: {
        width: 'calc(100% - 200px)',
        marginLeft: 200
    },
    [breakpoints.up('xl')]: {
        marginLeft: 0
    },
    minWidth: 1145,
    width: '100%',
    marginTop: 80,
    borderRadius: 4,
    [breakpoints.up('xs')]: {
        maxWidth: '100%',
        padding: 20,
        ...(open && {
            marginLeft: 240
        })
    }
}));

function Layout({ children }) {
    const { isExit, showSidebar } = useSelector((state) => state.menu);
    const { isLogin } = useSelector(loginSelector);

    const handleExit = (event) => {
        const e = event || window.event;
        e.preventDefault();
        e.returnValue = '';
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isExit) {
            window.addEventListener('beforeunload', handleExit);
            return () => window.removeEventListener('beforeunload', handleExit);
        }
    }, [isExit]);

    return (
        <Grid position="relative" container>
            <Toolbar
                sx={{ flex: 0, width: '100%', minHeight: { xs: '1px' }, height: '1px', padding: { xs: '0' } }}
                id="back-to-top-anchor"
            />
            <Header />
            <SideBar adminType="private" isLogin={isLogin} />
            <StyledContainer open={showSidebar} component="main">
                {children}
            </StyledContainer>
            <ProgressModal />
            <ImageModal />
            <DetailModal />
            <ConfirmModal />
            <EventModal />
            <NoticeModal />
            <UserModal />
            <BrandModal />
            <ProductModal />
            <RetailModal />
            <InquiryModal />
            <MessageModal />
            <Prompt when={isExit} message="저장되지 않을 수 있습니다. 페이지를 이동하시겠습니까?" />
            <ScrollTop>
                <Fab color="primary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </Grid>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
