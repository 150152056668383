import { createTheme } from '@mui/material/styles';

import palette from 'styles/theme/palette';
import typography from 'styles/theme/typography';

const theme = createTheme({
    palette,
    typography,
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    minWidth: 320,
                    // maxWidth: 500,
                    minHeight: 36,
                    margin: '5px 0'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    height: '100%',
                    '&&.Mui-focused fieldset': {
                        borderColor: palette.border.main,
                        borderWidth: 1
                    },
                    '&&:hover fieldset': {
                        borderColor: palette.border.main,
                        borderWidth: 1
                    },
                    padding: 0
                },
                input: {
                    boxSizing: 'border-box',
                    height: 36,
                    padding: '9px 10px'
                },
                notchedOutline: {
                    borderColor: palette.border.main
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: palette.text.primary
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500
                },
                sizeSmall: {
                    minHeight: 32,
                    padding: 5
                },
                sizeMedium: {
                    height: 30,
                    padding: 10,
                    fontSize: 12
                },
                sizeLarge: {
                    width: 'auto',
                    padding: 10
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    marginRight: 0
                }
            }
        }
    }
});

export default theme;
