const palette = {
    primary: {
        light: '#3D393508',
        opacity02: '#3D393533',
        main: '#3D3935',
        red: '#DE5D5D'
    },
    secondary: {
        main: '#3D3935'
    },
    background: {
        light: '#fbfbfb',
        lightOpacity07: '#f3f3f3b3',
        main: '#f3f3f3',
        active: '#e7e7e7'
    },
    neutral: {
        white: '#ffffff',
        black: '#3D3935',
        main: '#222222'
    },
    border: {
        light: '#00000033',
        opacity01: '#3d39351a',
        opacity02: '#3d393533',
        main: '#3D393534',
        dark: '#3D3935'
    },
    text: {
        primary: '#333333',
        secondary: '#333333',
        label: '#333333b3',
        disabled_btn: 'rgba(61, 57, 53, 0.12)'
    },
    shadow: {
        primary: '#E8E8E888'
    },
    disabled: {
        primary: '#0000001f'
    }
};

export default palette;
