import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { TableCell, Typography } from '@mui/material';

function ListTable({ row }) {
    const { menu } = useSelector((state) => state.menu);
    return (
        <>
            {menu === 'report' && (
                <>
                    <TableCell align="center">
                        <Typography>{row.com_nm}</Typography>
                    </TableCell>
                    <TableCell align="center">{row.br_nm || '-'}</TableCell>
                    <TableCell align="center">{row.pro_nm || '-'}</TableCell>
                    <TableCell align="center">{row.reg_dt || '-'}</TableCell>
                    <TableCell align="center">{row.source || '-'}</TableCell>
                    <TableCell align="center">{row.scan_result || '-'}</TableCell>
                    <TableCell align="center">{row.user_id || '-'}</TableCell>
                </>
            )}
        </>
    );
}

ListTable.propTypes = {
    row: PropTypes.object.isRequired
};

export default ListTable;
