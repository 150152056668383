import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLogin: !!localStorage.getItem('accessToken')
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setIsLogin: (state, { payload }) => {
            state.isLogin = true;
            localStorage.setItem('accessToken', payload.accessToken);
            localStorage.setItem('adminType', payload.admin_type);
        },
        setLogOut: (state) => {
            state.isLogin = false;
            localStorage.clear();
        }
    },
    extraReducers: {}
});

export const { setIsLogin, setLogOut } = loginSlice.actions;

export const loginSelector = (state) => state.login;

export default loginSlice.reducer;
