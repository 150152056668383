import React from 'react';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { TableCell } from '@mui/material';

import { setUser } from 'slices/modalSlice';

import useMessage from 'hooks/useMessage';

import TableButton from 'components/Table/Button';

function ListTable({ row }) {
    const { menu } = useSelector((state) => state.menu);
    const dispatch = useDispatch();

    const handleMessage = useMessage();

    const onViewInfo = (index, state) => {
        if (index) {
            handleMessage({
                type: 'confirm',
                confirmType: 'viewType',
                message: state === "Y" ? '선택한 계정의 개인정보열람 권한을 삭제하시겠습니까?' : '선택한 계정에 개인정보열람 권한을 부여하시겠습니까?',
                data: index,
                state
            });
        } else handleMessage({ message: '잘못된 접근입니다.' });
    };

    const onState = (index, state) => {
        if (index) {
            handleMessage({
                type: 'confirm',
                confirmType: 'userStatusChange',
                message:
                    state === '4'
                        ? '선택한 계정에 하위관리자 권한을 부여하시겠습니까?'
                        : '선택한 계정의 관리자 권한을 삭제하시겠습니까?',
                data: index,
                state
            });
        } else handleMessage({ message: '잘못된 접근입니다.' });
    };

    const onEdit = () => {
        dispatch(setUser({ open: true, data: row, type: 'modify' }));
    };

    const onDelete = (index) => {
        if (index) {
            handleMessage({
                type: 'confirm',
                confirmType: 'userDelete',
                message: '선택한 항목을 삭제합니다.',
                data: index
            });
        } else handleMessage({ message: '잘못된 접근입니다.' });
    };

    const onMasterType = (index) => {
        if (index) {
            handleMessage({
                type: 'confirm',
                confirmType: 'masterType',
                message: '상위관리자를 변경하시겠습니까? 변경 시 현재 계정의 권한은 하위관리자로 변경됩니다.',
                data: index
            });
        } else handleMessage({ message: '잘못된 접근입니다.' });
    };
    return (
        <>
            {menu === 'user' && (
                <>
                    <TableCell align="center">{row.mn_nm}</TableCell>
                    <TableCell align="center">{row.admin_id}</TableCell>
                    <TableCell align="center">{row.mn_tel}</TableCell>
                    <TableCell align="center">{row.mn_email}</TableCell>
                    <TableCell align="center">
                        <TableButton
                            size="medium"
                            variant="contained"
                            color={row.masking_flag !== 'Y' ? 'text.disabled_btn' : 'text.primary'}
                            text={row.masking_flag === 'Y' ? '가능' : '불가'}
                            onClick={() => onViewInfo(row.admin_idx, row.masking_flag)}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <TableButton
                            size="medium"
                            variant="contained"
                            color={row.con_state !== '2' ? 'text.disabled_btn' : 'text.primary'}
                            text={row.con_state === '2' ? '등록' : '미등록'}
                            onClick={() => onState(row.admin_idx, row.con_state)}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <TableButton
                            size="medium"
                            variant="contained"
                            color={row.admin_type !== 'M' ? 'text.disabled_btn' : 'text.disabled_btn'}
                            text="상위관리자"
                            onClick={() => onMasterType(row.admin_idx)}
                        />
                    </TableCell>
                    <TableCell align="center">{row.mod_dt === null ? '-' : row.mod_dt}</TableCell>

                    <TableCell align="center">
                        <TableButton pageType="edit" text="수정" rowIndex={row.admin_idx} onClick={onEdit} />
                        <TableButton pageType="edit" text="삭제" rowIndex={row.admin_idx} onClick={onDelete} />
                    </TableCell>
                </>
            )}
        </>
    );
}

ListTable.propTypes = {
    row: PropTypes.object.isRequired
};

export default ListTable;
