import React, { useCallback, useEffect, useState } from 'react';

import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { setConfirmed, setConfirmReset } from 'slices/modalSlice';

import useGetList from 'hooks/useGetList';
import useMenu from 'hooks/useMenu';
import useMessage from 'hooks/useMessage';

import { deleteData } from 'api';
import { Search } from 'components';

import ProductTable from './components/ProductTable';

export default function Brand() {
    const menu = useMenu({ page: 'Product', menu: 'product', menuTitle: '제품 관리', menuNum: 11 });
    const { isLoading, data: dataList, refetch: refetchList } = useGetList({ menu, url: '/web/product/lists' }); // 리스트 데이터 가져오기
    const { data, isConfirmed, confirmType } = useSelector((state) => state.modal);
    const dispatch = useDispatch();
    const handleMessage = useMessage();

    const [selected, setSelected] = useState([]);
    const { mutate: deleteProduct } = useMutation(({ url, data }) => deleteData(url, data), {
        onSuccess: () => {
            dispatch(setConfirmReset());
            refetchList();
            handleMessage({ message: '삭제되었습니다.' });
            setSelected([]);
        },
        onError: (error) => handleMessage({ type: 'message', ...error })
    });

    const handleConfirm = useCallback(() => {
        dispatch(setConfirmed());
        if (confirmType === 'productDelete') {
            deleteProduct({ url: `web/product/${data}` });
        }
    }, [dispatch, confirmType, deleteProduct]);

    useEffect(() => {
        if (isConfirmed) {
            handleConfirm();
        }
    }, [isConfirmed, handleConfirm]);

    return (
        <>
            <Search path="/product-list" total={dataList?.totalCount} />
            <ProductTable
                refetch={refetchList}
                isLoading={isLoading}
                data={dataList?.productLists}
                total={dataList?.totalCount}
                selected={selected}
                setSelected={setSelected}
            />
        </>
    );
}
