import React, { useRef, useState, useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box } from '@mui/material';

import { setIsLogin } from 'slices/loginSlice';

import useMessage from 'hooks/useMessage';

import { postData } from 'api';
import { MessageModal } from 'components/Modals';
import LoginForm from 'pages/Login/components/LoginForm';

import LoginImg from 'assets/img/login_banner.png';

import { isEmpty } from 'utils';

export default function Login() {
    const history = useHistory();
    const dispatch = useDispatch();
    const idRef = useRef(null);
    const pwRef = useRef(null);

    const [cookies, setCookie, removeCookie] = useCookies(['cookieID']);

    const [values, setValues] = useState({ checked: true });
    const [isFocused, setFocused] = useState({ id: false, password: false });
    const [isError, setIsError] = useState({ id: false, password: false });

    const handleMessage = useMessage();
    useEffect(() => {
        if (cookies.cookieID) {
            setValues((prev) => ({ ...prev, id: cookies.cookieID }));
        }
    }, [cookies.cookieID]);

    // 로그인 저장 체크박스
    const { mutate: handleLogin, isLoading } = useMutation(({ url, data }) => postData(url, data), {
        onSuccess: (data) => {
            if (values.checked) {
                setCookie('cookieID', values.id, { maxAge: 60 * 60 * 24 * 14 });
            } else {
                removeCookie('cookieID');
            }
            dispatch(setIsLogin(data));

            if (data.reset_pw === 'N') {
                handleMessage({
                    message:
                        '임시 발급된 비밀번호로 로그인 하셨습니다. 안전한 계정 관리를 위해 비밀번호 변경을 부탁드립니다.'
                });
                history.replace('admin/password');
            } else history.replace('/');
        },
        onError: () => {
            handleMessage({
                message: '로그인 정보가 일치하지 않습니다.'
            });
        }
    });

    // 로그인
    const handleSubmit = async () => {
        const formData = { admin_id: values.id, admin_pw: values.password };
        Object.keys(formData).forEach((key) => {
            setFocused((prev) => ({ ...prev, [key]: false }));
            setIsError((prev) => ({ ...prev, [key]: isEmpty(values[key]) }));
        });

        if ((isEmpty(formData.admin_id) && isEmpty(formData.admin_pw)) || isEmpty(formData.admin_id)) {
            idRef.current.focus();
        } else if (isEmpty(formData.admin_pw)) {
            pwRef.current.focus();
        } else {
            handleLogin({ url: '/web/admin/signin', data: formData });
        }
    };

    const onChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === 'checked') {
            setValues((prev) => ({ ...prev, [name]: checked }));
        } else {
            setValues((prev) => ({ ...prev, [name]: value }));
            setFocused((prev) => ({ ...prev, [name]: !!value }));
            setIsError((prev) => ({ ...prev, [name]: !value }));
        }
    };

    // Enter로 로그인
    const onEnter = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row'
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    bgcolor: 'primary.main',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <img src={LoginImg} alt="login_img" style={{ width: 480, height: 560 }} />
            </Box>
            <Box
                sx={{
                    width: 480,
                    height: 560,
                    bgcolor: 'neutral.white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <LoginForm
                    idRef={idRef}
                    pwRef={pwRef}
                    status={{ isLoading, isError, isFocused }}
                    data={values}
                    onEnter={onEnter}
                    onChange={onChange}
                    setFocused={setFocused}
                    handleSubmit={handleSubmit}
                />
            </Box>
            <MessageModal />
        </Box>
    );
}
