import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { TableCell, Typography } from '@mui/material';

import { LinkButton } from 'components/Buttons';

function ListTable({ row }) {
    // TODO: 추후 데이터 연결 필요
    const { page: menu } = useSelector((state) => state.menu);

    return (
        <>
            {menu === 'Dashboard' ? (
                <>
                    <TableCell align="center">
                        <Typography variant="underlined">{row.name}</Typography>
                    </TableCell>
                    <TableCell align="center">{row.calories || '-'}</TableCell>
                    <TableCell align="center">{row.fat || '-'}</TableCell>
                    <TableCell align="center">{row.carbs || '-'}</TableCell>
                    <TableCell align="center">{row.protein || '-'}</TableCell>
                    <TableCell align="center">
                        <LinkButton variant="contained" size="small" text="수정" path={`/edit/${row.idx}`} />
                    </TableCell>
                </>
            ) : (
                ''
            )}
        </>
    );
}

ListTable.propTypes = {
    row: PropTypes.object.isRequired
};

export default ListTable;
