import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Page404 from 'pages/404';
import ChangeInfo from 'pages/Admin/ChangeInfo';
import ChangePassword from 'pages/Admin/ChangePassword';
import Brand from 'pages/Brand';
import Chart from 'pages/Chart';
// import CSV from 'pages/CSV';
import Event from 'pages/Event';
import Inquiry from 'pages/Inquiry';
import Notice from 'pages/Notice';
import Policy from 'pages/Policy';
import AddPolicy from 'pages/Policy/AddPolicy';
import ModifyPolicy from 'pages/Policy/ModifyPolicy';
import ViewPolicy from 'pages/Policy/ViewPolicy';
import Product from 'pages/product';
import Regist from 'pages/Regist';
import Report from 'pages/Report';
import Retail from 'pages/retail';
import Scan from 'pages/Scan';
import SignUp from 'pages/Signup';
import User from 'pages/User';
import RouteWrapper from 'routes/RouteWrapper';

export default function Router() {
    return (
        <Switch>
            <RouteWrapper path="/" exact component={Chart} />
            <RouteWrapper path="/sign-up" component={SignUp} />

            <RouteWrapper path="/admin/info" exact component={ChangeInfo} />
            <RouteWrapper path="/admin/password" exact component={ChangePassword} />

            {/* <RouteWrapper path="/csv" exact component={CSV} />
            <RouteWrapper path="/chart" exact component={Chart} /> */}

            <RouteWrapper path="/scan" exact component={Scan} />
            <RouteWrapper path="/user-list" exact component={User} />
            <RouteWrapper path="/brand-list" exact component={Brand} />

            <RouteWrapper path="/regist" exact component={Regist} />
            <RouteWrapper path="/report-list" exact component={Report} />

            <RouteWrapper path="/inquiry" exact component={Inquiry} />
            <RouteWrapper path="/notice" exact component={Notice} />
            <RouteWrapper path="/event" exact component={Event} />

            <RouteWrapper path="/policy" exact component={Policy} />
            <RouteWrapper path="/addPolicy" exact component={AddPolicy} />
            <RouteWrapper path="/modifyPolicy/:idx" exact component={ModifyPolicy} />
            <RouteWrapper path="/viewPolicy/:idx" exact component={ViewPolicy} />

            <RouteWrapper path="/product" exact component={Product} />
            <RouteWrapper path="/retail" exact component={Retail} />

            <Route path="/login" exact>
                <Redirect to="/" />
            </Route>
            <Route path="/register" exact>
                <Redirect to="/" />
            </Route>
            <Route path="*" component={Page404} />
        </Switch>
    );
}
