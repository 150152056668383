import React from 'react';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { TableCell } from '@mui/material';
import Popover from '@mui/material/Popover';

import { menuSelector } from 'slices/menuSlice';
import { setBrand } from 'slices/modalSlice';

import useMessage from 'hooks/useMessage';

import TableButton from 'components/Table/Button';
import TableSelect from 'components/Table/Select';

const tableSelectOptions = {
    viewYn: [
        { label: '노출', value: 'Y' },
        { label: '미노출', value: 'N' }
    ],
    useYn: [
        { label: '사용', value: 'Y' },
        { label: '미사용', value: 'N' }
    ]
};

const brandCategoryList = (category) => {
    let result = '';
    switch (category) {
        case '1':
            result = '뷰티';
            break;
        case '2':
            result = '전자';
            break;
        case '3':
            result = '식음료';
            break;
        case '4':
            result = '패션';
            break;
        case '5':
            result = '유아동';
            break;
        case '6':
            result = '의약품';
            break;
        case '7':
            result = '기타';
            break;
        default:
            break;
    }
    return result;
};

function ListTable({ row, handleSelect, companyList }) {
    const { menu } = useSelector(menuSelector);
    const dispatch = useDispatch();

    const handleMessage = useMessage();

    const [anchorEl, setAnchorEl] = React.useState(null); // 브랜드 popup관련 변수
    const open = Boolean(anchorEl); // 브랜드 popup관련 변수

    // 브랜드 로고이미지 팝업 open
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // 브랜드 로고이미지 팝업 close
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const onDelete = (index) => {
        if (index) {
            handleMessage({
                type: 'confirm',
                confirmType: 'brandDelete',
                message: '선택한 항목을 삭제합니다.',
                data: index
            });
        } else handleMessage({ message: '잘못된 접근입니다.' });
    };

    const onEdit = () => {
        dispatch(setBrand({ open: true, data: row, company: companyList, type: 'modify' }));
    };

    return (
        <>
            {menu === 'brand' && (
                <>
                    <TableCell align="center">{row.com_nm || '-'}</TableCell>
                    <TableCell align="center">{row.br_nm || '-'}</TableCell>
                    <TableCell align="center">
                        <input
                            type="image"
                            src={row.br_img}
                            alt="brand_img"
                            style={{ width: 36, height: 36 }}
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        />
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: 'none'
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <input type="image" src={row.br_img} alt="brand_img" style={{ width: 128, height: 128 }} />
                        </Popover>
                    </TableCell>
                    <TableCell align="center">{brandCategoryList(row.br_cate)}</TableCell>
                    <TableCell align="center">
                        <TableSelect
                            rowIndex={row.br_idx}
                            name="br_main"
                            options={tableSelectOptions.viewYn}
                            value={row.br_main}
                            label={row.br_main === 'Y' ? '노출' : '미노출'}
                            handleSelect={handleSelect}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <TableSelect
                            rowIndex={row.br_idx}
                            name="use_yn"
                            value={row.use_yn}
                            label={row.use_yn === 'Y' ? '사용' : '미사용'}
                            options={tableSelectOptions.useYn}
                            handleSelect={handleSelect}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <TableButton pageType="edit" text="수정" rowIndex={row.br_idx} onClick={onEdit} />
                        <TableButton pageType="delete" text="삭제" rowIndex={row.br_idx} onClick={onDelete} />
                    </TableCell>
                </>
            )}
        </>
    );
}

ListTable.propTypes = {
    row: PropTypes.object.isRequired,
    handleSelect: PropTypes.func.isRequired,
    companyList: PropTypes.array
};

ListTable.defaultProps = {
    companyList: []
};

export default ListTable;
