import React, { useMemo, useRef } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import ReactQuillEditor from 'react-quill';

import { Box } from '@mui/material';

import 'react-quill/dist/quill.snow.css';

function ReactQuill({ contents, setContents }) {
    const quillRef = useRef(null);

    const onChange = (value) => setContents(value);

    const imageCallBack = () => {
        // 이미지 업로드 참고: https://github.com/quilljs/quill/issues/1400

        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async function handleImage() {
            const file = input.files ? input.files[0] : null;

            if (file) {
                // 서버로 이미지를 업로드 Example
                const formData = new FormData();
                formData.append('files', file);

                const headers = {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                };

                // 서버로 이미지를 upload한 다음
                // 이미지의 URL을 에디터에 삽입
                await axios
                    .post(`${process.env.REACT_APP_BASE_URL}/upload`, formData, { headers })
                    .then((res) => {
                        const quill = quillRef.current.getEditor();
                        const range = quill.getSelection(true);
                        const path = res.data.result;
                        const imageSrc = `http://localhost:3000/${path}`; // 이미지의 URL
                        quill.insertEmbed(range.index, 'image', imageSrc, 'user');
                    })
                    .catch(() => {
                        alert('에러가 발생했습니다.');
                    });
            } else {
                alert('이미지를 선택해주세요');
            }
        };
    };

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ size: ['small', false, 'large', 'huge'] }, { color: [] }],
                    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
                    ['link', 'image'],
                    ['clean']
                ],
                handlers: {
                    image: imageCallBack
                }
            },
            clipboard: { matchVisual: false }
        }),
        []
    );

    const formats = [
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'size',
        'color',
        'list',
        'link',
        'image',
        'align'
    ];

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                px: '20px',
                py: '4px',
                '& .ql-toolbar.ql-snow': {
                    borderTopRightRadius: '4px',
                    borderTopLeftRadius: '4px',
                    borderColor: 'border.opacity01'
                },
                '& .ql-toolbar.ql-snow + .ql-container.ql-snow': {
                    height: 400,
                    borderColor: 'border.opacity01',
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px'
                },
                '& .ql-snow .ql-tooltip': {
                    zIndex: 9999,
                    transform: 'translate(100px, 10px)'
                }
            }}
        >
            <ReactQuillEditor
                ref={quillRef}
                placeholder="내용을 입력해주세요."
                theme="snow"
                value={contents}
                onChange={onChange}
                formats={formats}
                modules={modules}
            />
        </Box>
    );
}

ReactQuill.propTypes = {
    contents: PropTypes.string.isRequired,
    setContents: PropTypes.func.isRequired
};

export default ReactQuill;
