import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';

import { setUser } from 'slices/modalSlice';
import { setSearchFilters } from 'slices/searchSlice';

import useSearch from 'hooks/useSearch';

import { TableLoader, Pagination } from 'components';

import { HEAD_CELL } from 'configs/table';

import { StyledTablePaper } from 'styles/customize/table';

import UserData from './ListTable';

function UserTable({ isLoading, data, total }) {
    const dispatch = useDispatch();
    const { page, pageSize } = useSelector((state) => state.search);
    const { page: menu } = useSelector((state) => state.menu);

    const handleSearch = useSearch({ menu });

    // Pagination
    const handlePage = (paging) => {
        dispatch(setSearchFilters(paging));
        handleSearch(paging);
    };

    const onPageChange = (newPage) => handlePage({ page: newPage, pageSize }); // 페이지 이동하기

    const onRowsPerPageChange = (event) => handlePage({ page: 1, pageSize: parseInt(event.target.value, 10) }); // rows per page(페이지 당 행) 변경하기

    const onUserClick = () => {
        dispatch(setUser({ open: true, data: null }));
    };
    return (
        <StyledTablePaper elevation={1}>
            <TableContainer>
                <Table
                    aria-labelledby={`${menu.toLowerCase()}Table`}
                    size="medium"
                    aria-label={`${menu.toLowerCase()} table`}
                >
                    <TableHead>
                        <TableRow>
                            {HEAD_CELL[menu]?.map(({ id, label }) => (
                                <TableCell key={id} align="center">
                                    {label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableLoader colSpan={20} />
                        ) : total === 0 ? (
                            <TableLoader type="empty" colSpan={20} />
                        ) : (
                            data.map((row) => {
                                return (
                                    <React.Fragment key={row.admin_idx}>
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.br_idx}>
                                            <UserData
                                                menu={menu}
                                                row={row}
                                                index={row.admin_idx}
                                                handleOpen={onUserClick}
                                            />
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!isLoading && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Pagination
                        count={total}
                        page={page > 0 ? page - 1 : 0}
                        rowsPerPage={pageSize || 10}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </Box>
            )}
        </StyledTablePaper>
    );
}
UserTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
    isLoading: PropTypes.bool.isRequired
};

UserTable.defaultProps = {
    data: [],
    total: 0
};

export default UserTable;
