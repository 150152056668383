const HEAD_CELL = {
    Dashboard: [
        { id: 'name', label: '디저트' },
        { id: 'calories', label: '칼로리' },
        { id: 'fat', label: '지방' },
        { id: 'carbs', label: '탄수화물' },
        { id: 'protein', label: '프로틴' },
        { id: 'status', label: '관리' }
    ],
    Signup: [
        { id: 'user_nm', label: '이름' },
        { id: 'user_id', label: '아이디' },
        { id: 'user_mobile', label: '휴대폰번호' },
        { id: 'reg_dt', label: '가입일시' },
        { id: 'scan_count', label: '스캔하기' },
        { id: 'register', label: '구매등록' }
    ],
    Scan: [
        { id: 'com_nm', label: '회사명' },
        { id: 'br_nm', label: '브랜드명' },
        { id: 'pro_nm', label: '제품명' },
        { id: 'reg_dt', label: '스캔일시' },
        { id: 'user_id', label: '아이디' }
    ],
    Regist: [
        { id: 'com_nm', label: '회사명' },
        { id: 'br_nm', label: '브랜드명' },
        { id: 'pro_nm', label: '제품명' },
        { id: 'reg_dt', label: '구매등록일시' },
        { id: 'purchs_place', label: '구매처' },
        { id: 'user_id', label: '아이디' }
    ],
    Report: [
        { id: 'com_nm', label: '회사명' },
        { id: 'br_nm', label: '브랜드명' },
        { id: 'pro_nm', label: '제품명' },
        { id: 'reg_dt', label: '신고일시' },
        { id: 'source', label: '구매처' },
        { id: 'scan_result', label: '유형' },
        { id: 'user_id', label: '아이디' }
    ],
    User: [
        { id: 'mn_nm', label: '이름' },
        { id: 'admin_id', label: '아이디' },
        { id: 'mn_tel', label: '휴대폰번호' },
        { id: 'mainExposure', label: '이메일' },
        { id: '개인정보열람', label: '개인정보열람' },
        { id: 'useStatus', label: '하위관리자 등록' },
        { id: 'exposureOrder', label: '상위관리자 등록' },
        { id: 'mod_dt', label: '상태변경일시' },
        { id: 'edit_delete', label: '관리' }
    ],
    Brand: [
        { id: 'com_nm', label: '회사명' },
        { id: 'br_nm', label: '브랜드명' },
        { id: 'br_img', label: '로고이미지' },
        { id: 'br_cate', label: '종류' },
        { id: 'br_main', label: '메인노출' },
        { id: 'use_yn', label: '사용여부' },
        { id: 'edit_delete', label: '관리' }
    ],
    Inquiry: [
        { id: 'bd_num', label: '문의번호' },
        { id: 'inqry', label: '문의유형' },
        { id: 'com_nm', label: '회사명' },
        { id: 'br_nm', label: '브랜드' },
        { id: 'subject', label: '제목' },
        { id: 'user_id', label: '회원 ID' },
        { id: 'reg_dt', label: '문의일시' },
        { id: 'mod_dt', label: '답변일시' },
        { id: 'mn_nm', label: '답변자' },
        { id: 'answer_yn', label: '답변여부' }
    ],
    Notice: [
        { id: 'subject', label: '제목' },
        { id: 'mn_nm', label: '등록자' },
        { id: 'reg_dt', label: '등록일시' },
        { id: 'use_yn', label: '사용여부' }
    ],
    Event: [
        { id: 'evt_type', label: '타입' },
        { id: 'evt_nm', label: '이벤트명' },
        { id: 'com_nm', label: '회사명' },
        { id: 'count', label: '조회수' },
        { id: 'use_yn', label: '사용여부' },
        { id: 'evt_s_dt', label: '노출 시작일시' },
        { id: 'evt_e_dt', label: '노출 종료일시' },
        { id: 'reg_dt', label: '등록일시' }
    ],
    Policy: [
        { id: 'policy_type', label: '구분' },
        { id: 'start_dt', label: '시작일' },
        { id: 'end_dt', label: '종료일' },
        { id: 'edit_delete', label: '관리' }
    ],
    Product: [
        { id: 'pro_num', label: '제품 번호' },
        { id: 'br_nm', label: '브랜드명' },
        { id: 'pro_nm', label: '제품명' },
        { id: 'retail_nm', label: '판매처' },
        { id: 'pro_img', label: '제품이미지' },
        // { id: 'pro_info', label: '제품 정보' },
        // { id: 'url', label: '상세 URL' },
        { id: 'use_yn', label: '사용여부' },
        { id: 'edit_delete', label: '관리' }
    ],
    Retail: [
        { id: 'retail_nm', label: '판매처' },
        { id: 'use_yn', label: '사용여부' },
        { id: 'edit_delete', label: '관리' }
    ]
};

const TABLE_SELECT_OPTION = {
    viewYn: [
        { label: '노출', value: 'Y' },
        { label: '미노출', value: 'N' }
    ],
    useYn: [
        { label: '사용', value: 'Y' },
        { label: '미사용', value: 'N' }
    ]
};

const BUTTONS = {
    addButton: { Dashboard: false },
    addTopButton: { Dashboard: true },
    deleteButton: { Dashboard: false, Brand: true },
    excelButton: { Dashboard: true }
};

export { TABLE_SELECT_OPTION, BUTTONS, HEAD_CELL };
