import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { SEARCH_OPTION as option } from 'configs/search';

function SearchRadio({ name, dataList, onChange }) {
    const searchState = useSelector((state) => state.search);

    const radioOptions = dataList?.length > 0 ? [...option[name], ...dataList] : option?.[name];

    return (
        <RadioGroup row aria-label="radio" name={name} value={searchState[name]} onChange={onChange}>
            {radioOptions?.map((radio) => (
                <FormControlLabel
                    key={`radio-${radio.label}`}
                    value={radio.value}
                    label={radio.label}
                    control={<Radio color="primary" />}
                />
            ))}
        </RadioGroup>
    );
}

SearchRadio.propTypes = {
    dataList: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    ),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

SearchRadio.defaultProps = {
    dataList: []
};

export default SearchRadio;
