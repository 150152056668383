import { useCallback, useEffect } from 'react';

import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { setMenu } from 'slices/menuSlice';

const useMenu = ({ page, menu, menuTitle, menuNum }) => {
    const dispatch = useDispatch();
    const { isExit, showSidebar, ...menuState } = useSelector((state) => state.menu);

    const handleMenu = useCallback(() => {
        if (!_.isEqual(menuState, { page, menu, menuTitle, menuNum })) {
            dispatch(setMenu({ page, menu, menuTitle, menuNum }));
        }
    }, [dispatch, menuState, page, menu, menuTitle, menuNum]);

    useEffect(() => {
        handleMenu();
    }, [handleMenu]);

    return page;
};

export default useMenu;
