import { Paper, Table, TableBody, TableCell, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableLoader = styled(Typography)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
    fontWeight: 700
}));

export const StyledTablePaper = styled(Paper)(({ theme: { palette } }) => ({
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
    '& table': {
        minWidth: 750,
        '& tr': {
            borderTop: `1px solid ${palette.border.opacity01}`,
            borderBottom: `1px solid ${palette.border.opacity01}`
        }
    }
}));

export const StyledTable = styled(Table)(({ theme: { palette } }) => ({
    minWidth: 750,
    '& tr': {
        borderTop: `1px solid ${palette.border.opacity01}`,
        borderBottom: `1px solid ${palette.border.opacity01}`
    },
    '& td': {
        padding: '0 20px'
    }
}));

export const StyledSticky = styled(TableCell)(({ theme: { palette } }) => ({
    position: 'sticky',
    left: 0,
    background: palette.neutral.white,
    borderRight: `1px solid ${palette.border.opacity01}`
}));

export const StyledStickyBody = styled(TableBody)(({ theme: { palette } }) => ({
    '& th': {
        fontSize: 11,
        fontWeight: 700,
        borderBottom: `1px solid ${palette.border.opacity01}`
    },
    '& td': {
        borderBottom: `1px solid ${palette.border.opacity01}`
    }
}));

export const StyledStickyTableCell = styled(TableCell)(({ theme: { palette } }) => ({
    position: 'sticky',
    left: 0,
    background: palette.background.light
}));
