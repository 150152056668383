import React from 'react';

import PropTypes from 'prop-types';

import { Box, Grid, Typography } from '@mui/material';

const ChartTitle = ({ title, isRight = true }) => {
    return (
        <Grid container direction="row" justifyContent="space-between">
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            {isRight && (
                <Box>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Box
                            sx={{
                                backgroundColor: '#3F72E6',
                                borderRadius: 4,
                                width: 8,
                                height: 8,
                                marginRight: '5px'
                            }}
                        />
                        <Typography variant="h6" gutterBottom>
                            정상
                        </Typography>
                        <Box
                            sx={{
                                backgroundColor: '#F5D445',
                                borderRadius: 4,
                                width: 8,
                                height: 8,
                                marginRight: '5px',
                                marginLeft: '5px'
                            }}
                        />
                        <Typography variant="h6" gutterBottom>
                            비유통
                        </Typography>
                    </Grid>
                </Box>
            )}
        </Grid>
    );
};

ChartTitle.propTypes = {
    title: PropTypes.string,
    isRight: PropTypes.bool
};

ChartTitle.defaultProps = {
    title: '',
    isRight: true
};

export default ChartTitle;
