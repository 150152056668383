import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { setClose, setConfirm } from 'slices/modalSlice';

import { DefaultButton as ModalButton } from 'components/Buttons';

function ConfirmModal() {
    const dispatch = useDispatch();
    const { msgOpen, messageType, message } = useSelector((state) => state.modal);

    const onConfirm = () => dispatch(setConfirm());

    const onClose = () => dispatch(setClose());

    return (
        msgOpen &&
        messageType === 'confirm' && (
            <Dialog
                open={msgOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose(event, reason);
                    }
                }}
            >
                <DialogTitle>확인</DialogTitle>
                <DialogContent>
                    <Typography minWidth={320} variant="body2">
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ p: '20px' }}>
                    <ModalButton size="small" text="확인" onClick={onConfirm} />
                    <ModalButton size="small" color="error" text="취소" onClick={onClose} />
                </DialogActions>
            </Dialog>
        )
    );
}

export default ConfirmModal;
