import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';

import palette from 'styles/theme/palette';

const formStyles = {
    container: (provided) => ({
        ...provided,
        width: '100%',
        maxWidth: 500,
        height: 36,
        display: 'inline-block',
        fontSize: 12,
        textAlign: 'center'
    }),
    control: (provided) => ({
        ...provided,
        height: 'inherit',
        boxShadow: 'unset',
        borderColor: palette.border.main,
        '&:hover': {
            borderColor: palette.border.main
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: 'inherit'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: 36,
        padding: 0
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        alignSelf: 'unset'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        width: 24,
        padding: '2px 6px 2px 2px'
    }),
    menu: (provided) => ({
        ...provided,
        margin: '2px 0 0'
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0
    }),
    option: (provided, state) => ({
        ...provided,
        height: 36,
        padding: '8px 10px',
        textAlign: 'center',
        color: state.isSelected ? palette.neutral.white : palette.text.primary,
        backgroundColor: state.isSelected ? palette.primary.main : palette.neutral.white,
        '&:hover': {
            backgroundColor: state.isSelected ? palette.primary.main : palette.primary.opacity02
        }
    })
};

function Select({ isClearable, isSearchable, name, options, control }) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <ReactSelect
                    {...field}
                    options={options}
                    aria-label={name}
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    styles={formStyles}
                />
            )}
        />
    );
}

Select.propTypes = {
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    control: PropTypes.object.isRequired
};

Select.defaultProps = {
    isClearable: false,
    isSearchable: false
};

export default Select;
