import React from 'react';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
    width: '100%',
    height: 100,
    padding: '0 0 20px 30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
        width: 'inherit',
        fontSize: 12,
        lineHeight: '18px',
        fontWeight: 300,
        letterSpacing: '0px',
        color: palette.neutral.white,
        opacity: 0.5
    }
}));

export default function Copyright() {
    return (
        <StyledBox>
            <Typography>
                Copyright © BlockOdyssey Corp. <br />
                All rights reserved.
            </Typography>
        </StyledBox>
    );
}
