import React from 'react';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: 48,
    height: 32,
    marginRight: 4,
    padding: 6,
    boxShadow: 'unset',
    borderRadius: 4,
    border: `1px solid ${theme.palette.border.main}`,
    fontSize: 12,
    letterSpacing: ' -0.24px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.neutral.white,
    '&:hover': {
        backgroundColor: theme.palette.background.light,
        border: `1px solid ${theme.palette.border.main}`,
        boxShadow: 'unset'
    }
}));

function TableButton({ disabled, text, rowIndex, onClick, color }) {
    return (
        <StyledButton sx={{ color }} variant="contained" disabled={disabled} onClick={() => onClick(rowIndex)}>
            {text}
        </StyledButton>
    );
}

TableButton.propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string.isRequired,
    rowIndex: PropTypes.number,
    onClick: PropTypes.func.isRequired
};
TableButton.defaultProps = {
    disabled: false,
    color: 'text.primary',
    rowIndex: undefined
};

export default TableButton;
