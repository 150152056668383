import React from 'react';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { TableCell } from '@mui/material';
import Popover from '@mui/material/Popover';

import { menuSelector } from 'slices/menuSlice';
// import { setConfirm } from 'slices/modalSlice';
import { setProduct } from 'slices/modalSlice';

import useMessage from 'hooks/useMessage';

import TableButton from 'components/Table/Button';
import TableSelect from 'components/Table/Select';

function ListTable({ row, handleSelect }) {
    const { menu } = useSelector(menuSelector);
    const dispatch = useDispatch();

    const handleMessage = useMessage();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const tableSelectOptions = {
        useYn: [
            { label: '사용', value: 'Y' },
            { label: '미사용', value: 'N' }
        ]
    };

    const onModify = (index) => {
        dispatch(setProduct({ open: true, data: { modalStatus: 'modify', index } }));
    };

    const onConfirm = (index) => {
        if (index) {
            handleMessage({
                type: 'confirm',
                confirmType: 'productDelete',
                message: '선택한 항목을 삭제합니다.',
                data: index
            });
        } else handleMessage({ message: '잘못된 접근입니다.' });
    };

    const transformSelName = (selNm) => {
        // 서버에서 불러온 sel_nm 은 콤마(,)로 구분된 판매처명
        const sellerArr = selNm.split(',');
        if (sellerArr.length === 1) {
            return sellerArr[0];
        }
        return `${sellerArr[0]} 외 ${sellerArr.length - 1}개`;
    };

    return (
        <>
            {menu === 'product' && (
                <>
                    <TableCell align="center">{row.pro_num || '-'}</TableCell>
                    <TableCell align="center">{row.br_nm || '-'}</TableCell>
                    <TableCell align="center" sx={{ maxWidth: 200 }}>
                        {row.pro_nm || '-'}
                    </TableCell>
                    <TableCell align="center">{transformSelName(row.sel_nm) || '-'}</TableCell>
                    <TableCell align="center">
                        <input
                            type="image"
                            src={row.pro_img}
                            alt="product_img"
                            style={{ width: 36, height: 36 }}
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        />
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: 'none'
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <input type="image" src={row.pro_img} alt="pro_img" style={{ width: 128, height: 128 }} />
                        </Popover>
                    </TableCell>
                    {/* <TableCell
                        align="center"
                        sx={{
                            maxWidth: 200,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                            '&.MuiTableCell-root': {
                                borderBottom: 0
                            }
                        }}
                    >
                        {row.pro_dc || '-'}
                    </TableCell>
                    <TableCell
                        align="center"
                        sx={{
                            maxWidth: 200,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            // display: '-webkit-box',
                            flexWrap: 'nowrap'
                        }}
                    >
                        {row.pro_url || '-'}
                    </TableCell> */}
                    <TableCell align="center">
                        <TableSelect
                            rowIndex={row.pro_idx}
                            name="use_yn"
                            value={row.use_yn}
                            label={row.use_yn === 'Y' ? '사용' : '미사용'}
                            options={tableSelectOptions.useYn}
                            handleSelect={handleSelect}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <TableButton pageType="edit" text="수정" rowIndex={row.pro_idx} onClick={onModify} />
                        <TableButton pageType="delete" text="삭제" rowIndex={row.pro_idx} onClick={onConfirm} />
                    </TableCell>
                </>
            )}
        </>
    );
}

ListTable.propTypes = {
    row: PropTypes.object.isRequired,
    handleSelect: PropTypes.func.isRequired
};

export default ListTable;
