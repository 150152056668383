import React from 'react';

import useGetList from 'hooks/useGetList';
import useMenu from 'hooks/useMenu';

import Charts from 'pages/Chart/components/Charts';

export default function Chart() {
    const menu = useMenu({ page: 'Chart', menu: 'chart', menuTitle: '요약보기', menuNum: 0 }); // 페이지/메뉴 설정하기

    // purchase 데이터 가져오기
    const { isLoading: lineLoading, data: lineChartData } = useGetList({
        menu,
        url: `/web/summary/scan`
    });

    return <Charts lineLoading={lineLoading} lineChartData={lineChartData} />;
}
