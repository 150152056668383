/* eslint-disable import/prefer-default-export */
const MESSAGES = {
    submit: '입력한 정보를 등록하시겠습니까?',
    'edit submit': '이미지를 삭제하시겠습니까?',
    'upload success': '성공적으로 등록되었습니다.',
    'edit success': '성공적으로 수정되었습니다.',

    fail: '잘못된 요청입니다.',

    delete: '이미지를 삭제하시겠습니까?',
    reset: '입력한 내용을 삭제하시겠습니까?',
    'upload cancel': '목록으로 돌아가시겠습니까? 입력된 정보는 저장되지 않습니다.',
    'edit cancel': '목록으로 돌아가시겠습니까? 입력된 정보는 수정되지 않습니다.',

    'no dates': '시작일과 종료일을 선택해주세요.',
    'no image': '하나 이상의 이미지 파일을 선택해주세요.',
    'upload image': '이미지를 불러오는 중 문제가 발생하였습니다. 잠시 후 다시 시도해주세요.'
};

export { MESSAGES };
