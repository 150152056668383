import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { setClose } from 'slices/modalSlice';

function ImageModal() {
    const dispatch = useDispatch();
    const { imgOpen, data } = useSelector((state) => state.modal);

    const onClose = () => dispatch(setClose());

    return (
        imgOpen && (
            <Dialog
                open={imgOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose(event, reason);
                    }
                }}
            >
                <DialogTitle>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h2">이미지 조회</Typography>
                        <CloseIcon sx={{ cursor: 'pointer' }} fontSize="large" onClick={onClose} />
                    </Grid>
                </DialogTitle>
                <DialogContent sx={{ width: 700 }}>
                    <img width="100%" src={data} alt="preview" />
                </DialogContent>
            </Dialog>
        )
    );
}

export default ImageModal;
