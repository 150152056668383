import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
    Image as ImageIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import { Grid } from '@mui/material';

import { StyledDefaultImage, StyledImageButton, StyledNoImage } from 'styles/customize/page';

function UploadImage({ imageIndex, setImageIndex, imageFiles }) {
    const [imgError, setImgError] = useState(false);

    // 이미지 이전 버튼
    const onPreviousImage = () => {
        if (imageIndex <= 0) {
            setImageIndex(imageFiles.length - 1);
        } else {
            setImageIndex((prevState) => prevState - 1);
        }
    };

    // 이미지 다음 버튼
    const onNextImage = () => {
        if (imageIndex >= imageFiles.length - 1) {
            setImageIndex(0);
        } else {
            setImageIndex((prevState) => prevState + 1);
        }
    };

    return imageFiles[imageIndex] ? (
        <Grid
            sx={{ position: 'relative', width: 500, height: 500, overflow: 'hidden', '& img': { borderRadius: '4px' } }}
            container
            justifyContent="center"
            alignItems="center"
        >
            {imgError ? (
                <StyledDefaultImage>
                    <ImageIcon />
                </StyledDefaultImage>
            ) : (
                <img
                    width="100%"
                    height={500}
                    onError={() => setImgError(true)}
                    src={imageFiles[imageIndex].preview}
                    alt="product img"
                />
            )}
            {imageFiles.length > 1 && (
                <Grid
                    sx={{ position: 'absolute', width: 500, height: 500 }}
                    item
                    xs
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <StyledImageButton startIcon={<ChevronLeftIcon />} variant="contained" onClick={onPreviousImage} />
                    <StyledImageButton endIcon={<ChevronRightIcon />} variant="contained" onClick={onNextImage} />
                </Grid>
            )}
        </Grid>
    ) : (
        <StyledNoImage />
    );
}

UploadImage.propTypes = {
    imageFiles: PropTypes.array,
    imageIndex: PropTypes.number.isRequired,
    setImageIndex: PropTypes.func.isRequired
};

UploadImage.defaultProps = {
    imageFiles: []
};

export default UploadImage;
