import { useDispatch } from 'react-redux';

// import { setLogOut } from 'slices/loginSlice';
import { setMessage } from 'slices/modalSlice';

const useMessage = () => {
    const dispatch = useDispatch();

    const handleMessage = ({ statusCode, ...rest }) => {
        dispatch(setMessage(rest));
    };

    return handleMessage;
};

export default useMessage;
