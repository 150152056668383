/* eslint-disable camelcase */

import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { Box, Grid, Paper, Typography } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';

import useFormError from 'hooks/useFormError';
import useMenu from 'hooks/useMenu';
import useMessage from 'hooks/useMessage';

import { patchData } from 'api';
import { Input, SubmitButton } from 'components';

import { SCHEMA } from 'configs/form';

import theme from 'styles/theme/form';

const Title = styled(Typography)(() => ({
    fontWeight: 300,
    fontSize: 30,
    lineHeight: '38px',
    letterSpacing: '-1.8px',
    '& span': {
        font: 'inherit',
        fontWeight: 500,
        letterSpacing: 'inherit'
    }
}));

export default function ChangePassword() {
    useMenu({ page: 'ChangePassword', menu: null, menuTitle: '비밀번호 변경', menuNum: null });
    const defaultValues = {
        admin_pw: '',
        new_pw: '',
        check_pw: ''
    };
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(SCHEMA.ChangePwd)
    });
    const handleMessage = useMessage();
    // TODO :: 비밀번호 정규식 넣어야함.
    const { mutate } = useMutation('changePwd', (body) => patchData('web/admin/password', body), {
        onSuccess: () => {
            handleMessage({ message: '비밀번호가 변경되었습니다.' });
            reset(defaultValues);
        },
        onError: ({ statusCode, message }) => {
            if (statusCode) handleMessage({ message });
        }
    });

    const onSubmit = (data) => {
        const { admin_pw, new_pw, check_pw } = data;
        if (new_pw !== check_pw) {
            handleMessage({ message: '새 비밀번호가 일치하지 않습니다.' });
        } else {
            mutate({ admin_pw, new_pw });
        }
    };
    useFormError(errors);
    return (
        <Paper sx={{ width: '100%', height: '100%', pb: '20px', bgcolor: 'neutral.white' }} elevation={1}>
            <ThemeProvider theme={theme}>
                <Box borderRadius="4px" bgcolor="neutral.white">
                    <Box
                        sx={{ height: 560, width: 322, mx: 'auto' }}
                        component="form"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Grid height="100%" container justifyContent="center" alignItems="center" direction="column">
                            <Grid mb={5} item xs="auto">
                                <Title variant="h3" component="h3">
                                    주기적인 <Typography component="span">비밀번호 변경</Typography>으로 <br />
                                    안전하게 <Typography component="span">정보</Typography>를{' '}
                                    <Typography component="span">보호</Typography>하세요
                                </Title>
                            </Grid>
                            <Grid height={48} mb="20px" item xs="auto">
                                <Input
                                    inputType="password"
                                    placeholder="현재 비밀번호를 입력해주세요"
                                    variant="outlined"
                                    control={control}
                                    name="admin_pw"
                                />
                            </Grid>
                            <Grid height={48} item xs="auto">
                                <Input
                                    inputType="password"
                                    placeholder="새 비밀번호를 입력해주세요"
                                    variant="outlined"
                                    control={control}
                                    name="new_pw"
                                />
                            </Grid>
                            <Grid height={48} mb="20px" item xs="auto">
                                <Input
                                    inputType="password"
                                    placeholder="비밀번호를 한번 더 입력해주세요"
                                    variant="outlined"
                                    control={control}
                                    name="check_pw"
                                />
                            </Grid>
                            <Grid width={{ xs: '100%' }} height={48} item xs="auto">
                                <SubmitButton type="submit" text="비밀번호 변경하기" />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </ThemeProvider>
        </Paper>
    );
}
