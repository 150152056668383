import { Box, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled(Grid)(({ theme: { palette, breakpoints } }) => ({
    [breakpoints.up('xs')]: {
        width: 450
    },
    height: 500,
    boxShadow: `0px 0px 5px 2px ${palette.shadow.primary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
        height: '100%'
    }
}));

export const StyledDefaultImage = styled(Box)(({ theme: { palette } }) => ({
    width: 500,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.background.light,

    '& svg': {
        width: '30%',
        height: '30%',
        color: palette.background.main
    }
}));

export const StyledNoImage = styled(Box)(({ theme: { palette } }) => ({
    width: 500,
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    borderRadius: 4,
    color: palette.text.label,
    textTransform: 'uppercase',
    fontSize: '18px',
    backgroundColor: palette.background.main
}));

export const StyledTable = styled('table')(({ theme: { palette } }) => ({
    borderRadius: 4,
    '& tr': {
        borderTop: `1px solid ${palette.border.opacity01}`,
        borderBottom: `1px solid ${palette.border.opacity01}`
    },
    '& th': {
        width: 160,
        fontWeight: 500,
        lineHeight: '48px',
        textAlign: 'left',
        color: palette.text.primary
    },
    '& td': {
        padding: 7
    }
}));

export const StyledImageContainer = styled(Box)(() => ({
    paddingBottom: 30,
    '& h4': {
        marginBottom: 15
    },
    "& input[type='file']": {
        display: 'none'
    }
    // "& h4 + div > div": {
    //     position: "relative",
    //     width: 500,
    //     overflow: "hidden",
    //     "& img": {
    //         margin: "0 auto",
    //         borderRadius: 4
    //     },
    //     "& img + div": {
    //         position: "absolute",
    //         width: 500,
    //         height: 500
    //     }
    // },
    // "& > div > div + div": {
    //     width: 500,
    //     margin: "20px auto",
    //     "& img:last-of-type": {
    //         margin: 0
    //     }
    // }
}));

export const StyledImageButton = styled(Button)(({ theme: { palette } }) => ({
    width: 40,
    height: 40,
    minWidth: 0,
    margin: 0,
    padding: 0,
    boxShadow: 'unset',
    border: 'none',
    borderRadius: 4,
    fontWeight: 700,
    fontSize: 12,
    color: palette.neutral.black,
    background: 'none',
    '& > span': {
        marginLeft: 0,
        marginRight: 0
    },
    '& svg': {
        width: 40,
        height: 40,
        background: '#fbfbfb80'
    },
    '&:hover': {
        boxShadow: 'unset',
        border: 'none',
        background: 'none',
        color: palette.neutral.black
    },
    '&:active': {
        boxShadow: 'unset',
        transition: 'none',
        background: 'none'
    }
}));

export const StyledSmallerImage = styled('img')(() => ({
    height: '100%',
    marginRight: 5,
    borderRadius: 4,
    cursor: 'pointer'
}));
