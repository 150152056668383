import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from '@mui/material';

import { setSearchFilters } from 'slices/searchSlice';

import useGetList from 'hooks/useGetList';
import useMessage from 'hooks/useMessage';
import useSearch from 'hooks/useSearch';

import { putData } from 'api';

import { HEAD_CELL } from 'configs/table';

import { StyledTablePaper } from 'styles/customize/table';

import { TableLoader, Pagination } from '../../../components';
import BrandData from './ListTable';

function BrandTable({ isLoading, data, total, selected, setSelected, refetch }) {
    const dispatch = useDispatch();
    const { page, pageSize } = useSelector((state) => state.search);
    const { page: menu } = useSelector((state) => state.menu);

    const handleSearch = useSearch({ menu });
    const handleMessage = useMessage();
    const numSelected = selected ? selected.length : 0;
    const rowCount = data.length;

    const { data: companyList } = useGetList({ menu: 'getCompanyList', url: '/web/common/companyList' });
    const { mutate: updateMutation } = useMutation(({ url, fileYn, data }) => putData(url, data, fileYn), {
        onSuccess: () => refetch(),
        onError: (error) => handleMessage({ type: 'message', ...error })
    });

    const isSelected = (index) => (selected ? selected.indexOf(index) !== -1 : false);

    // 사용여부/노출여부 등 select 데이터 수정하기
    const handleSelect = (name, value, rowIndex) => {
        // api 수정되면 수정 필요
        if (name === 'use_yn') {
            updateMutation({ url: `/web/brand/use/${rowIndex}`, fileYn: false, data: { [name]: value } });
        } else if (name === 'br_main')
            updateMutation({ url: `/web/brand/main/${rowIndex}`, fileYn: false, data: { [name]: value } });
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.br_idx);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, index) => {
        const selectedIndex = selected.indexOf(index);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, index);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    // Pagination
    const handlePage = (paging) => {
        dispatch(setSearchFilters(paging));
        handleSearch(paging);
    };

    const onPageChange = (newPage) => handlePage({ page: newPage, pageSize }); // 페이지 이동하기

    const onRowsPerPageChange = (event) => handlePage({ page: 1, pageSize: parseInt(event.target.value, 10) }); // rows per page(페이지 당 행) 변경하기

    const onConfirm = () => {
        if (selected) {
            if (selected.length === 0) {
                alert('삭제할 항목을 선택해주세요.');
            } else
                handleMessage({
                    type: 'confirm',
                    confirmType: 'brandDelete',
                    message: '선택한 항목들을 삭제합니다.',
                    data: selected
                });
        } else handleMessage({ message: '잘못된 접근입니다.' });
    };

    useEffect(() => {
        setSelected([]);
    }, [page]);

    return (
        <StyledTablePaper elevation={1}>
            <TableContainer>
                <Table
                    aria-labelledby={`${menu.toLowerCase()}Table`}
                    size="medium"
                    aria-label={`${menu.toLowerCase()} table`}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                    checked={rowCount > 0 && numSelected === rowCount}
                                    onChange={handleSelectAllClick}
                                    inputProps={{ 'aria-label': 'select all data' }}
                                />
                            </TableCell>
                            {HEAD_CELL[menu]?.map(({ id, label }) => (
                                <TableCell key={id} align="center">
                                    {label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableLoader colSpan={9} />
                        ) : total === 0 ? (
                            <TableLoader type="empty" colSpan={20} />
                        ) : (
                            data.map((row) => {
                                const labelId = `${menu.toLowerCase()}-table-checkbox-${row.br_idx}`;
                                const isItemSelected = isSelected(row.br_idx);

                                return (
                                    <React.Fragment key={row.br_idx}>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.br_idx}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleClick(event, row.br_idx)}
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <BrandData
                                                menu={menu}
                                                row={row}
                                                index={row.br_idx}
                                                handleSelect={handleSelect}
                                                companyList={companyList}
                                            />
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!isLoading && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Pagination
                        count={total}
                        page={page > 0 ? page - 1 : 0}
                        rowsPerPage={pageSize || 10}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                        onDelete={onConfirm}
                        companyList={companyList}
                    />
                </Box>
            )}
        </StyledTablePaper>
    );
}

BrandTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    selected: PropTypes.array,
    setSelected: PropTypes.func,
    refetch: PropTypes.func.isRequired
};

BrandTable.defaultProps = {
    data: [],
    total: 0,
    selected: false,
    setSelected: null
};

export default BrandTable;
