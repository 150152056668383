import React from 'react';

import { Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';
import { Bubble } from 'react-chartjs-2';

import { Skeleton, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import ChartTitle from './ChartTitle';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
        datalabels: {
            formatter: (value) => {
                return value.title;
            }
        }
    },
    scales: {
        x: {
            display: false
        },
        y: {
            display: false
        }
    }
};

const getRandom = (max, min) => Math.floor(Math.random() * (max - min) + min);

const data = {
    title: {
        display: true
    },
    datasets: [
        {
            data: Array.from({ length: 2 }, () => ({
                x: getRandom(100, -100),
                y: getRandom(100, -100),
                r: 50,
                title: '아이오페'
            })),
            backgroundColor: 'rgba(255, 99, 132, 0.5)'
        }
    ]
};

const rows = [
    {
        name: '설화수',
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4
    },
    {
        name: '아이오페',
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4
    },
    {
        name: '라네즈',
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4
    },
    {
        name: '헤라',
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4
    },
    {
        name: '프리메라',
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4
    },
    {
        name: '한율',
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4
    }
];

function BubbleChart({ bubbleLoading, bubbleChartData }) {
    return bubbleLoading ? (
        <Skeleton variant="rectangular" width="100%" height={500} />
    ) : (
        <>
            <ChartTitle title="브랜드별 Summary" isRight={false} />
            <Box sx={{ position: 'relative', width: '100%', height: 322, marginTop: '20px' }}>
                <Bubble data={bubbleChartData.length > 0 || data} options={options} />
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" sx={{ '&&& *': { height: '10px', marginTop: '20px' } }}>
                    <TableHead sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                        <TableRow sx={{ borderBottom: '2px solid black' }}>
                            <TableCell sx={{ width: '24%' }}>브랜드</TableCell>
                            <TableCell sx={{ width: '19%' }} align="center">
                                합계
                            </TableCell>
                            <TableCell sx={{ width: '19%' }} align="center">
                                정상
                            </TableCell>
                            <TableCell sx={{ width: '19%' }} align="center">
                                비유통
                            </TableCell>
                            <TableCell sx={{ width: '19%' }} align="center">
                                퍼센트
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 }
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="center">{row.value1}</TableCell>
                                <TableCell align="center">{row.value2}</TableCell>
                                <TableCell align="center">{row.value3}</TableCell>
                                <TableCell align="center" sx={{ color: 'red' }}>
                                    {row.value4}%
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

BubbleChart.propTypes = {
    bubbleLoading: PropTypes.bool,
    bubbleChartData: PropTypes.arrayOf(PropTypes.object)
};

BubbleChart.defaultProps = {
    bubbleLoading: false,
    bubbleChartData: []
};

export default BubbleChart;
