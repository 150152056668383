import React, { useEffect, useState } from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

import { Skeleton, Box, Grid, Typography } from '@mui/material';

import ChartTitle from './ChartTitle';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            min: 0,
            ticks: {
                stepSize: 1
            }
        }
    },
    animation: {
        duration: 500
    },
    elements: {
        line: {
            tension: 0.3
        }
    },
    plugins: {
        legend: {
            display: false
        }
    }
};

const lineChart = {
    scanByDate: [
        {
            '1_count': 3,
            '2_count': 0,
            '3_count': 9,
            '4_count': 0,
            '5_count': 0,
            '6_count': 0,
            '7_count': 49,
            reg_dt: '2022-07-12'
        },
        {
            '1_count': 0,
            '2_count': 0,
            '3_count': 8,
            '4_count': 0,
            '5_count': 0,
            '6_count': 0,
            '7_count': 7,
            reg_dt: '2022-07-13'
        }
    ],
    scanByBrand: [
        {
            br_nm: '테스트',
            normal: 6,
            abnormal: 0
        }
    ]
};
const handleChartData = (chartdata) => {
    // const data = [...chartdata].reverse();
    const result = {
        chartDate: [],
        chartData1: [],
        chartData2: [],
        chartData3: [],
        chartData5: [],
        chartData6: [],
        chartData7: []
    };

    chartdata.forEach((element) => {
        for (const key in element) {
            if (key)
                switch (key) {
                    case '1_count':
                        result.chartData1.push(element[key]);
                        break;
                    case '2_count':
                        result.chartData2.push(element[key]);
                        break;
                    case '3_count':
                        result.chartData3.push(element[key]);
                        break;
                    case '5_count':
                        result.chartData5.push(element[key]);
                        break;
                    case '6_count':
                        result.chartData6.push(element[key]);
                        break;
                    case '7_count':
                        result.chartData7.push(element[key]);
                        break;
                    case 'reg_dt':
                        result.chartDate.push(element[key]);
                        break;
                    default:
                        break;
                }
        }
    });

    return result;
};

const SummaryRender = ({ chartData, title, chartColor, isLeftBorder }) => {
    return (
        <Grid item xs={2} sx={{ paddingLeft: '20px', borderLeft: isLeftBorder && '1px solid #C4BEBC' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ color: '#828182' }} variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Box
                    sx={{
                        margin: '7px 5px',
                        backgroundColor: chartColor,
                        borderRadius: 4,
                        width: 8,
                        height: 8,
                        marginRight: '5px'
                    }}
                />
            </Box>
            <Typography variant="h2" gutterBottom>
                {chartData.reduce((a, b) => a + b)}
            </Typography>
        </Grid>
    );
};

SummaryRender.propTypes = {
    chartData: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    chartColor: PropTypes.string.isRequired,
    isLeftBorder: PropTypes.bool.isRequired
};

function LineChart({ lineLoading, lineChartData }) {
    const [cartData, setCartData] = useState(handleChartData(lineChart.scanByDate));
    const { chartDate, chartData1, chartData2, chartData3, chartData5, chartData6, chartData7 } = cartData;

    useEffect(() => {
        if (lineChartData?.length > 0) {
            setCartData(handleChartData(lineChartData));
        }
    }, [lineChartData]);

    const lineData = {
        labels: chartDate,
        datasets: [
            {
                label: '구매등록',
                data: chartData1,
                borderColor: 'rgba(204, 0, 0, 0.5)',
                backgroundColor: '#CC0000'
            },
            {
                label: '기인증(본인)',
                data: chartData2,
                borderColor: 'rgb(204, 153, 0, 0.5)',
                backgroundColor: '#CC9900'
            },

            {
                label: '기인증(타인)',
                data: chartData3,
                borderColor: 'rgb(204, 204, 051, 0.5)',
                backgroundColor: '#CCCC33'
            },
            {
                label: '고의 위변조',
                data: chartData5,
                borderColor: 'rgb(051, 204, 051, 0.5)',
                backgroundColor: '#33CC33'
            },
            {
                label: '생수병',
                data: chartData6,
                borderColor: 'rgb(051, 204, 204, 0.5)',
                backgroundColor: '#33CCCC'
            },
            {
                label: '스캔',
                data: chartData7,
                borderColor: 'rgb(102, 051, 102, 0.5)',
                backgroundColor: '#663366'
            }
        ]
    };

    return lineLoading ? (
        <Skeleton variant="rectangular" width="100%" height={400} />
    ) : (
        <>
            <ChartTitle title="구매등록" isRight={false} />
            <Box
                sx={{
                    padding: '20px',
                    borderRadius: '10px',
                    background: '#F5F2F4',
                    margin: '20px 0',
                    height: '180px',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Grid container justifyContent="flex-start" alignItems="center" direction="row">
                    {lineData.datasets.map((el, idx) => (
                        <SummaryRender
                            key={idx + el.label}
                            title={el.label}
                            chartData={el.data}
                            chartColor={el.backgroundColor}
                            isLeftBorder={idx !== 0}
                        />
                    ))}
                </Grid>
            </Box>
            <Box sx={{ position: 'relative', width: '100%', height: 400 }}>
                <Line data={lineData} options={options} />
            </Box>
        </>
    );
}

LineChart.propTypes = {
    lineLoading: PropTypes.bool,
    lineChartData: PropTypes.object
};

LineChart.defaultProps = {
    lineLoading: false,
    lineChartData: null
};

export default LineChart;
