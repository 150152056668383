import { createSlice } from '@reduxjs/toolkit';

export const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        page: '',
        menu: 'dashboard',
        menuTitle: 'Dashboard',
        menuNum: 0,
        isExit: false,
        showSidebar: false
    },
    reducers: {
        setMenu: (state, { payload }) => {
            state.page = payload.page;
            state.menu = payload.menu;
            state.menuTitle = payload.menuTitle;
            state.menuNum = payload.menuNum;
            state.isExit = false;
            state.showSidebar = false;
        },
        setIsExit: (state, { payload }) => {
            state.isExit = payload;
        },
        setShowMenu: (state) => {
            state.showSidebar = !state.showSidebar;
        }
    }
});

export const { setMenu, setIsExit, setShowMenu } = menuSlice.actions;

export const menuSelector = (state) => state.menu;

export default menuSlice.reducer;
