import React from 'react';

import useGetList from 'hooks/useGetList';
import useMenu from 'hooks/useMenu';

import { Search } from 'components';

import EventTable from './components/EventTable';

export default function Event() {
    const menu = useMenu({ page: 'Event', menu: 'event', menuTitle: '이벤트', menuNum: 9 }); // 페이지/메뉴 설정하기
    const { isLoading, data: dataList } = useGetList({ menu, url: '/web/event/lists' }); // 리스트 데이터 가져오기
    return (
        <>
            <Search path="/event" heading="" total={dataList?.totalCount} />
            <EventTable isLoading={isLoading} data={dataList?.eventLists} total={dataList?.totalCount} />
        </>
    );
}
