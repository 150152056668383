const DATE_FORMAT = {
    daily: 'yyyy/MM/dd',
    monthly: 'yyyy/MM'
};

const SEARCH_COMPONENT = {
    Dashboard: {
        date: true,
        dateSelect: false,
        radio: true,
        selects: true,
        searchCondition: true,
        searchKeyword: true
    },
    Brand: {
        date: false,
        dateSelect: false,
        radio: false,
        selects: false,
        searchCondition: false,
        searchKeyword: true
    },
    User: {
        date: false,
        dateSelect: false,
        radio: false,
        selects: false,
        searchCondition: false,
        searchKeyword: false
    },
    Inquiry: {
        date: true,
        dateSelect: true,
        radio: false,
        selects: false,
        searchCondition: false,
        searchKeyword: true
    },
    Notice: {
        date: true,
        dateSelect: false,
        radio: false,
        selects: false,
        searchCondition: false,
        searchKeyword: true
    },
    Event: {
        date: true,
        dateSelect: true,
        radio: false,
        selects: false,
        searchCondition: false,
        searchKeyword: true
    },
    Chart: {
        date: false,
        radio: false,
        selects: false,
        searchCondition: false,
        searchKeyword: false
    },
    Signup: {
        date: true,
        dateSelect: false,
        searchKeyword: true,
        searchCondition: true
    },
    Scan: {
        date: true,
        dateSelect: false,
        searchKeyword: true,
        searchCondition: true
    },
    Regist: {
        date: true,
        dateSelect: false,
        searchKeyword: true,
        searchCondition: true
    },
    Report: {
        date: true,
        dateSelect: false,
        searchKeyword: true,
        searchCondition: true
    },
    Product: {
        date: false,
        dateSelect: false,
        radio: false,
        selects: false,
        searchCondition: true,
        searchKeyword: true
    },
    Retail: {
        date: false,
        dateSelect: false,
        radio: false,
        selects: false,
        searchCondition: true,
        searchKeyword: true
    }
};

const SEARCH_CAPTION = {
    gender: '성별',
    useYn: '판매여부',
    dessert: '디저트',
    sweets: '캔디',
    food: '음식',
    drink: '음료'
};
const SEARCH_RADIO_ROW = {
    Dashboard: [['sweets', 'food'], ['drink']]
};
const SEARCH_SELECT = {
    Dashboard: [['gender', 'useYn'], ['dessert']],
    Inquiry: ['startDate', 'endDate']
};

const SEARCH_OPTION = {
    inquiryDateType: [
        { value: 'reg_dt', label: '문의일시' },
        { value: 'mod_dt', label: '답변일시' }
    ],
    eventDateType: [
        { value: 'reg_dt', label: '등록일시' },
        { value: 'evt_s_dt', label: '노출 시작일시' },
        { value: 'evt_e_dt', label: '노출 종료일시' }
    ],
    gender: [
        { value: '', label: '전체' },
        { value: 'M', label: '남성' },
        { value: 'F', label: '여성' }
    ],
    useYn: [
        { value: '', label: '전체' },
        { value: 'Y', label: '판매' },
        { value: 'N', label: '미판매' }
    ],
    searchCondition: {
        Dashboard: [
            { value: 'all', label: '전체' },
            { value: 'id', label: '아이디' },
            { value: 'name', label: '이름' },
            { value: 'tel', label: '연락처' }
        ],
        Brand: [
            { value: 'all', label: '전체' },
            { value: 'com_nm', label: '회사명' },
            { value: 'br_nm', label: '브랜드명' }
        ],
        Product: [
            { value: 'all', label: '전체' },
            { value: 'pro_num', label: '제품번호' },
            { value: 'br_nm', label: '브랜드명' },
            { value: 'pro_nm', label: '제품명' }
        ],
        Retail: [{ value: 'all', label: '전체' }],
        User: [
            { value: 'all', label: '전체' },
            { value: 'com_nm', label: '회사명' },
            { value: 'mn_nm', label: '담당자' },
            { value: 'mn_tel', label: '전화번호' },
            { value: 'mn_email', label: '이메일' },
            { value: 'admin_id', label: '아이디' }
        ],
        Signup: [
            { value: 'all', label: '전체' },
            { value: 'user_nm', label: '이름' },
            { value: 'user_id', label: '아이디' },
            { value: 'user_mobile', label: '휴대폰번호' }
        ],
        Scan: [
            { value: 'all', label: '전체' },
            { value: 'com_nm', label: '회사명' },
            { value: 'br_nm', label: '브랜드명' },
            { value: 'pro_nm', label: '제품명' },
            { value: 'user_id', label: '아이디' }
        ],
        Regist: [
            { value: 'all', label: '전체' },
            { value: 'com_nm', label: '회사명' },
            { value: 'br_nm', label: '브랜드명' },
            { value: 'pro_nm', label: '제품명' },
            { value: 'purchs_place', label: '구매처' },
            { value: 'user_id', label: '아이디' }
        ],
        Report: [
            { value: 'all', label: '전체' },
            { value: 'com_nm', label: '회사명' },
            { value: 'br_nm', label: '브랜드명' },
            { value: 'pro_nm', label: '제품명' },
            { value: 'source', label: '구매처' },
            { value: 'user_id', label: '아이디' }
        ],
        Inquiry: [
            { value: 'all', label: '전체' },
            { value: 'bd_num', label: '문의번호' },
            { value: 'com_nm', label: '회사명' },
            { value: 'br_nm', label: '브랜드' },
            { value: 'subject', label: '제목' },
            { value: 'user_id', label: '회원ID' },
            { value: 'mn_nm', label: '답변자' }
        ],
        Notice: [
            { value: 'all', label: '전체' },
            { value: 'subject', label: '제목' },
            { value: 'mn_nm', label: '등록자' }
        ],
        Event: [
            { value: 'all', label: '전체' },
            { value: 'com_nm', label: '회사명' },
            { value: 'evt_nm', label: '이벤트명' }
        ]
    },
    sort: {
        Dashboard: [
            { value: 'latest', label: '최신 등록순' },
            { value: 'oldest', label: '오래된 등록순' }
        ]
    },
    term: [
        { value: 'daily', label: '일간' },
        { value: 'monthly', label: '월간' }
    ]
};

const SEARCH_PARAM = {
    Dashboard: {
        // API query명 : redux state이름
        startDate: 'startDate',
        endDate: 'endDate',
        gender: 'gender',
        useYn: 'useYn',
        dessert: 'dessert',
        food1: 'food',
        sweets1: 'sweets',
        drink1: 'drink',
        searchCondition: 'searchCondition',
        searchKeyword: 'searchKeyword',
        sort: 'sort',
        page: 'page',
        pageSize: 'pageSize'
    },
    Signup: {
        start_time: 'startDate',
        end_time: 'endDate',
        condition: 'searchCondition',
        keyword: 'searchKeyword',
        current_page: 'page',
        limit: 'pageSize'
    },
    Scan: {
        start_time: 'startDate',
        end_time: 'endDate',
        condition: 'searchCondition',
        keyword: 'searchKeyword',
        current_page: 'page',
        limit: 'pageSize'
    },
    Regist: {
        start_time: 'startDate',
        end_time: 'endDate',
        condition: 'searchCondition',
        keyword: 'searchKeyword',
        current_page: 'page',
        limit: 'pageSize'
    },
    Report: {
        start_time: 'startDate',
        end_time: 'endDate',
        condition: 'searchCondition',
        keyword: 'searchKeyword',
        current_page: 'page',
        limit: 'pageSize'
    },
    User: {
        start_time: 'startDate',
        end_time: 'endDate',
        condition: 'searchCondition',
        keyword: 'searchKeyword',
        current_page: 'page',
        limit: 'pageSize'
    },
    Brand: {
        condition: 'searchCondition',
        keyword: 'searchKeyword',
        current_page: 'page',
        limit: 'pageSize'
    },
    Inquiry: {
        date_type: 'inquiryDateType',
        start_time: 'startDate',
        end_time: 'endDate',
        condition: 'searchCondition',
        keyword: 'searchKeyword',
        current_page: 'page',
        limit: 'pageSize'
    },
    Notice: {
        start_time: 'startDate',
        end_time: 'endDate',
        condition: 'searchCondition',
        keyword: 'searchKeyword',
        current_page: 'page',
        limit: 'pageSize'
    },
    Event: {
        start_time: 'startDate',
        end_time: 'endDate',
        condition: 'searchCondition',
        keyword: 'searchKeyword',
        current_page: 'page',
        limit: 'pageSize',
        standard: 'eventDateType'
    },
    Chart: {
        term: 'term',
        startDate: 'startDate',
        endDate: 'endDate'
    },
    Product: {
        condition: 'searchCondition',
        keyword: 'searchKeyword',
        current_page: 'page',
        limit: 'pageSize'
    },
    Retail: {
        condition: 'searchCondition',
        keyword: 'searchKeyword',
        current_page: 'page',
        limit: 'pageSize'
    }
};

export { DATE_FORMAT, SEARCH_COMPONENT, SEARCH_RADIO_ROW, SEARCH_SELECT, SEARCH_OPTION, SEARCH_CAPTION, SEARCH_PARAM };
