import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { TableCell } from '@mui/material';

import { menuSelector } from 'slices/menuSlice';
// import { setConfirm } from 'slices/modalSlice';

import useMessage from 'hooks/useMessage';

import TableButton from 'components/Table/Button';
import TableSelect from 'components/Table/Select';

function ListTable({ row, handleSelect }) {
    // TODO: 추후 데이터 연결 필요
    const { menu } = useSelector(menuSelector);
    // const dispatch = useDispatch();

    // const handleRetailDelete = () => dispatch(setConfirm());
    const handleMessage = useMessage();

    const tableSelectOptions = {
        useYn: [
            { label: '사용', value: 'Y' },
            { label: '미사용', value: 'N' }
        ]
    };

    const onConfirm = (index) => {
        if (index) {
            handleMessage({
                type: 'confirm',
                confirmType: 'retailDelete',
                message: '선택한 항목을 삭제합니다.',
                data: index
            });
        } else handleMessage({ message: '잘못된 접근입니다.' });
    };

    return (
        <>
            {menu === 'retail' && (
                <>
                    <TableCell width="70%" align="center">
                        {row.sel_nm || '-'}
                    </TableCell>
                    <TableCell width="15%" align="center">
                        <TableSelect
                            rowIndex={row.sel_idx}
                            name="use_yn"
                            value={row.use_yn}
                            label={row.use_yn === 'Y' ? '사용' : '미사용'}
                            options={tableSelectOptions.useYn}
                            handleSelect={handleSelect}
                        />
                    </TableCell>
                    <TableCell align="center" width="15%">
                        <TableButton pageType="delete" text="삭제" rowIndex={row.sel_idx} onClick={onConfirm} />
                    </TableCell>
                </>
            )}
        </>
    );
}

ListTable.propTypes = {
    row: PropTypes.object.isRequired,
    handleSelect: PropTypes.func.isRequired
};

export default ListTable;
