import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { modalSelector, setClose, setNoticeType } from 'slices/modalSlice';

import UseFormError from 'hooks/useFormError';

import { postData, putData } from 'api';
import { SubmitButton, DefaultButton } from 'components/Buttons';
import { Select, Input } from 'components/FormInputs';

import { SCHEMA } from 'configs/form';

import { StyledBox, StyledInputContainer, StyledLabel } from 'styles/customize/form';
import theme from 'styles/theme/form';

// 공지사항 추가 모달
function NoticeModal() {
    const queryClient = useQueryClient();
    const { noticeOpen, noticeData, noticeType } = useSelector(modalSelector);
    const defaultValues = {
        subject: '',
        use_yn: { value: 'Y', label: '사용' },
        contents: ''
    };
    const isAdd = noticeType === 'add';
    const isDetail = noticeType === 'detail';
    const isModify = noticeType === 'modify';

    const options = [
        { value: 'Y', label: '사용' },
        { value: 'N', label: '미사용' }
    ];
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(SCHEMA.Notice)
    });

    const dispatch = useDispatch();
    const { mutate } = useMutation('addNotice', ({ url, data }) => postData(url, data), {
        onSuccess: async () => {
            await queryClient.refetchQueries('notice');
            reset(defaultValues);
            dispatch(setClose());
        }
    });
    const { mutate: editNotice } = useMutation('editNotice', ({ url, data }) => putData(url, data), {
        onSuccess: async () => {
            await queryClient.refetchQueries('notice');
            reset(defaultValues);
            dispatch(setClose());
        }
    });

    const onClose = () => {
        reset(defaultValues);
        dispatch(setClose());
    };
    UseFormError(errors);
    const onSubmit = (data) => {
        if (noticeType === 'add') {
            mutate({
                url: 'web/notice',
                data: {
                    ...data,
                    use_yn: data.use_yn.value
                }
            });
        } else {
            editNotice({
                url: `web/notice/${noticeData.bd_idx}`,
                data: {
                    ...data,
                    use_yn: data.use_yn.value
                }
            });
        }
    };
    const typeHandler = () => {
        dispatch(setNoticeType('modify'));
        reset({
            subject: noticeData?.subject,
            use_yn: noticeData?.use_yn === 'Y' ? { value: 'Y', label: '사용' } : { value: 'N', label: '미사용' },
            contents: noticeData?.contents
        });
    };

    return (
        <Dialog
            open={noticeOpen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose(event, reason);
                }
            }}
            sx={{
                '& .MuiPaper-root': {
                    minWidth: 1145,
                    minHeight: 462,
                    maxWidth: '100%'
                }
            }}
        >
            <ThemeProvider theme={theme}>
                <DialogTitle>공지사항 {isModify ? '수정' : isAdd ? '등록' : ''}</DialogTitle>
                <DialogContent sx={{ height: '100%', paddingBottom: 0 }}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <StyledBox display="grid" gridTemplateColumns="200px 1fr">
                            <StyledLabel>
                                <Typography variant="inputLabel">제목</Typography>
                            </StyledLabel>
                            <StyledInputContainer>
                                {isDetail ? (
                                    <Typography>{noticeData?.subject}</Typography>
                                ) : (
                                    <Input placeholder="제목을 입력해주세요" name="subject" control={control} />
                                )}
                            </StyledInputContainer>
                            <StyledLabel>
                                <Typography variant="inputLabel">사용여부</Typography>
                            </StyledLabel>
                            <StyledInputContainer sx={{ '&& > *': { width: '110px' } }}>
                                {isDetail ? (
                                    <Typography>{noticeData?.use_yn === 'Y' ? '사용' : '미사용'}</Typography>
                                ) : (
                                    <Select name="use_yn" control={control} options={options} />
                                )}
                            </StyledInputContainer>
                            <StyledLabel sx={{ height: '100%', minHeight: '200px' }}>
                                <Typography variant="inputLabel">내용</Typography>
                            </StyledLabel>
                            <StyledInputContainer sx={{ height: '100%', display: 'block' }}>
                                {isDetail ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            height: '100%',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography>{noticeData?.contents}</Typography>
                                    </Box>
                                ) : (
                                    <Input
                                        placeholder="내용을 입력해주세요"
                                        name="contents"
                                        multiline
                                        rows={10}
                                        control={control}
                                    />
                                )}
                            </StyledInputContainer>
                        </StyledBox>
                        <Grid sx={{ py: 2 }} container justifyContent="flex-end" alignItems="center" gap="10px">
                            <Grid item xs="auto">
                                <DefaultButton icon="cancel" onClick={onClose} text="취소" />
                            </Grid>
                            <Grid item xs="auto">
                                {isDetail ? (
                                    <DefaultButton onClick={typeHandler} text="수정" />
                                ) : (
                                    <SubmitButton type="submit" text={isAdd ? '등록' : '수정'} />
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </ThemeProvider>
        </Dialog>
    );
}
export default NoticeModal;
