import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { TableCell, Typography } from '@mui/material';

import { setEvent } from 'slices/modalSlice';

import { formatDate } from 'utils';

function ListTable({ row }) {
    const { menu } = useSelector((state) => state.menu);
    const dispatch = useDispatch();

    const onEventListClick = () => {
        dispatch(setEvent({ open: true, data: row, type: 'detail' }));
    };

    return (
        <>
            {menu === 'event' && (
                <>
                    <TableCell align="center">
                        <Typography>{row.evt_type === 'U' ? '링크' : '이미지'}</Typography>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} align="center" onClick={onEventListClick}>
                        {row.evt_nm || '-'}
                    </TableCell>
                    <TableCell align="center">{row.com_nm || '-'}</TableCell>
                    <TableCell align="center">{row.count || '0'}</TableCell>
                    <TableCell align="center">{row.use_yn === 'Y' ? '사용' : '미사용' || '-'}</TableCell>
                    <TableCell align="center">{formatDate('time', row.evt_s_dt) || '-'}</TableCell>
                    <TableCell align="center">{formatDate('time', row.evt_e_dt) || '-'}</TableCell>
                    <TableCell align="center">{formatDate('time', row.reg_dt) || '-'}</TableCell>
                </>
            )}
        </>
    );
}

ListTable.propTypes = {
    row: PropTypes.object.isRequired
};

export default ListTable;
