import React, { useCallback, useEffect } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setConfirmed, setConfirmReset } from 'slices/modalSlice';

import useMenu from 'hooks/useMenu';
import useMessage from 'hooks/useMessage';

import { deleteData, getData, patchData, postData } from 'api';

import { searchSelector } from '../../slices/searchSlice';
import UserTable from './components/UserTable';

export default function User() {
    const history = useHistory();
    const admin = localStorage.getItem('adminType');

    const menu = useMenu({ page: 'User', menu: 'user', menuTitle: '계정 관리', menuNum: 5 }); // 페이지/메뉴 설정하기

    const { pageSize, page } = useSelector(searchSelector);
    const {
        isLoading,
        data: dataList,
        refetch: refetchList
    } = useQuery(
        [menu, page, pageSize],
        () => getData('/web/admin/lists', { limit: pageSize, current_page: page - 1 }),
        {
            onError: async ({ response }) => {
                if (response.statusCode === 401) {
                    history.replace('/');
                    await localStorage.setItem('adminType', 'A');
                }
            }
        }
    ); // 리스트 데이터 가져오기

    const { data, isConfirmed, confirmType, state } = useSelector((state) => state.modal);
    const dispatch = useDispatch();
    const handleMessage = useMessage();

    const confirmMessageType = (type, state) => {
        if (type === 'viewType') {
            return state === 'Y'
                ? '선택한 계정의 개인정보열람 권한을 삭제하였습니다'
                : '개인정보 열람 가능자로 등록되었습니다.';
        }
        if (type === 'userStatusChange') {
            return state === '2' ? '선택한 계정의 접근 권한을 삭제하였습니다.' : '하위관리자로 등록되었습니다';
        }
        if (type === 'masterType') {
            return '상위관리자가 변경되었습니다.';
        }
        if (type === 'userDelete') {
            return '삭제되었습니다.';
        }
        return '';
    };

    const { mutate: deleteMutate } = useMutation(({ url, data }) => deleteData(url, data), {
        onSuccess: async () => {
            dispatch(setConfirmReset());
            handleMessage({ message: confirmMessageType(confirmType) });
            await refetchList();
        },
        onError: (error) => handleMessage({ type: 'message', ...error })
    });

    const { mutate: patchMutate } = useMutation(({ url, body }) => patchData(url, body), {
        onSuccess: () => {
            dispatch(setConfirmReset());
            handleMessage({ message: confirmMessageType(confirmType, state) });

            if (admin === 'M') refetchList();
        },
        onError: (error) => handleMessage({ type: 'message', ...error })
    });

    // 개인정보 다루는 메뉴이므로 DB 로그 남기기 위한 Post 요청
    const { mutate: logForPrivacyData } = useMutation(({ url, data, fileYn }) => postData(url, data, fileYn), {
        onError: (error) => handleMessage({ type: 'message', ...error })
    });

    const handleConfirm = useCallback(() => {
        dispatch(setConfirmed());
        if (confirmType === 'userDelete') {
            deleteMutate({ url: `web/admin/${data}` });
        } else if (confirmType === 'userStatusChange') {
            patchMutate({ url: `web/admin/state/${data}` });
        } else if (confirmType === 'masterType') {
            patchMutate({ url: `web/admin/type/${data}` });
        } else if (confirmType === 'viewType') {
            patchMutate({ url: `web/admin/masking/${data}`, body: { masking_flag: state === 'Y' ? 'N' : 'Y' } });
        }
    }, [dispatch, confirmType, deleteMutate, patchMutate]);

    useEffect(() => {
        if (isConfirmed) {
            handleConfirm();
        }
    }, [isConfirmed, handleConfirm]);

    useEffect(() => {
        logForPrivacyData({ url: 'web/admin/log?menu_name=계정관리' });
    }, []);

    return (
        <>
            <UserTable isLoading={isLoading} data={dataList?.adminLists} total={dataList?.totalCount} />
        </>
    );
}
