import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledListItem = styled(ListItemButton)(({ theme: { palette } }) => ({
    width: '90%',
    height: 30,
    margin: '5px auto 0',
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: palette.primary.main,
    borderRadius: 4,
    '&&:hover': {
        borderRadius: 4,
        backgroundColor: palette.background.main,
        '& p': {
            color: palette.text.primary
        },
        '& svg': {
            color: palette.text.primary
        }
    },
    '& svg': {
        minWidth: 20,
        color: palette.neutral.white,
        width: 20,
        height: 20
    },
    '& p': {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '-0.45px',
        textAlign: 'left',
        color: palette.neutral.white,
        opacity: 0.7
    }
}));

function MenuItem({ collapsed, open, selected, icon, menuTitle, path, onClick }) {
    return path ? (
        <StyledListItem disableRipple selected={selected} component={Link} to={path} onClick={onClick}>
            <ListItemText
                sx={{ display: 'flex', justifyContent: 'center' }}
                primary={
                    <Typography component="p" variant="h5">
                        {menuTitle}
                    </Typography>
                }
            />
            {collapsed && (open ? <ExpandLess /> : <ExpandMore />)}
        </StyledListItem>
    ) : (
        <StyledListItem disableRipple selected={selected} onClick={onClick}>
            <ListItemIcon>
                <ListItemIcon>{icon}</ListItemIcon>
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography component="p" variant="h5">
                        {menuTitle}
                    </Typography>
                }
            />
            {collapsed && (open ? <ExpandLess /> : <ExpandMore />)}
        </StyledListItem>
    );
}

MenuItem.propTypes = {
    collapsed: PropTypes.bool,
    open: PropTypes.bool,
    selected: PropTypes.bool.isRequired,
    icon: PropTypes.object,
    menuTitle: PropTypes.string.isRequired,
    path: PropTypes.string,
    onClick: PropTypes.func.isRequired
};

MenuItem.defaultProps = {
    collapsed: false,
    open: false,
    path: '',
    icon: null
};

export default MenuItem;
