import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { TableCell, Typography, Box } from '@mui/material';

import useMessage from 'hooks/useMessage';

import TableButton from 'components/Table/Button';

import { formatDate } from 'utils';

function ListTable({ row }) {
    const { menu } = useSelector((state) => state.menu);
    const handleMessage = useMessage();
    const history = useHistory();

    const onModify = (idx) => {
        history.push(`/modifyPolicy/${idx}`);
    };

    const onDelete = (name, idx) => {
        handleMessage({
            type: 'confirm',
            confirmType: 'policyDelete',
            message: `해당 '${name}' 정책을 삭제 하시겠습니까?`,
            deletingIdx: idx
        });
    };

    return (
        <>
            {menu === 'policy' && (
                <>
                    <TableCell align="center">
                        <Box onClick={() => history.push(`/viewPolicy/${row.policy_idx}`)}>
                            <Typography variant="underlined">
                                {row.policy_type === 'P' ? '개인정보 처리방침' : '서비스 이용약관' || '-'}
                            </Typography>
                        </Box>
                    </TableCell>
                    <TableCell align="center">{formatDate('daily', new Date(row.start_dt)) || '-'}</TableCell>
                    <TableCell align="center">{formatDate('daily', new Date(row.end_dt)) || '-'}</TableCell>
                    <TableCell align="center">
                        <TableButton
                            rowIndex={row.policy_idx}
                            pageType="edit"
                            text="수정"
                            onClick={() => onModify(row.policy_idx)}
                        />
                        <TableButton
                            rowIndex={row.policy_idx}
                            pageType="edit"
                            text="삭제"
                            onClick={() =>
                                onDelete(
                                    row.policy_type === 'P' ? '개인정보 처리방침' : '서비스 이용약관',
                                    row.policy_idx
                                )
                            }
                        />
                    </TableCell>
                </>
            )}
        </>
    );
}

ListTable.propTypes = {
    row: PropTypes.object.isRequired
};

export default ListTable;
