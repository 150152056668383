import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { modalSelector, setClose } from 'slices/modalSlice';

import useMessage from 'hooks/useMessage';

import { postData, putData } from 'api';
import { SubmitButton, DefaultButton } from 'components/Buttons';
import { Select, Input } from 'components/FormInputs';

import { SCHEMA } from 'configs/form';

import { StyledBox, StyledInputContainer, StyledLabel } from 'styles/customize/form';
import theme from 'styles/theme/form';

import { isEmpty } from 'utils';

const emailAddress = [
    { value: '', label: '이메일을 선택해주세요' },
    { value: 'blockodyssey.io', label: 'blockodyssey.io' },
    { value: 'gmail.com', label: 'gmail.com' },
    { value: 'naver.com', label: 'naver.com' },
    { value: 'hanmail.net', label: 'hanmail.net' },
    { value: 'nate.com', label: 'nate.com' },
    { value: 'outlook.com', label: 'outlook.com' },
    { value: 'daum.net', label: 'daum.net' }
];

const defaultValues = {
    mn_nm: '',
    admin_id: '',
    admin_pw: '',
    mn_tel: '',
    mn_email: '',
    email_domain: { value: '', label: '이메일을 선택해주세요' }
};

function UserModal() {
    const { userOpen, userData, userType } = useSelector(modalSelector);
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const handleMessage = useMessage();
    const isModify = userType === 'modify';

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(SCHEMA.User)
    });

    const { mutate: addUser } = useMutation(({ url, data }) => postData(url, data), {
        onSuccess: () => {
            queryClient.refetchQueries('User');
            reset(defaultValues);
            dispatch(setClose());
        },
        onError: (error) => {
            alert(error.message);
        }
    });

    const { mutate: editUser } = useMutation('editUser', ({ url, data }) => putData(url, data), {
        onSuccess: () => {
            queryClient.refetchQueries('User');
            reset(defaultValues);
            dispatch(setClose());
        },
        onError: (error) => handleMessage({ type: 'message', ...error })
    });

    const onSubmit = (data) => {
        const email = `${data.mn_email}@${data.email_domain.value}`;

        if (isModify) {
            editUser({
                url: `web/admin/${userData.admin_idx}`,
                data: {
                    mn_nm: data.mn_nm,
                    admin_pw: data.admin_pw,
                    admin_id: data.admin_id,
                    mn_tel: data.mn_tel,
                    mn_email: email
                }
            });
        } else {
            addUser({
                url: '/web/admin',
                data: {
                    admin_id: data.admin_id,
                    admin_pw: data.admin_pw,
                    mn_nm: data.mn_nm,
                    mn_tel: data.mn_tel,
                    mn_email: email
                }
            });
        }
    };

    const onClose = () => {
        dispatch(setClose());
        reset(defaultValues);
    };

    useEffect(() => {
        if (!isEmpty(errors)) {
            if (Object.values(errors).length !== 0) {
                const array = Object.values(errors);
                if (Object.prototype.hasOwnProperty.call(array[0], 'message')) {
                    alert(array[0].message);
                } else {
                    alert(array[0].value.message);
                }
            }
        }
    }, [errors]);

    useEffect(() => {
        if (userOpen && !isEmpty(userData) && isModify) {
            const emailDomain = emailAddress.findIndex((user) => user.value === userData.mn_email?.split('@')[1]);

            reset({
                mn_nm: userData.mn_nm,
                admin_id: userData.admin_id,
                mn_tel: userData.mn_tel,
                mn_email: userData.mn_email?.split('@')[0] || '',
                email_domain: emailAddress[emailDomain] || defaultValues.email_domain
            });
        }
    }, [userOpen, userData, isModify, reset]);

    return (
        <Dialog
            open={userOpen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose(event, reason);
                }
            }}
            sx={{
                '& .MuiPaper-root': {
                    overflow: 'visible',
                    minWidth: 1145,
                    minHeight: 462,
                    maxWidth: '100%'
                }
            }}
        >
            <ThemeProvider theme={theme}>
                <DialogTitle>관리자 {isModify ? '수정' : '등록'}</DialogTitle>
                <DialogContent sx={{ height: '100%', paddingBottom: 0, overflow: 'inherit' }}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <StyledBox display="grid" gridTemplateColumns="200px 1fr">
                            <StyledLabel>
                                <Typography variant="inputLabel">관리자 이름</Typography>
                            </StyledLabel>

                            <StyledInputContainer>
                                <Input placeholder="홍길동" name="mn_nm" control={control} />
                            </StyledInputContainer>
                            <StyledLabel>
                                <Typography variant="inputLabel">아이디</Typography>
                            </StyledLabel>

                            <StyledInputContainer>
                                <Input placeholder="-" name="admin_id" control={control} />
                            </StyledInputContainer>
                            <StyledLabel>
                                <Typography variant="inputLabel">비밀번호</Typography>
                            </StyledLabel>
                            <StyledInputContainer>
                                <Input placeholder="-" name="admin_pw" inputType="password" control={control} />
                            </StyledInputContainer>

                            <StyledLabel>
                                <Typography variant="inputLabel">전화번호</Typography>
                            </StyledLabel>
                            <StyledInputContainer>
                                <Input placeholder="-" name="mn_tel" inputType="text" control={control} />
                            </StyledInputContainer>
                            <StyledLabel>
                                <Typography variant="inputLabel">이메일</Typography>
                            </StyledLabel>
                            <StyledInputContainer>
                                <Input placeholder="email" name="mn_email" control={control} /> @
                                <Select name="email_domain" control={control} options={emailAddress} />
                            </StyledInputContainer>
                        </StyledBox>
                        <Grid sx={{ py: 2, gap: '10px' }} container justifyContent="flex-end" alignItems="center">
                            <Grid item xs="auto">
                                <DefaultButton gap={10} icon="cancel" onClick={onClose} text="취소" />
                            </Grid>
                            <Grid item xs="auto">
                                <SubmitButton type="submit" text={isModify ? '수정' : '등록'} />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </ThemeProvider>
        </Dialog>
    );
}
export default UserModal;
