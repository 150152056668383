import React from 'react';

import useGetList from 'hooks/useGetList';
import useMenu from 'hooks/useMenu';

import { Search } from 'components';

import ScanTable from './components/ScanTable';

export default function Scan() {
    const menu = useMenu({ page: 'Scan', menu: 'scan', menuTitle: '스캔하기', menuNum: 2 }); // 페이지/메뉴 설정하기
    const { isLoading, data: dataList } = useGetList({ menu, url: '/web/scan/lists' }); // 리스트 데이터 가져오기

    return (
        <>
            <Search path="/scan" total={dataList?.totalCount} />
            <ScanTable isLoading={isLoading} data={dataList?.scanLists} total={dataList?.totalCount} />
        </>
    );
}
