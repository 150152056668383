import React from 'react';

import PropTypes from 'prop-types';

import { Button, Typography } from '@mui/material';

function PageHeading({ text, buttonText, type, disabled, onClick }) {
    return type === 'button' ? (
        <Typography
            sx={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
            variant="h3"
            component="h4"
        >
            {text}
            <Button
                sx={{ marginLeft: '20px' }}
                disabled={disabled}
                size="small"
                color="primary"
                variant="outlined"
                onClick={onClick}
            >
                {buttonText}
            </Button>
        </Typography>
    ) : (
        <Typography
            sx={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
            variant="h3"
            component="h4"
        >
            {text}
        </Typography>
    );
}

PageHeading.propTypes = {
    buttonText: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

PageHeading.defaultProps = {
    buttonText: '',
    disabled: false,
    onClick: () => {}
};

export default PageHeading;
