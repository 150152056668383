import React, { useEffect } from 'react';

import { useQuery, useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { modalSelector, setConfirmReset } from 'slices/modalSlice';
import { searchSelector } from 'slices/searchSlice';

import useMenu from 'hooks/useMenu';

import { getData, deleteData } from 'api';

import PolicyTable from './components/PolicyTable';

export default function Policy() {
    const { pageSize, page } = useSelector(searchSelector);
    const dispatch = useDispatch();
    const menu = useMenu({ page: 'Policy', menu: 'policy', menuTitle: '약관 및 방침 관리', menuNum: 10 }); // 페이지/메뉴 설정하기

    const { confirmType, isConfirmed, deletingIdx } = useSelector(modalSelector);

    const {
        data: dataList,
        isLoading,
        refetch
    } = useQuery(
        ['getPolicyList', menu, page, pageSize],
        () => getData('web/policy/lists', { limit: pageSize, current_page: page - 1 }),
        {
            onError: (error) => {
                console.log('error:', error);
            }
        }
    );

    const { mutate: deletePolicy } = useMutation(
        ['deletePolicy', deletingIdx],
        (body) => deleteData(`/web/policy/${deletingIdx}`, body),
        {
            onSuccess: () => {
                // 정책 삭제 성공 후 컨펌 관련 state 초기화
                dispatch(setConfirmReset());
                refetch();
            },
            onError: (err) => {
                console.log('err:', err);
            }
        }
    );

    useEffect(() => {
        if (isConfirmed && confirmType === 'policyDelete' && deletingIdx) {
            deletePolicy({ idx: deletingIdx });
        }
    }, [confirmType, isConfirmed, deletingIdx]);

    return <PolicyTable isLoading={isLoading} data={dataList?.policyLists} total={dataList?.totalCount} />;
}
