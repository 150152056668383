import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MuiPagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

// import useSearch from 'hooks/useSearch';

import { setBrand, setEvent, setUser, setNotice, setProduct, setRetail } from 'slices/modalSlice';

import { DefaultButton } from 'components/Buttons';

function Pagination({ count, page, rowsPerPage, onPageChange, onRowsPerPageChange, onDelete, row, companyList }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const totalCount = Math.ceil(count / rowsPerPage);

    const { page: menu } = useSelector((state) => state.menu);

    const onChange = (_, value) => onPageChange(value); // 페이지 번호 변경하기

    const onEventClick = () => {
        dispatch(setEvent({ open: true, data: null, type: 'add' }));
    };
    const onNoticeClick = () => {
        dispatch(setNotice({ open: true, data: null, type: 'add' }));
    };
    const onUserClick = () => {
        dispatch(setUser({ open: true, data: null }));
    };
    const onBrandClick = () => {
        dispatch(setBrand({ open: true, data: row, company: companyList, type: 'add' }));
    };
    const onProductClick = () => {
        dispatch(setProduct({ open: true, data: null }));
    };
    const onRetailClick = () => {
        dispatch(setRetail({ open: true, data: null }));
    };
    return (
        <Box
            minHeight={52}
            p="20px"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            columnGap="20px"
            position="relative"
        >
            {count > 0 && (
                <Box sx={{ display: 'flex', left: 20, position: 'absolute' }}>
                    <Typography component="p" variant="body2">
                        페이지 당 행:
                    </Typography>
                    <Select
                        sx={{ '&&&& *': { border: 0, borderColor: 'unset' }, '& > div': { p: 0 } }}
                        label=""
                        id="rows per page"
                        value={rowsPerPage}
                        onChange={onRowsPerPageChange}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                    전체 : {count}
                </Box>
            )}
            {count > 0 && (
                <MuiPagination
                    sx={{
                        // width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative'
                    }}
                    color="primary"
                    shape="rounded"
                    variant="outlined"
                    showFirstButton
                    showLastButton
                    count={totalCount}
                    page={page + 1} // 1부터 시작
                    onChange={onChange}
                />
            )}
            {menu === 'User' && (
                <div style={{ display: 'flex', marginRight: 20, gap: 10, position: 'absolute', top: 'auto', right: 0 }}>
                    <DefaultButton pageType="edit" text="관리자 추가" height="20px" onClick={onUserClick} />
                </div>
            )}
            {menu === 'Brand' && (
                <div style={{ display: 'flex', marginRight: 20, gap: 10, position: 'absolute', top: 'auto', right: 0 }}>
                    {count > 0 && <DefaultButton pageType="edit" text="삭제" height="20px" onClick={onDelete} />}
                    <DefaultButton size="large" text="브랜드 추가" onClick={onBrandClick} />
                </div>
            )}
            {menu === 'Notice' && (
                <div style={{ display: 'flex', marginRight: 20, gap: 10, position: 'absolute', top: 'auto', right: 0 }}>
                    {count > 0 && <DefaultButton pageType="edit" text="삭제" height="20px" onClick={onDelete} />}
                    <DefaultButton size="large" text="공지추가" onClick={onNoticeClick} />
                </div>
            )}
            {menu === 'Event' && (
                <div
                    style={{
                        display: 'flex',
                        marginRight: 20,
                        gap: 10,
                        position: 'absolute',
                        top: 'auto',
                        right: 0
                    }}
                >
                    <DefaultButton size="large" text="이벤트 추가" onClick={onEventClick} />
                </div>
            )}

            {menu === 'Policy' && (
                <div style={{ display: 'flex', marginRight: 20, gap: 10, position: 'absolute', top: 'auto', right: 0 }}>
                    <DefaultButton size="large" text="+ 약관 및 방침 등록" onClick={() => history.push('addPolicy')} />
                </div>
            )}
            {menu === 'Product' && (
                <div style={{ display: 'flex', marginRight: 20, gap: 10, position: 'absolute', top: 'auto', right: 0 }}>
                    {count > 0 && <DefaultButton pageType="edit" text="삭제" height="20px" onClick={onDelete} />}
                    <DefaultButton size="large" text="제품 추가" onClick={onProductClick} />
                </div>
            )}
            {menu === 'Retail' && (
                <div style={{ display: 'flex', marginRight: 20, gap: 10, position: 'absolute', top: 'auto', right: 0 }}>
                    {count > 0 && <DefaultButton pageType="edit" text="삭제" height="20px" onClick={onDelete} />}
                    <DefaultButton size="large" text="판매처 추가" onClick={onRetailClick} />
                </div>
            )}
        </Box>
    );
}

Pagination.propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    companyList: PropTypes.array,
    row: PropTypes.object
};
Pagination.defaultProps = {
    onDelete: () => null,
    rowsPerPage: 10,
    companyList: [],
    row: {}
};

export default Pagination;
