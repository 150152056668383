import React, { useEffect } from 'react';

import { useMutation } from 'react-query';

import useGetList from 'hooks/useGetList';
import useMenu from 'hooks/useMenu';
import useMessage from 'hooks/useMessage';

import { postData } from 'api';
import { Search } from 'components';

import SignupTable from './components/SignupTable';

export default function SignUp() {
    const menu = useMenu({ page: 'Signup', menu: 'signup', menuTitle: '회원가입', menuNum: 1 }); // 페이지/메뉴 설정하기
    const { isLoading, data: dataList } = useGetList({ menu, url: '/web/user/lists' }); // 리스트 데이터 가져오기

    const handleMessage = useMessage();
    // 개인정보 다루는 메뉴이므로 DB 로그 남기기 위한 Post 요청
    const { mutate: logForPrivacyData } = useMutation(({ url, data, fileYn }) => postData(url, data, fileYn), {
        onError: (error) => handleMessage({ type: 'message', ...error })
    });

    useEffect(() => {
        logForPrivacyData({ url: 'web/admin/log?menu_name=회원가입' });
    }, []);

    return (
        <>
            <Search path="/sign-up" heading="" total={dataList?.totalCount} />

            <SignupTable isLoading={isLoading} data={dataList?.userLists} total={dataList?.totalCount} />
        </>
    );
}
