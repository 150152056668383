import React from 'react';

import PropTypes from 'prop-types';

import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography
} from '@mui/material';

import Map from 'assets/img/img_map.svg';

import ChartTitle from './ChartTitle';

const rows = [
    {
        name: '한국',
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4
    },
    {
        name: '중국',
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4
    },
    {
        name: '일본',
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4
    },
    {
        name: '대만',
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4
    },
    {
        name: '홍콩',
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4
    },
    {
        name: '베트남',
        value1: 1,
        value2: 2,
        value3: 3,
        value4: 4
    }
];

const MapItem = ({ title, baseTop, baseLeft, titleTop, titleLeft, titleWidth, mapData, isLeft = false }) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: baseTop,
                left: baseLeft
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    width: '4px',
                    height: '4px',
                    backgroundColor: 'red',
                    borderRadius: '2px',
                    zIndex: 1
                }}
            />
            <Typography
                variant="body2"
                sx={{
                    position: 'absolute',
                    fontSize: '10px',
                    top: titleTop,
                    left: titleLeft,
                    width: titleWidth,
                    backgroundColor: '#ffffff',
                    zIndex: 1
                }}
            >
                {title}
            </Typography>
            {mapData && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '-16px',
                        left: isLeft ? '-110px' : '10px',
                        width: '100px',
                        height: '30px',
                        zIndex: 3,
                        display: 'flex',
                        flexDirection: 'row',
                        border: '2px solid #003399',
                        backgroundColor: '#ffffff',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': {
                            zIndex: 99
                        }
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: '12px',
                            backgroundColor: '#ffffff'
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: '12px',
                            backgroundColor: '#ffffff',
                            color: 'red',
                            marginLeft: '3px'
                        }}
                    >
                        {mapData}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

MapItem.propTypes = {
    title: PropTypes.string,
    baseTop: PropTypes.string,
    baseLeft: PropTypes.string,
    titleTop: PropTypes.string,
    titleLeft: PropTypes.string,
    titleWidth: PropTypes.string,
    mapData: PropTypes.string,
    isLeft: PropTypes.bool
};

MapItem.defaultProps = {
    title: '',
    baseTop: '',
    baseLeft: '',
    titleTop: '',
    titleLeft: '',
    titleWidth: '20px',
    mapData: undefined,
    isLeft: false
};

const MapHandler = ({ mapData }) => {
    return (
        <Box sx={{ position: 'relative' }}>
            <MapItem
                title="경기도"
                baseTop="75px"
                baseLeft="120px"
                titleTop="-7px"
                titleLeft="10px"
                titleWidth="30px"
                mapData={mapData['경기도']}
            />
            <MapItem
                title="인천광역시"
                baseTop="64px"
                baseLeft="97px"
                titleTop="-7px"
                titleLeft="-50px"
                titleWidth="45px"
                mapData={mapData['인천광역시']}
                isLeft
            />
            <MapItem
                title="강원도"
                baseTop="44px"
                baseLeft="170px"
                titleTop="-7px"
                titleLeft="10px"
                titleWidth="45px"
                mapData={mapData['강원도']}
            />
            <MapItem
                title="충청남도"
                baseTop="120px"
                baseLeft="100px"
                titleTop="-7px"
                titleLeft="-40px"
                titleWidth="37px"
                mapData={mapData['충청남도']}
                isLeft
            />
            <MapItem
                title="세종시"
                baseTop="116px"
                baseLeft="120px"
                titleTop="-7px"
                titleLeft="10px"
                titleWidth="26px"
                mapData={mapData['세종시']}
                isLeft
            />
            <MapItem
                title="대전광역시"
                baseTop="125px"
                baseLeft="130px"
                titleTop="0px"
                titleLeft="10px"
                titleWidth="45px"
                mapData={mapData['대전광역시']}
            />
            <MapItem
                title="충청북도"
                baseTop="95px"
                baseLeft="150px"
                titleTop="-7px"
                titleLeft="10px"
                titleWidth="35px"
                mapData={mapData['충청북도']}
            />
            <MapItem
                title="경상남도"
                baseTop="120px"
                baseLeft="190px"
                titleTop="-7px"
                titleLeft="10px"
                titleWidth="35px"
                mapData={mapData['경상남도']}
            />
            <MapItem
                title="대구광역시"
                baseTop="152px"
                baseLeft="190px"
                titleTop="-7px"
                titleLeft="10px"
                titleWidth="45px"
                mapData={mapData['대구광역시']}
            />
            <MapItem
                title="전라남도"
                baseTop="160px"
                baseLeft="120px"
                titleTop="5px"
                titleLeft="-15px"
                titleWidth="35px"
                mapData={mapData['전라남도']}
                isLeft
            />
            <MapItem
                title="전라북도"
                baseTop="210px"
                baseLeft="110px"
                titleTop="5px"
                titleLeft="-15px"
                titleWidth="35px"
                mapData={mapData['전라북도']}
                isLeft
            />
            <MapItem
                title="광주광역시"
                baseTop="190px"
                baseLeft="107px"
                titleTop="-7px"
                titleLeft="-50px"
                titleWidth="45px"
                mapData={mapData['광주광역시']}
                isLeft
            />
            <MapItem
                title="경상남도"
                baseTop="175px"
                baseLeft="165px"
                titleTop="5px"
                titleLeft="-15px"
                titleWidth="35px"
                mapData={mapData['경상남도']}
            />
            <MapItem
                title="울산광역시"
                baseTop="173px"
                baseLeft="217px"
                titleTop="-7px"
                titleLeft="10px"
                titleWidth="45px"
                mapData={mapData['울산광역시']}
            />
            <MapItem
                title="부산"
                baseTop="192px"
                baseLeft="210px"
                titleTop="8px"
                titleLeft="-8px"
                titleWidth="20px"
                mapData={mapData['부산']}
            />
            <MapItem
                title="제주"
                baseTop="287px"
                baseLeft="98px"
                titleTop="-7px"
                titleLeft="10px"
                titleWidth="20px"
                mapData={mapData['제주']}
            />
            <MapItem
                title="서울특별시"
                baseTop="60px"
                baseLeft="114px"
                titleTop="-20px"
                titleLeft="0"
                titleWidth="45px"
                mapData={mapData['서울특별시']}
            />
        </Box>
    );
};

MapHandler.propTypes = {
    mapData: PropTypes.object
};

MapHandler.defaultProps = {
    mapData: {}
};

const mapDummy = {
    서울특별시: '8%',
    경기도: '7%',
    인천광역시: '3%',
    강원도: '5%',
    충청남도: '9%',
    대전광역시: '20%',
    부산: '5%',
    전라북도: '10%'
};

function MapChart({ mapLoading, mapChartData }) {
    console.log('mapChartData', mapChartData);
    return mapLoading ? (
        <Skeleton variant="rectangular" width="100%" height={500} />
    ) : (
        <>
            <ChartTitle title="지역별 Summary" isRight={false} />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <Box>
                    <MapHandler mapData={mapDummy} />
                    <img height="300px" width="300px" alt="" src={Map} />
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" sx={{ '&&& *': { height: '30px' } }}>
                    <TableHead sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                        <TableRow sx={{ borderBottom: '2px solid black' }}>
                            <TableCell sx={{ width: '24%' }}>브랜드</TableCell>
                            <TableCell sx={{ width: '19%' }} align="center">
                                합계
                            </TableCell>
                            <TableCell sx={{ width: '19%' }} align="center">
                                정상
                            </TableCell>
                            <TableCell sx={{ width: '19%' }} align="center">
                                비유통
                            </TableCell>
                            <TableCell sx={{ width: '19%' }} align="center">
                                퍼센트
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 }
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="center">{row.value1}</TableCell>
                                <TableCell align="center">{row.value2}</TableCell>
                                <TableCell align="center">{row.value3}</TableCell>
                                <TableCell align="center" sx={{ color: 'red' }}>
                                    {row.value4}%
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

MapChart.propTypes = {
    mapLoading: PropTypes.bool,
    mapChartData: PropTypes.arrayOf(PropTypes.object)
};

MapChart.defaultProps = {
    mapLoading: false,
    mapChartData: []
};

export default MapChart;
