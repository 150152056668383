import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Add as AddIcon, Close as CloseIcon, Image as ImageIcon } from '@mui/icons-material';
import { Grid, Box, IconButton } from '@mui/material';

import useMessage from 'hooks/useMessage';

import { UploadImageCarousel } from 'components/Carousels';
import PageHeading from 'layouts/PageHeading';

import { StyledDefaultImage, StyledImageContainer, StyledSmallerImage } from 'styles/customize/page';

import { getMessageText } from 'utils';

function UploadImage({ text, imageFiles, setImageFiles, onConfirm, handleDeleteImageFile }) {
    const { idx } = useParams();

    const handleMessage = useMessage();

    const [imgError, setImgError] = useState({});
    const [imageIndex, setImageIndex] = useState(0);
    const [imageLoading, setImageLoading] = useState(false); // 이미지 업로드 로딩 상태

    // input[type="file"]로 이미지 파일 업로드
    // imageFiles로 업로드한 파일 저장하기
    const handleImageFile = async (e) => {
        try {
            setImageLoading(true);

            const { files } = e.target;
            for (const file of files) {
                // 이미지 preview 만들기
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImageFiles((prevState) => [
                        ...prevState,
                        {
                            file,
                            preview: reader.result
                        }
                    ]);
                };
                reader.readAsDataURL(file);
            }

            setImageLoading(false);
        } catch (error) {
            handleMessage({ message: getMessageText('upload image') });
        } finally {
            setImageLoading(false);
        }
    };

    const handleDelete = (imageIndex) => {
        setImageIndex(0);
        handleDeleteImageFile(imageIndex);
    };

    // 작은 이미지 클릭
    const onImageClick = (index) => setImageIndex(index);

    return (
        <StyledImageContainer>
            <PageHeading type="default" text={text} />
            <UploadImageCarousel imageFiles={imageFiles} imageIndex={imageIndex} setImageIndex={setImageIndex} />
            <Grid
                sx={{
                    width: 500,
                    margin: '20px auto',
                    '& img:last-of-type': { margin: 0 }
                }}
                container
                justifyContent="flex-start"
                alignItems="center"
                gap="20px"
            >
                {imageFiles?.map((image, index) => (
                    <React.Fragment key={index}>
                        {image && (
                            <Box
                                sx={{
                                    position: 'relative',
                                    '& > button': {
                                        position: 'absolute',
                                        bottom: '-10px',
                                        right: '-10px',
                                        backgroundColor: 'neutral.black',
                                        color: 'neutral.white',
                                        padding: '5px',
                                        '& svg': {
                                            width: 12,
                                            height: 12
                                        },
                                        '&:hover': {
                                            background: 'neutral.black'
                                        },
                                        '&:active': {
                                            transform: 'scale(0.98)'
                                        }
                                    }
                                }}
                                width={70}
                                height={70}
                                role="button"
                                tabIndex={0}
                                onClick={() => onImageClick(index)}
                                onKeyDown={() => onImageClick(index)}
                            >
                                {imgError[index] ? (
                                    <StyledDefaultImage sx={{ width: 70 }}>
                                        <ImageIcon />
                                    </StyledDefaultImage>
                                ) : (
                                    <StyledSmallerImage
                                        width="100%"
                                        height={70}
                                        onError={() => setImgError((prev) => ({ ...prev, [index]: true }))}
                                        src={image.preview}
                                        alt=""
                                    />
                                )}
                                {idx && image.idx ? (
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => onConfirm('delete', { imageIndex: image.idx, index })}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton aria-label="delete" onClick={() => handleDelete(index)}>
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </Box>
                        )}
                    </React.Fragment>
                ))}
                {/* 다중, 중복 이미지 업로드 */}
                <Box component="label" htmlFor="icon-button-file">
                    <input
                        id="icon-button-file"
                        accept="image/*"
                        type="file"
                        multiple
                        disabled={imageLoading}
                        onChange={handleImageFile}
                        onClick={(e) => (e.target.value = null)}
                    />
                    <IconButton
                        sx={{
                            width: 70,
                            height: 70,
                            borderRadius: '4px',
                            border: 1,
                            borderColor: 'neutral.black',
                            '& svg': {
                                width: 32,
                                height: 32,
                                color: 'neutral.black'
                            },
                            '&:hover': {
                                backgroundColor: 'neutral.white'
                            },
                            '&:active': {
                                transition: 'none',
                                transform: 'scale(0.98)'
                            }
                        }}
                        component="span"
                        disabled={imageLoading}
                        color="primary"
                        aria-label="upload product image"
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            </Grid>
        </StyledImageContainer>
    );
}

UploadImage.propTypes = {
    imageFiles: PropTypes.array,
    text: PropTypes.string.isRequired,
    setImageFiles: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    handleDeleteImageFile: PropTypes.func.isRequired
};

UploadImage.defaultProps = {
    imageFiles: []
};

export default UploadImage;
