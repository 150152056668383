import React, { useCallback, useEffect, useState } from 'react';

import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { setConfirmed, setConfirmReset } from 'slices/modalSlice';

import useGetList from 'hooks/useGetList';
import useMenu from 'hooks/useMenu';
import useMessage from 'hooks/useMessage';

import { deleteData } from 'api';
import { Search } from 'components';

import BrandTable from './components/BrandTable';

export default function Brand() {
    const [selected, setSelected] = useState([]);
    const menu = useMenu({ page: 'Brand', menu: 'brand', menuTitle: '브랜드 관리', menuNum: 6 });
    const { isLoading, data: dataList, refetch: refetchList } = useGetList({ menu, url: '/web/brand/lists' }); // 리스트 데이터 가져오기
    const { data, isConfirmed, confirmType } = useSelector((state) => state.modal);

    const dispatch = useDispatch();
    const handleMessage = useMessage();

    const { mutate } = useMutation(({ url, data }) => deleteData(url, data), {
        onSuccess: () => {
            dispatch(setConfirmReset());
            refetchList();
            handleMessage({ message: '삭제되었습니다.' });
            setSelected([]);
        },
        onError: (error) => handleMessage({ type: 'message', ...error })
    });

    const handleConfirm = useCallback(() => {
        dispatch(setConfirmed());
        if (confirmType === 'brandDelete') {
            mutate({ url: `web/brand/${data}` });
        }
    }, [dispatch, confirmType, mutate]);

    useEffect(() => {
        if (isConfirmed) {
            handleConfirm();
        }
    }, [isConfirmed, handleConfirm]);

    return (
        <>
            <Search path="/brand-list" total={dataList?.totalCount} />
            <BrandTable
                refetch={refetchList}
                isLoading={isLoading}
                data={dataList?.brandLists}
                total={dataList?.totalCount}
                selected={selected}
                setSelected={setSelected}
            />
        </>
    );
}
