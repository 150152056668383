import React from 'react';

import PropTypes from 'prop-types';

import { TableRow, TableCell, CircularProgress } from '@mui/material';

import { StyledTableLoader } from 'styles/customize/table';

function TableLoader({ text, type, colSpan }) {
    return type === 'loading' ? (
        <TableRow>
            <TableCell align="center" colSpan={colSpan}>
                <StyledTableLoader component="div" variant="body2">
                    <CircularProgress sx={{ mr: '10px' }} size={12} color="primary" /> 데이터를 불러오는 중입니다.
                </StyledTableLoader>
            </TableCell>
        </TableRow>
    ) : (
        <TableRow>
            <TableCell align="center" colSpan={colSpan}>
                <StyledTableLoader component="div" variant="body2">
                    {text ? text : '조회된 데이터가 없습니다.'}
                </StyledTableLoader>
            </TableCell>
        </TableRow>
    );
}

TableLoader.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    colSpan: PropTypes.number
};

TableLoader.defaultProps = {
    text: '',
    type: 'loading',
    colSpan: 20
};

export default TableLoader;
