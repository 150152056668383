import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Grid, TextField } from '@mui/material';

import { SearchSelect } from 'components';

function SearchField({ searchCondition, dataList, onSelect, searchKeyword, onChange }) {
    const { page: menu } = useSelector((state) => state.menu);
    return (
        <>
            <Grid item xs="auto">
                <SearchSelect
                    menu={menu}
                    name="searchCondition"
                    value={searchCondition}
                    dataList={dataList}
                    onChange={onSelect}
                />
            </Grid>
            <Grid item xs="auto">
                <TextField
                    sx={{ minWidth: 152, width: 200 }}
                    InputLabelProps={{ shrink: false }}
                    inputProps={{ 'aria-label': 'search keyword' }}
                    id="outlined-search-keyword"
                    label=""
                    size="small"
                    variant="outlined"
                    type="search"
                    value={searchKeyword}
                    onChange={onChange}
                    onKeyDown={onChange}
                />
            </Grid>
        </>
    );
}

SearchField.propTypes = {
    dataList: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    ),
    searchCondition: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    searchKeyword: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

SearchField.defaultProps = {
    dataList: []
};

export default SearchField;
