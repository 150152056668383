import React from 'react';

import PropTypes from 'prop-types';

import { Grid, Box, Typography } from '@mui/material';
import { DatePicker as Picker } from '@mui/x-date-pickers/DatePicker';

import { DATE_FORMAT } from 'configs/search';

import { StyledBox } from 'styles/customize/select';

import { formatDate } from 'utils';

function DatePicker({ readOnly, caption, term, dates, onChange, eventDateType }) {
    return (
        <>
            <Grid item xs="auto">
                {caption && (
                    <Typography component="p" variant="caption">
                        시작일
                    </Typography>
                )}
                <Picker
                    disableCloseOnSelect={false}
                    mask={term === 'daily' ? '____/__/__' : '____/__'}
                    views={term === 'monthly' ? ['month', 'year'] : ['day']}
                    maxDate={eventDateType === 'reg_dt' ? new Date() : null}
                    inputFormat={term === 'daily' ? DATE_FORMAT.daily : DATE_FORMAT.monthly}
                    inputProps={{ 'aria-label': 'start date', placeholder: '' }}
                    value={dates.startDate}
                    onChange={(e) => onChange('startDate', e ? formatDate(term, e) : null)}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <StyledBox>
                            <input ref={inputRef} {...inputProps} readOnly />
                            {InputProps?.endAdornment}
                        </StyledBox>
                    )}
                />
            </Grid>
            <Grid item xs="auto">
                {caption && <Box height={17} />}
                <Typography component="span" variant="dash">
                    ~
                </Typography>
            </Grid>
            <Grid item xs="auto">
                {caption && (
                    <Typography component="p" variant="caption">
                        종료일
                    </Typography>
                )}
                <Picker
                    readOnly={readOnly}
                    disableCloseOnSelect={false}
                    mask={term === 'daily' ? '____/__/__' : '____/__'}
                    views={term === 'monthly' ? ['month', 'year'] : ['day']}
                    minDate={dates.startDate ? new Date(dates.startDate) : new Date('1900', '01', '01')}
                    maxDate={eventDateType === 'reg_dt' ? new Date() : null}
                    inputFormat={term === 'daily' ? DATE_FORMAT.daily : DATE_FORMAT.monthly}
                    inputProps={{ 'aria-label': 'end date', placeholder: '' }}
                    value={dates.endDate}
                    onChange={(e) => onChange('endDate', e ? formatDate(term, e) : null)}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <StyledBox>
                            <input ref={inputRef} {...inputProps} readOnly />
                            {InputProps?.endAdornment}
                        </StyledBox>
                    )}
                />
            </Grid>
        </>
    );
}

DatePicker.propTypes = {
    readOnly: PropTypes.bool,
    caption: PropTypes.bool,
    dates: PropTypes.object,
    term: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    eventDateType: PropTypes.string
};

DatePicker.defaultProps = {
    readOnly: false,
    caption: false,
    dates: { startDate: null, endDate: null },
    eventDateType: ''
};

export default DatePicker;
